import { Drawer, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const DynamicListBS = ({
  isOpen,
  onClose,
  onItemClicked,
  list,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  onItemClicked: (value: { id: string; label: string }) => void;
  list: { id: string; label: string }[];
  title: string;
}) => {
  return (
    <Drawer
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: '28px',
          borderTopRightRadius: '28px',
          backgroundColor: 'white',
          height: 'fit-content',
          maxHeight: '100%',
          overflow: 'hidden',
        },
      }}
      keepMounted={false}
      anchor={'bottom'}
      open={isOpen}
      onClose={onClose}
    >
      <Stack>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingY: '26px',
            paddingX: '16px',
            borderBottom: (theme) => `1px solid ${theme.palette.surface.paper}`,
          }}
        >
          <CloseIcon onClick={onClose} />
          <Typography
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              whiteSpace: 'nowrap',
            }}
            fontWeight="600"
            fontSize="16px"
            height="26px"
          >
            {title}
          </Typography>
        </Stack>
        <Stack
          sx={{
            width: '100vw',
            paddingX: '24px',
            paddingY: '16px',
          }}
        >
          <Stack
            sx={{
              maxHeight: '50vh',
              overflowY: 'auto',
              '&::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                background: (theme) => theme.palette.primary.main,
                borderRadius: '4px',
              },
            }}
          >
            {list.map(({ id, label }) => {
              return (
                <Typography
                  key={id}
                  onClick={() => onItemClicked({ id, label })}
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="24px"
                  sx={{ paddingX: '16px', paddingY: '8px' }}
                >
                  {label}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};
