export function toFarsiNumber(n: number | string) {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  return n.toString().replace(/\d/g, (x: string) => farsiDigits[Number(x)]);
}

export function convertEnglishDigitToPersian(value: string | number): string {
  return String(value)?.replaceAll(/[0-9]/g, (token) => {
    return String.fromCharCode(
      token.charCodeAt(0) + ('۰'.charCodeAt(0) - '0'.charCodeAt(0)),
    );
  });
}

export const convertPersianDigitToEnglish = (persianString: string) => {
  return persianString
    .split('')
    .map((s: any) => s.replace(/[۰-۹]/g, (d: any) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d)))
    .join('');
};

interface IsNumberConfig {
  persian?: boolean;
  withComma?: boolean;
}

/**
 * detects if the given value is number or not.
 * @param value it can be string or number
 * @param config
 * contains tow configs:
 * ```ts
 * {
 *   // validates value in persian numbers
 *   persian?: boolean;
 *   // validate value with "," or "،"
 *   withComma?: boolean;
 * }
 * ```
 */
export function isNumber(
  value: string | number,
  config: IsNumberConfig = {},
): boolean {
  const { persian = false, withComma = false } = config;
  if (typeof value === 'number') {
    return true;
  }
  const numCharRex = `[${persian ? '۰-۹' : '0-9'}]`;
  return (
    Boolean(value) &&
    RegExp(
      `^${
        withComma
          ? `${numCharRex}{1,3}([,،]${numCharRex}{3})*`
          : `${numCharRex}*`
      }$`,
    ).test(value)
  );
}
