import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Stack, Typography } from '@mui/material';
import { funnelCityList } from 'containers/car-services-order/configs';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  onSelect: (id: number) => void;
};

export const CityDrawer = ({ isOpen, onClose, onSelect }: Props) => {
  return (
    <Drawer
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '28px 28px 0 0',
          backgroundColor: 'white',
          height: 'fit-content',
          maxHeight: '60%',
          overflow: 'hidden',
        },
      }}
      keepMounted={false}
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
    >
      <Stack
        sx={{
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingY: '26px',
          paddingX: '16px',
          borderBottom: (theme) => `1px solid ${theme.palette.surface.paper}`,
        }}
      >
        <CloseIcon onClick={onClose} />
        <Typography
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          fontWeight="600"
          fontSize="16px"
          height="26px"
        >
          انتخاب شهر
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: '100vw',
          paddingX: '24px',
          paddingY: '16px',
        }}
      >
        {funnelCityList.map((city) => {
          return (
            <Typography
              key={city.id}
              onClick={() => {
                onSelect(city.id);
              }}
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              sx={{ paddingX: '16px', paddingY: '8px' }}
            >
              {city.label}
            </Typography>
          );
        })}
      </Stack>
    </Drawer>
  );
};
