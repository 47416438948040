import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {
  Autocomplete,
  Box,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Spacer from 'components/Spacer';
import { CityDrawer } from 'containers/car-services-order/components/CityDrawer';
import Footer from 'containers/car-services-order/components/Footer';
import { ShiftTimeBottomSheet } from 'containers/car-services-order/components/ShiftTimeBottomSheet';
import Tick from 'containers/car-services-order/components/Tick';
import Layout from 'containers/car-services-order/components/layout';
import {
  funnelCityList,
  getRetargetingCity,
  initialData,
  useCarServices,
} from 'containers/car-services-order/configs';
import { useGetShiftTimes } from 'containers/car-services-order/requests';
import { useFormik } from 'formik';
import useFunnelContext from 'hooks/useFunnelContext';
import { useRouter } from 'next/router';
import { FC, useEffect, useRef, useState } from 'react';
import createStyle from 'utils/createStyle';
import { requestForAnimation } from 'utils/requestForAnimation';
import * as yup from 'yup';
import {
  useGetOrderDetails,
  useUpdateOrderMutation,
} from '../../../services/rest/car-services';
import { OrderStateType } from 'services/rest/car-services/car-services';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}
const styles = createStyle({
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '24px',
  },
  input: {
    width: '100%',
  },
  formControl: {
    flexGrow: 1,
  },
  startAndormentIcon: {
    color: '#6E6E6E',
    marginRight: 1,
  },
});
const weekDays = [
  'شنبه',
  'یک‌شنبه',
  'دوشنبه',
  'سه‌شنبه',
  'چهارشنبه',
  'پنج‌شنبه',
  'جمعه',
];

const dataSchema = yup.object().shape({
  time: yup.string().required(),
  city: yup
    .number()
    .nullable()
    .required('لطفا شهر مورد نظر خود را انتخاب کنید.'),
  address: yup.string().required(),
});

// const setDiscountToLocalStorage = (code: string) => {
//   localStorage.setItem(SPECIFIC_DISCOUNT_CODE, code);
// };

const UserAddress: FC<PropTypes> = (props) => {
  // const [isDiscountOpen, setDiscountOpen] = useState(false);
  // const { copy } = useCopyToClipboard();
  const addressRef = useRef<HTMLInputElement>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const shiftTimeRef = useRef<HTMLInputElement>(null);
  const [isCityBSOpen, setCityBSOpen] = useState(false);
  const { currentStep, totalStep } = props;
  const [isOpen, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const funnelData = useFunnelContext();
  const updateOrder = useUpdateOrderMutation();
  const theme = useTheme();
  const { query } = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data: carServicesData, setData: setCarServicesData } =
    useCarServices();
  const { data: shiftTimes } = useGetShiftTimes(
    {
      order: String(carServicesData.orderId) ?? '',
    },
    { enabled: Boolean(carServicesData.orderId) },
  );
  const { data: orderData } = useGetOrderDetails(
    String(carServicesData.orderId) ?? '',
  );

  const submitHandler = (newValues: {
    address: string;
    time: string | null;
    city: number | null;
  }) => {
    if (!selectedDate || newValues.city !== 1) return;
    if (!carServicesData.orderId) return;

    updateOrder.mutate(
      {
        address: newValues.address,
        visit_time: selectedDate.toISOString(),
        city: 'Tehran',
        id: String(carServicesData.orderId),
      },
      {
        onSuccess: () => funnelData?.nextStep(true),
      },
    );
  };

  const formik = useFormik({
    validateOnMount: false,

    initialValues: {
      address: orderData?.address ?? '',
      time: orderData?.visit_time ?? '',
      city: orderData?.city === 'تهران' ? 1 : null,
    },
    validationSchema: dataSchema,
    onSubmit: submitHandler,
  });

  const wrongCity = formik.values.city === 99999;

  useEffect(() => {
    if (
      orderData?.state &&
      (['cancelled', 'done'] as OrderStateType[]).includes(orderData.state)
    ) {
      setCarServicesData(initialData);
      if (typeof window !== 'undefined')
        location.replace('/services/car-services/car-info');
    }
    if (orderData?.visit_time) {
      formik.setFieldValue('time', orderData.visit_time);
      setSelectedDate(new Date(orderData.visit_time));
    }
    if (orderData?.address) formik.setFieldValue('address', orderData.address);
  }, [orderData?.visit_time, orderData?.address, orderData?.state]);

  useEffect(() => {
    const city = getRetargetingCity(query);
    if (city) {
      formik.setFieldValue('city', city);
    }
  }, [query]);

  // useEffect(() => {
  //   const discountCode = localStorage.getItem(SPECIFIC_DISCOUNT_CODE);
  //   if (discountCode) return;
  //   setDiscountOpen(true);
  // }, []);

  return (
    <Layout
      header={{
        title: 'ثبت محل سرویس',
        totalPage: totalStep,
        currentPage: currentStep,
        // goPrevStep: !carServicesData.orderId,
      }}
    >
      <Typography
        fontSize="15px"
        fontWeight={600}
        color={(th) => th.palette.common.black}
      >
        زمان و محل دریافت سرویس را مشخص کنید.
      </Typography>

      {isMobile ? (
        <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
          <TextField
            error={!!formik.errors.city || wrongCity}
            helperText={
              wrongCity
                ? 'فعلا این سرویس فقط در شهر تهران ارائه میشود.'
                : formik.errors.city
            }
            value={
              funnelCityList.find((i) => i.id === formik.values.city)?.label ||
              null
            }
            onClick={() => {
              setCityBSOpen(true);
            }}
            sx={{
              ...styles.input,
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': {
                  borderColor: theme.palette.surface.paper,
                },
              },
              '& .MuiOutlinedInput-root': {
                paddingRight: '6px',
                paddingLeft: '6px',
              },
              '& .MuiOutlinedInput-input': {
                paddingLeft: '8px',
                paddingRight: '8px',
              },
            }}
            size="small"
            InputProps={{
              placeholder: 'انتخاب کنید...',
              readOnly: true,
              startAdornment: (
                <SearchRoundedIcon
                  sx={{
                    ...styles.startAndormentIcon,
                    marginRight: '0',
                    marginLeft: 0.5,
                  }}
                />
              ),
            }}
            inputProps={{
              'aria-autocomplete': 'none',
              autoComplete: 'nope',
            }}
            label="شهر"
          />
          <Tick
            sx={{ marginTop: '10px' }}
            isactive={String(formik.values.city === 1)}
          />
        </Stack>
      ) : (
        <Stack sx={{ ...styles.inputContainer, ...{ alignItems: 'start' } }}>
          <FormControl sx={styles.formControl}>
            <Autocomplete
              size="small"
              value={
                funnelCityList.find((i) => i.id === formik.values.city) || null
              }
              onChange={(_e, newValue) => {
                if (!newValue) {
                  setCarServicesData((prev) => ({ ...prev, city: null }));
                  return;
                }
                formik.setFieldValue('city', newValue?.id || '');
                setCarServicesData((prev) => ({ ...prev, city: newValue.id }));
                setTimeout(() => {
                  addressRef.current?.focus();
                }, 100);
              }}
              options={funnelCityList}
              renderInput={(params) => (
                <TextField
                  error={!!formik.errors.city || wrongCity}
                  helperText={
                    wrongCity
                      ? 'فعلا این سرویس فقط در شهر تهران ارائه میشود.'
                      : formik.errors.city
                  }
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: 'انتخاب کنید...',
                    startAdornment: (
                      <SearchRoundedIcon
                        sx={{
                          ...styles.startAndormentIcon,
                          marginRight: '0',
                          marginLeft: 0.5,
                        }}
                      />
                    ),
                  }}
                  inputProps={{
                    ...params.inputProps,
                    'aria-autocomplete': 'none',
                    autoComplete: 'nope',
                  }}
                  label="شهر"
                />
              )}
              sx={styles.input}
              noOptionsText={'موردی یافت نشد'}
            />
          </FormControl>
          <Tick
            sx={{ marginTop: '10px' }}
            isactive={String(formik.values.city !== null)}
          />
        </Stack>
      )}

      <Stack
        component="div"
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginTop: '24px',
        }}
      >
        <TextField
          inputRef={addressRef}
          minRows={3}
          multiline
          label="آدرس کامل"
          placeholder="آدرس خود را وارد کنید"
          name="address"
          onChange={formik.handleChange}
          value={formik.values.address}
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          sx={{ flexGrow: 1 }}
        />
        <Box mt={1}>
          <Tick isactive={String(formik.values.address.length > 2)} />
        </Box>
      </Stack>

      <Stack
        component="div"
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginTop: '24px',
        }}
      >
        <TextField
          inputRef={shiftTimeRef}
          label="زمان"
          placeholder="انتخاب کنید"
          name="time"
          value={
            selectedDate
              ? `${weekDays[(selectedDate.getDay() + 1) % 7]} - ${String(
                  selectedDate.getHours(),
                ).padStart(2, '0')}:${String(selectedDate.getMinutes()).padEnd(
                  2,
                  '0',
                )}`
              : ''
          }
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          sx={{
            flexGrow: 1,
            '& .MuiOutlinedInput-input': {
              paddingTop: '10px',
              paddingBottom: '10px',
            },
          }}
          inputProps={{}}
          onClickCapture={() => {
            setOpen(true);
            requestForAnimation(() => {
              shiftTimeRef.current?.blur();
            });
          }}
        />
        <Box mt={1}>
          <Tick isactive={String(Boolean(selectedDate))} />
        </Box>
      </Stack>

      <Spacer />

      <Footer
        ref={submitBtnRef}
        isLoading={updateOrder.isLoading}
        isDisabled={
          formik.values.address.length < 3 ||
          updateOrder.isLoading ||
          !selectedDate
        }
        onClick={formik.submitForm}
        id="car-services-address-btn"
      />

      {shiftTimes && (
        <ShiftTimeBottomSheet
          open={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          defaultValue={selectedDate}
          onSelect={(d: Date) => {
            setSelectedDate(new Date(d.getTime()));
            setOpen(false);
            formik.setValues((prev) => ({
              ...prev,
              time: `${
                weekDays[(d.getDay() + 1) % 7]
              } -  ساعت ${d.getHours()}:${String(d.getMinutes()).padEnd(
                2,
                '0',
              )}`,
            }));
            setTimeout(() => {
              submitBtnRef.current?.focus();
            }, 100);
          }}
          data={shiftTimes}
        />
      )}
      <CityDrawer
        isOpen={isCityBSOpen}
        onClose={() => {
          setCityBSOpen(false);
        }}
        onSelect={(id) => {
          formik.setFieldValue('city', id);
          setCarServicesData((prev) => ({
            ...prev,
            city: id,
          }));
          setCityBSOpen(false);
          setTimeout(() => {
            addressRef.current?.focus();
          }, 100);
        }}
      />
      {/* <Modal
        open={isDiscountOpen}
        onClose={() => {
          setDiscountOpen(false);
          setDiscountToLocalStorage('first20');
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            height: 'fit-content',
            backgroundColor: theme.palette.common.white,
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '1.75rem',
            padding: '1.5rem',
            outline: 'none',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: '2rem',
              marginBottom: '1rem',
            }}
          >
            کد تخفیف اختصاصی شما
          </Typography>
          <Typography>
            %20 تخفیف تا سقف 300 هزار تومان.
            <br />
            <Typography
              component="span"
              sx={{
                fontSize: 'inherit',
                display: 'block',
                fontWeight: 600,
                marginTop: '0.5rem',
              }}
            >
              first20
            </Typography>
          </Typography>
          <Typography
            sx={{
              marginTop: '1.5rem',
              alignSelf: 'end',
              fontSize: '0.875rem',
              fontWeight: 600,
              lineHeight: '1.25rem',
              color: theme.palette.secondary.main,
            }}
            onClick={() => {
              setDiscountOpen(false);
              copy('first20');
              setDiscountToLocalStorage('first20');
              Toast({
                message: 'کد تخفیف با موفقیت کپی شد.',
                type: 'success',
              });
            }}
          >
            کپی کردن کد تخفیف
          </Typography>
        </Stack>
      </Modal> */}
    </Layout>
  );
};

export default UserAddress;
