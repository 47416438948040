import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import { AxiosError } from 'axios';
import Toast from 'components/toast';
import { CustomizedBundleItem } from 'containers/car-services-order/components/BundleItem/CustomizedBundleItem';
import CallToSupport from 'containers/car-services-order/components/CallToSupport';
import Footer from 'containers/car-services-order/components/Footer';
import Layout from 'containers/car-services-order/components/layout';
import {
  basicCheckupId,
  initialData,
  receiptTextStyles,
  useCarServices,
} from 'containers/car-services-order/configs';
import {
  UpdateQuotationParams,
  useGetQuotation,
  useUpdateQuotation,
} from 'containers/car-services-order/requests';
import useFunnelContext from 'hooks/useFunnelContext';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  OrderStateType,
  TierType,
} from 'services/rest/car-services/car-services';
import {
  useGetOrderDetails,
  useGetSuggestedPartPrices,
  useUpdateOrderMutation,
} from '../../../../services/rest/car-services';
import { displayError } from '../../../../utils/errorHandler';
import BundleItem from '../../components/BundleItem/BundleItem';
import { convertPersianDigitToEnglish } from 'utils';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const SuggestedPackage: FC<PropTypes> = ({ currentStep, totalStep }) => {
  const theme = useTheme();
  const [notCompleted, setNotCompleted] = useState(false);
  const [reminderDate, setReminderDate] = useState('');
  const [reminderMileage, setReminderMileage] = useState('');
  const [isReminderOpen, setReminderOpen] = useState(false);
  const funnelData = useFunnelContext();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { data: carServicesData, setData: setCarServicesData } =
    useCarServices();
  const [isOtherTierAvailable, setOtherTierAvailable] = useState(false);
  const { data: quotationData } = useGetQuotation(
    {
      uuid: carServicesData.quotationUUID ?? '',
    },
    {
      enabled:
        !carServicesData.orderId && Boolean(carServicesData.quotationUUID),
    },
  );
  const { data: orderData } = useGetOrderDetails(
    String(carServicesData.orderId),
    {
      enabled: !!carServicesData.orderId,
    },
  );
  const { mutate: updateQuotation } = useUpdateQuotation();
  const { mutate: updateOrder } = useUpdateOrderMutation();

  const customizedIds = useRef<number[]>([]);

  const orderServiceIds = orderData?.services.map((item) => item.id);

  const { data, isLoading, isRefetching, fetchStatus } =
    useGetSuggestedPartPrices(
      {
        ids: carServicesData.orderId
          ? orderServiceIds ?? []
          : quotationData?.service_types ?? [],
        brand_model:
          orderData?.car_profile.brand_model_en ??
          quotationData?.brand_model_en ??
          '',
        manufacture_year:
          orderData?.car_profile.manufactured_year ??
          quotationData?.manufactured_year ??
          '',
      },
      {
        staleTime: 0,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        retry: 0,

        enabled: Boolean(
          carServicesData.orderId ? orderData?.id : quotationData?.id,
        ),
        onSuccess: (response) => {
          setOtherTierAvailable(
            Boolean(
              response.tiers.economy ||
                response.tiers.standard ||
                response.tiers.premium,
            ),
          );
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            if (error.response && error.response.status === 400) {
              if (error.response.data.service_types) {
                const errorList: string[] = error.response.data.service_types;
                const errorMessage = `در حال حاضر قادر به ارایه خدمت/خدمات ${errorList.join(
                  ', ',
                )} برای خودرو شما نیستیم.`;
                displayError(errorMessage);
                funnelData?.changeStep('select-services');
              }
              if (error.response.data.part_price_bundles) {
                const errorList: string[] =
                  error.response.data.part_price_bundles;
                const errorMessage = `در حال حاضر قادر به ارایه خدمت/خدمات ${errorList.join(
                  ', ',
                )} برای خودرو شما نیستیم.`;
                displayError(errorMessage);
                funnelData?.changeStep('select-services');
              }
            }
          }
        },
      },
    );

  const [selectedTier, setSelectedTier] = useState<TierType | undefined>(
    undefined,
  );

  useEffect(() => {
    funnelData?.setLoading(
      fetchStatus === 'idle' ? false : isLoading || isRefetching,
    );
  }, [isLoading, isRefetching, funnelData, fetchStatus]);

  useEffect(() => {
    if (
      orderData?.state &&
      (['cancelled', 'done'] as OrderStateType[]).includes(orderData.state)
    ) {
      setCarServicesData(initialData);
      if (typeof window !== 'undefined')
        location.replace('/services/car-services/car-info');
    }
  }, [orderData?.state, setCarServicesData]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setReminderOpen(true);
    }, 10000);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (selectedTier === 'customization') clearTimeout(timeoutRef.current);
  }, [selectedTier]);

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const submitServiceBundle = () => {
    if (!selectedTier) return;

    const noPartServiceCount =
      (orderServiceIds ?? quotationData?.service_types)?.reduce(
        (acc, item) =>
          [13, 14, basicCheckupId].includes(item) ? (acc += 1) : acc,
        0,
      ) ?? 0;
    const selectedCount =
      orderServiceIds ?? quotationData?.service_types
        ? ((orderServiceIds ?? quotationData?.service_types)?.length ?? 0) -
          noPartServiceCount
        : 0;
    if (
      selectedTier === 'customization' &&
      selectedCount + 1 !== customizedIds.current.length
    ) {
      Toast({
        type: 'error',
        message: 'قبل از تایید باید تمام قطعات را انتخاب کنید.',
      });
      setNotCompleted(true);
      return;
    }
    const body = {
      tier: selectedTier,
    } as UpdateQuotationParams;
    // if (selectedTier === 'customization') {
    body.parts = customizedIds.current ?? [];
    // }
    if (carServicesData.orderId) {
      updateOrder(
        { id: String(carServicesData.orderId), ...body },
        {
          onSuccess: () => {
            funnelData?.nextStep();
          },
        },
      );
      return;
    }
    if (!carServicesData.quotationUUID) return;
    updateQuotation(
      { ...body, uuid: carServicesData.quotationUUID },
      {
        onSuccess: () => {
          if (!quotationData) return;
          funnelData?.nextStep();
        },
        onError: (error: any) => {
          Toast({
            message: Array.isArray(error.response?.data)
              ? error.response?.data.join('')
              : 'مشکلی در بروزرسانی سفارش شما رخ داده است.',
            type: 'error',
          });
        },
      },
    );
  };

  const updateCustomizedIds = useCallback((ids: number[]) => {
    customizedIds.current = [...ids];
  }, []);
  const isTest = funnelData?.testFlag === 'T';

  const lastMonths = useMemo(() => {
    const itemDate = new Date();
    const stepValue = isTest ? -1 : 1;

    const result = new Array(6).fill('').map((_, index) => {
      itemDate.setMonth(
        itemDate.getMonth() + (isTest && index === 0 ? 0 : stepValue),
        1,
      );
      const yearMonth = itemDate
        .toLocaleDateString('fa-IR-u-nu-latn', {
          month: 'long',
          year: 'numeric',
        })
        .split(' ');

      return {
        title: `${yearMonth[1]} ${yearMonth[0]}`,
        value: itemDate.toISOString().split('T')?.[0],
      };
    });
    return result;
  }, []);

  return (
    <Layout
      header={{
        title: 'انتخاب لوازم و قطعات',
        totalPage: totalStep,
        currentPage: currentStep,
      }}
      hasFlexGrow
      hasFullWidth={matches}
      backgroundColor={{ xs: '#FAFAFA', md: 'initial' }}
      noContentWrapperPadding={true}
    >
      {/* <Stack
        sx={{
          padding: '1rem 1.5rem',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: theme.palette.info.surface,
        }}
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.75rem',
          }}
        >
          {isTest
            ? 'در صورتی که هنوز زمان سرویس دوره‌ای شما نشده، اطلاعات آخرین سرویس دوره‌ای خود را وارد کنید تا با ارسال یادآور، بتوانید از خدمات کارنامه استفاده کنید.'
            : ' زمان سرویس دوره‌ای بعدی خود را وارد کنید تا با ارسال یادآور، بتوانید از خدمات کارنامه استفاده کنید.'}
        </Typography>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            alignSelf: 'end',
          }}
        >
          <Typography
            id="open-reminder-modal-btn"
            sx={{
              margin: '0 0 0 0.25rem',
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.75rem',
              color: theme.palette.secondary.main,
            }}
            onClick={() => {
              setReminderOpen(true);
            }}
          >
            {isTest ? 'وارد کردن اطلاعات' : 'وارد کردن تاریخ'}
          </Typography>
        </Stack>
      </Stack> */}

      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: { xs: '24px', md: '24px' },
        }}
      >
        <Typography sx={{ ...receiptTextStyles.header, marginBottom: '1rem' }}>
          بسته مد نظر خود را انتخاب کنید.
        </Typography>

        <Stack
          sx={{
            marginBottom: '2rem',
            width: '100%',
          }}
        >
          {data && (
            <RadioGroup value={selectedTier}>
              <Stack minWidth="100%" flexDirection="column" spacing={1}>
                {data.tiers.standard && (
                  <BundleItem
                    onTierChanged={setSelectedTier}
                    tier={data.tiers.standard}
                    discount={data.discount?.amount}
                    selectedTier={selectedTier}
                    tierType="standard"
                  />
                )}
                {data.tiers.economy &&
                  (!data.tiers.standard ||
                    (data.tiers.standard &&
                      data.tiers.economy.total_price <
                        data.tiers.standard.total_price)) && (
                    <BundleItem
                      selectedTier={selectedTier}
                      onTierChanged={setSelectedTier}
                      tier={data.tiers.economy}
                      discount={data.discount?.amount}
                      tierType="economy"
                    />
                  )}
                {data.tiers.premium && (
                  <BundleItem
                    selectedTier={selectedTier}
                    onTierChanged={setSelectedTier}
                    tier={data.tiers.premium}
                    discount={data.discount?.amount}
                    tierType="premium"
                  />
                )}
                <CustomizedBundleItem
                  onTierChanged={setSelectedTier}
                  tierType="customization"
                  selectedTier={selectedTier}
                  updateCustomizedIds={updateCustomizedIds}
                  notCompleted={notCompleted}
                  isOtherTierAvailable={isOtherTierAvailable}
                  servicePrice={
                    data.services_total_price ??
                    data.tiers.economy?.services_total_price ??
                    data.tiers.standard?.services_total_price
                  }
                />
                {/*<Stack*/}
                {/*  sx={{*/}
                {/*    border: `1px solid ${theme.palette.surface.paper}`,*/}
                {/*    width: '100%',*/}
                {/*    borderRadius: '0.5rem',*/}
                {/*    overflow: 'hidden',*/}
                {/*    backgroundColor: 'white',*/}
                {/*    padding: '1rem 0.75rem 0.75rem',*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <Stack flexDirection="row" alignItems="center">*/}
                {/*    <Radio*/}
                {/*      onChange={() => {*/}
                {/*        setselectedTire('idk');*/}
                {/*      }}*/}
                {/*      sx={{*/}
                {/*        '&.Mui-checked': {*/}
                {/*          color: '#694FB4',*/}
                {/*        },*/}
                {/*      }}*/}
                {/*      value="idk"*/}
                {/*    />*/}
                {/*    <Typography sx={{ ...receiptTextStyles.header }}>*/}
                {/*      می‌خواهم خودم انتخاب کنم*/}
                {/*    </Typography>*/}
                {/*  </Stack>*/}
                {/*  <Divider sx={{ marginY: '1rem' }} />*/}
                {/*  <Typography*/}
                {/*    sx={{*/}
                {/*      color: theme.palette.iconDefault.main,*/}
                {/*      marginX: '0.75rem',*/}
                {/*    }}*/}
                {/*    fontSize="0.875rem"*/}
                {/*    fontWeight="400"*/}
                {/*    lineHeight="1.5rem"*/}
                {/*  >*/}
                {/*    موارد مد نظرم در بسته‌ها نبود.*/}
                {/*  </Typography>*/}
                {/*</Stack>*/}
              </Stack>
            </RadioGroup>
          )}
        </Stack>
        <Typography
          sx={{
            ...receiptTextStyles.title,
            fontWeight: 400,
            textAlign: 'center',
            display: { xs: 'none', md: 'initial' },
            width: '100%',
          }}
        >
          در صورت تمایل به تغییر یا مشخص کردن برند لوازم و قطعات با پشتیبانی
          تماس بگیرید.
        </Typography>
        <Stack
          sx={{
            width: '100%',
            marginTop: 'auto',
            marginBottom: { xs: '3rem', md: 'initial' },
          }}
        >
          {/* <Typography
          sx={{
            ...receiptTextStyles.title,
            fontWeight: 400,
            textAlign: 'center',
            display: { md: 'none' },
          }}
        >
          در صورت تمایل به تغییر یا مشخص کردن برند لوازم و قطعات با پشتیبانی
          تماس بگیرید.
        </Typography> */}
          <CallToSupport />
        </Stack>
      </Stack>
      <Footer
        isDisabled={
          (fetchStatus !== 'idle' && (isLoading || isRefetching)) ||
          !selectedTier
        }
        isLoading={fetchStatus !== 'idle' && (isLoading || isRefetching)}
        id="car-services-suggested-bundle-btn"
        onClick={submitServiceBundle}
      />
      <Modal
        open={isReminderOpen}
        onClose={() => {
          setReminderOpen(false);
        }}
        disableAutoFocus
      >
        <Stack
          sx={{
            padding: { xs: '1.5rem' },
            backgroundColor: 'white',
            minWidth: { xs: '330px' },
            borderRadius: '16px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography
            sx={{ fontSize: '1.375rem', fontWeight: '400', lineHeight: '2rem' }}
          >
            {isTest ? 'اطلاعات آخرین سرویس دوره‌ای' : 'زمان سرویس دوره‌ای بعدی'}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: '400',
              lineHeight: '1.875remrem',
              margin: '1rem 0',
            }}
          >
            {isTest
              ? 'در صورتی که هنوز زمان سرویس دوره‌ای شما نرسیده، اطلاعات آخرین سرویس دوره‌ای خود را وارد کنید تا با ارسال یادآور، بتوانید از خدمات کارنامه استفاده کنید.'
              : 'زمان سرویس دوره‌ای بعدی خود را وارد کنید تا با ارسال یادآور، بتوانید از خدمات کارنامه استفاده کنید.'}
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="select-services-time">انتخاب زمان</InputLabel>
            <Select
              labelId="select-services-time"
              id="select-services"
              placeholder="انتخاب کنید"
              label="انتخاب زمان"
              onChange={(event) => {
                setReminderDate(event.target.value as string);
              }}
            >
              {lastMonths.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isTest && (
            <TextField
              label="وارد کردن کارکرد"
              placeholder="وارد کنید..."
              sx={{ marginTop: '1.5rem' }}
              InputLabelProps={{ shrink: true }}
              inputMode="decimal"
              value={
                reminderMileage.trim().length > 0
                  ? parseInt(
                      convertPersianDigitToEnglish(reminderMileage.trim()),
                    ).toLocaleString()
                  : ''
              }
              onChange={(e) => {
                setReminderMileage(e.target.value.replaceAll(',', ''));
              }}
            />
          )}
          <Button
            variant="text"
            onClick={() => {
              if (
                (isTest && !(reminderDate && reminderMileage)) ||
                (!isTest && !reminderDate)
              )
                return;
              setCarServicesData((prev) => ({
                ...prev,
                reminderDate,
                reminderMileage,
              }));
              funnelData?.setStep('user-phone');
            }}
            sx={{
              margin: '1.5rem 0 0',
              paddingX: '0',
              alignSelf: 'end',
              color: theme.palette.secondary.main,
            }}
          >
            {isTest ? 'ثبت اطلاعات' : 'ثبت زمان سرویس دوره‌ای بعدی'}
          </Button>
        </Stack>
      </Modal>
    </Layout>
  );
};

export default SuggestedPackage;
