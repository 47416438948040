import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, type ChangeEvent } from 'react';
import { faNumToEn } from 'utils/en-fa';

type PropTypes = TextFieldProps & { defaultempty?: string };

const AppNumberInput = forwardRef<HTMLInputElement, PropTypes>((props, ref) => {
  const { defaultempty = '', value, onChange, ...otherProps } = props;

  const changeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newValue = faNumToEn(event.target.value).replace(/[^0-9]/g, '');

    event.target.value = newValue;

    if (typeof onChange !== 'undefined') {
      onChange(event);
    }
  };

  return (
    <TextField
      ref={ref}
      value={
        (value || defaultempty) === ''
          ? ''
          : Number(value || defaultempty).toLocaleString()
      }
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        changeHandler({
          ...e,
          target: { ...e.target, value: e.target.value.replaceAll(',', '') },
        } as React.ChangeEvent<HTMLInputElement>);
      }}
      type="tel"
      {...otherProps}
    />
  );
});

AppNumberInput.displayName = 'AppNumberInput';

export default AppNumberInput;
