type ClientKeys =
  | 'API_2_URL'
  | 'API_REST_URL'
  | 'MY_CAR_API_URL'
  | 'AFTERMARKET_API_URL'
  | 'PRICE_ESTIMATOR_API_URL'
  | 'SWITCH_BASE_URL'
  | 'LEASING_BASE_URL'
  | 'USER_TRACE_URL'
  | 'POSTـSTORAGE_URL';

export const servicesPathnames: { [key in ClientKeys]: string } = {
  API_2_URL: '/trade',
  API_REST_URL: '/rest/api',
  MY_CAR_API_URL: '/mycar/api',
  AFTERMARKET_API_URL: '/aftermarket/api',
  PRICE_ESTIMATOR_API_URL: '/priceestimator/api',
  SWITCH_BASE_URL: '/switch/api',
  USER_TRACE_URL: '/user-trace',
  LEASING_BASE_URL: '/leasing/api/v1',
  POSTـSTORAGE_URL: '/post-storage',
};

const switchBaseUrl = servicesPathnames.SWITCH_BASE_URL;
const aftermarketBaseUrl = servicesPathnames.AFTERMARKET_API_URL;
const userTraceBaseUrl = servicesPathnames.USER_TRACE_URL;
const myCarBaseUrl = servicesPathnames.MY_CAR_API_URL;
const priceEstimatorBaseUrl = servicesPathnames.PRICE_ESTIMATOR_API_URL;
const api2BaseUrl = servicesPathnames.API_2_URL;
const restBaseUrl = servicesPathnames.API_REST_URL;
const leasingBaseUrl = servicesPathnames.LEASING_BASE_URL;
const postStorageUrl = servicesPathnames.POSTـSTORAGE_URL;

export const switchEndpoints = {
  REFRESH_TOKEN: `${switchBaseUrl}/auth/refresh/`,
  SEND_OTP: `${switchBaseUrl}/auth/otp/send/`,
  CONFIRM_OTP: `${switchBaseUrl}/auth/otp/confirm/`,
  LOGOUT: `${switchBaseUrl}/auth/logout/`,
  CURRENT_USER: `${switchBaseUrl}/users/current-user/`,
  DIVAR_CLIENT_STATE: `${switchBaseUrl}/divar-oauth/client-state/`,
  DIVAR_REDIRECT_URL: `${switchBaseUrl}/divar-oauth/redirect-link/`,
  DIVAR_ACCESS_TOKEN: `${switchBaseUrl}/divar-oauth/access-token/`,
};

export const aftermarketEndpoints = {
  SELECTED_PARTS: `${aftermarketBaseUrl}/bundles/selective-parts/`,
  TIME_SLOTS: `${aftermarketBaseUrl}/time-slots/`,
  QUOTATIONS: `${aftermarketBaseUrl}/public/quotations/`,
  UPDATE_FETCH_QUOTATIONS: `${aftermarketBaseUrl}/public/quotations/:uuid/`,
  SERVICE_TYPES: `${aftermarketBaseUrl}/public/service-types/`,
  BUNDLES: `${aftermarketBaseUrl}/bundles`,
  CURRENT_USER: `${aftermarketBaseUrl}/users/me/`,
  ORDERS: `${aftermarketBaseUrl}/public/orders/`,
  ORDER_DETAIL: `${aftermarketBaseUrl}/public/orders/:orderID/`,
  ORDER_PAYMENT: `${aftermarketBaseUrl}/public/orders/:orderID/payments/`,
  SUBMIT_ORDER: `${aftermarketBaseUrl}/public/orders/:orderID/submit/`,
  DISCOUNT: `${aftermarketBaseUrl}/public/orders/:orderID/discount/`,
  DELETE_DISCOUNT: `${aftermarketBaseUrl}/public/orders/:orderID/discount/:code/`,
  REMINDERS: `${aftermarketBaseUrl}/reminders/`,
};

export const myCarEndpoints = {
  UPLOAD_MEDIA: `${myCarBaseUrl}/media/`,
  MANUFACTURE_YEARS: `${myCarBaseUrl}/priceestimator/brand-models/:carName/manufacture-years/`,
  SHAYRAD_AUTHENTICITY: `${myCarBaseUrl}/car-authenticity/shayrad-authenticity/`,
  SHAYRAD_SEND_OTP: `${myCarBaseUrl}/car-authenticity/shayrad-authenticity/:uuid/request-otp/`,
  SHAYRAD_CONFIRM_OTP: `${myCarBaseUrl}/car-authenticity/shayrad-authenticity/:uuid/confirm-otp/`,
  CAR_AUTHENTICITY_CREATE: `${myCarBaseUrl}/car-authenticity/authenticity/`,
  CAR_AUTHENTICITY_UPDATE_FETCH: `${myCarBaseUrl}/car-authenticity/authenticity/:requestUUID/`,
  CAR_AUTHENTICITY_DETAILS_BY_TOKEN: `${myCarBaseUrl}/car-authenticity/authenticity/by-post-token/:postToken/`,
  CAR_AUTHENTICITY_REPORT_ISSUE: `${myCarBaseUrl}/car-authenticity/authenticity/:requestUUID/report-issue/`,
  CAR_AUTHENTICITY_PAYMENT: `${myCarBaseUrl}/car-authenticity/payment/`,
  CAR_AUTHENTICITY_IMAGE: `${myCarBaseUrl}/ar-authenticity/authenticity/:requestUUID/car-image/`,
  BLOGS: `${myCarBaseUrl}/blog-posts/`,
  LATEST_ORDERS: `${myCarBaseUrl}/orders/latest/`,
  PLATES_LIST: `${myCarBaseUrl}/plate/`,
  VIOLATION_INQUIRY_LIST: `${myCarBaseUrl}/violation-inquiry/inquiries/:inquiryID/`,
  VIOLATION_INQUIRY_IMAGE: `${myCarBaseUrl}/violation-inquiry/inquiry-records/:inquiryRecordID/image/`,
  PRE_INQUIRY: `${myCarBaseUrl}/violation-inquiry/pre-inquiries/`,
  VALIDATE_DISCOUNT_CODE: `${myCarBaseUrl}/violation-inquiry/discount/verify/`,
  BATCH_PAYMENTS: `${myCarBaseUrl}/violation-inquiry/batch-payments/`,
  CAR_PROFILES: `${myCarBaseUrl}/car-profiles/`,
  PRICE_ESTIMATION: `${myCarBaseUrl}/car-profiles/:profileID/estimate/`,
  CREATE_LEAD: `${myCarBaseUrl}/upgrade-lead/`,
  UPDATE_LEAD: `${myCarBaseUrl}/upgrade-lead/:leadID/`,
  UPDATE_MILEAGE: `${myCarBaseUrl}/car-profiles/:carProfileID/statuses/mileage/`,
  BODY_STATUS: `${myCarBaseUrl}/car-profiles/:carProfileID/statuses/body/`,
  KARNAMA_VIDEOS: `${myCarBaseUrl}/karnama/videos/`,
  KARNAMA_HEADING_VIDEOS: `${myCarBaseUrl}/karnama/videos/header/`,
  KARNAMA_VIDEO_DETAILS: `${myCarBaseUrl}/karnama/videos/:videoID/`,
};

export const priceEstimatorEndpoints = {
  REGISTER_FEEDBACK: `${priceEstimatorBaseUrl}/feedback/register`,
  POPULAR_CAR_PRICES: `${priceEstimatorBaseUrl}/zeroprice/last-prices/popular/`,
  DIVAR_CAR_POST_PRICING_DETAIL: `${priceEstimatorBaseUrl}/pricing_report/divar-car-post/detail`,
  REGISTER_PRICING_REPORT: `${priceEstimatorBaseUrl}/pricing_report/register`,
  PRICE_REPORT_WITH_ID: `${priceEstimatorBaseUrl}/pricing_report/:reportID/detail`,
  CAR_PRICE: `${priceEstimatorBaseUrl}/estimate/data-driven/`,
  CONFIRM_FREE_REPORT: `${priceEstimatorBaseUrl}/pricing_report/:reportID/confirm-free-report`,
  LATEST_ZERO_PRICES: `${priceEstimatorBaseUrl}/zeroprice/last-prices/`,
  DIVAR_PRICE_REPORT: `${priceEstimatorBaseUrl}/pricing_report/divar-car-post/price-report-detail/`,
};

export const userTraceEndpoints = {
  REGISTER: `${userTraceBaseUrl}/user-trace/v1/event_logs/register`,
  CARPOST_ANALYTICS: `${userTraceBaseUrl}/user-trace/v1/analytics/car-post/:token/views`,
};

export const api2Endpoints = {
  REDIRECT_BY_TOKEN: `${api2BaseUrl}/payment/v1/redirect_by_invoice_token`,
  BUY_ASSISTANT_CONFIG: `${api2BaseUrl}/cars/v1/-/get-assistant-buy-configs`,
  CAR_POST_NOTIFICATION_REGISTER: `${api2BaseUrl}/car-post/notification/register`,
  COMMITION_PERFORMA: `${api2BaseUrl}/payment/v1/deal-payment/proforma`,
  GET_BANNER: `${api2BaseUrl}/banner/banner/:bannerKey`,
  GET_BANNERS: `${api2BaseUrl}/banner/banners`,
  GET_HEADER: `${api2BaseUrl}/banner/headerbar/:headerKey`,
  EXTERNAL_LIST: `${api2BaseUrl}/sales-expert/info/external`,
  HOME_LATEST_ADS: `${api2BaseUrl}/cars/v1/car-posts/search?&size=10&start=0&sort=newest`,
  LEASING_LATEST_ADS: `${api2BaseUrl}/cars/v1/car-posts/search?&size=10&start=0&sort=newest&is_leasing_available=true`,
  LATEST_ADS_WITHOUT_PARAMS: `${api2BaseUrl}/cars/v1/car-posts/search`,
  POPULAR_CAR_DETAIL: `${api2BaseUrl}/cars/v1/-/full-name-details`,
  LEASING_LOAN_CONFIG: `${api2BaseUrl}/leasing/v1/loan-config`,
  LEASING_LOAN_CONFIG_2: `${api2BaseUrl}/provider/v2/loan-config`,
  LEASING_REGISTER: `${api2BaseUrl}/leasing/v1/register`,
  LEASING_SEND_OTP: `${api2BaseUrl}/api/v1/finnotech/verification/verify`,
  LEASING_TERMS: `${api2BaseUrl}/leasing/v1/:leasingUUID/terms`,
  LEASING_CONFIG: `${api2BaseUrl}/leasing/v1/leasing-config`,
  GET_PDP: `${api2BaseUrl}/concierge-sale/v2/car-post/:conciergeSaleId`,
  CONCIERGE_SALE_REGISTER: `${api2BaseUrl}/concierge-sale/register`,
  PURCHASE_PLAN: `${api2BaseUrl}/plan-purchase/v1/plan/`,
  RELATED_CARS: `${api2BaseUrl}/cars/v1/car-posts/related`,
  SERVICES_PERFORMA: `${api2BaseUrl}/payment/v1/proforma/:invoiceToken`,
  CONCIERGE_SALE_PUT_SALE: `${api2BaseUrl}/concierge-sale/:registerId`,
  CAR_CONFIG: `${api2BaseUrl}/cars/v1/configs/by-level-id/:lastLevel/:lastLevelId`,
  FORM_STEP: `${api2BaseUrl}/concierge-sale/form-steps`,
  ALL_CONCIERGE_SALES: `${api2BaseUrl}/concierge-sale/`,
  GET_ALL_PROVIDERS: `${api2BaseUrl}/provider/v1/`,
  LEASING_CONFIG_INSTALMENT: `${api2BaseUrl}/provider/v1/instalment-months`,
  PROVIDER_PACKAGES: `${api2BaseUrl}/provider/v1/plans`,
  INSTALMENT_INFO: `${api2BaseUrl}/provider/v1/conditions`,
  USER_INFO: `${api2BaseUrl}/provider/v1/user-info`,
  CAR_COLORS: `${api2BaseUrl}/colors/`,
  CREATE_INVOICE: `${api2BaseUrl}/payment/v1/create_invoice`,
  CONCIERGE_SALE_PLANS: `${api2BaseUrl}/plan-purchase/v2/plan/`,
  LEASING_DETAILS: `${api2BaseUrl}/concierge-sale/user-profile/detail-and-steps/:leasingID`,
  INSPECTION_BANNER: `${api2BaseUrl}/banner/banner/car-inspection-cover`,
  SELL_TICKET_TOPICS: `${api2BaseUrl}/concierge-sale/ticket/topics`,
  SELL_SUBMIT_TOKEN: `${api2BaseUrl}/concierge-sale/ticket`,
  PDP_FEEDBACK_TOPICS: `${api2BaseUrl}/concierge-sale/car-post/feedback/titles/`,
  SUBMIT_PDP_FEEDBACK: `${api2BaseUrl}/concierge-sale/car-post/feedback/:id/`,
  CONCIERGE_SALE_UPLOAD_FILE: `${api2BaseUrl}/concierge-sale/car-post/upload-file`,
  CONCIERGE_SALE_CAR_STATUS: `${api2BaseUrl}/car-health/:id`,
};

export const restEndpoints = {
  CAR_SEARCH: `${restBaseUrl}/find_cars/v1/search`,
  FETCH_CARS_V1: `${restBaseUrl}/find_cars/v1/fetch_cars`,
  FIND_CARS_BRAND_MODELS: `${restBaseUrl}/find_cars/v1/brand_models`,
  EVENT_LOGS: `${restBaseUrl}/event_logs/v1/`,
  SEND_CONDITION_TO_OWNER: `${restBaseUrl}/evaluation/v1/sms/inspection_conditions/`,
  RELATED_MODELS: `${restBaseUrl}/evaluation/v1/companion/related-models/`,
  MODELS_BY_LEVEL: `${restBaseUrl}/evaluation/v1/companion/models-by-level`,
  SERVICE_TIME_LIST: `${restBaseUrl}/evaluation/v1/service_time/`,
  TIMESLOT_AVAILABILITY: `${restBaseUrl}/evaluation/v1/timeslot_availability/`,
  INSPECTION_CENTER: `${restBaseUrl}/evaluation/v1/inspection-center/`,
  INSPECTION_RESERVE: `${restBaseUrl}/evaluation/v1/inspection_reserve/`,
  INSPECTION_RESERVE_UPDATE: `${restBaseUrl}/evaluation/v1/inspection_reserve/:inspectionId/`,
  SERVER_TIME: `${restBaseUrl}/evaluation/v1/dispatch-sheet/servertime/`,
  PACKAGE_LIST: `${restBaseUrl}/evaluation/v1/packages/`,
  COMPANION_PACKAGE_LIST: `${restBaseUrl}/evaluation/v1/companion/package/`,
  COMPANION_TIME_LIST: `${restBaseUrl}/evaluation/v1/companion/available-times/`,
  PURCHASE_AFFIDAVIT: `${restBaseUrl}/affidavit/v1/purchase/`,
  AFFIDAVIT_LINK: `${restBaseUrl}/affidavit/v1/affidavit/:affidavitID/download_link_customer/`,
  AFFIDAVIT_PRICE: `${restBaseUrl}/affidavit/v1/affidavit/price/`,
  AFFIDAVIT_SEND_APP_LINK: `${restBaseUrl}/affidavit/v1/send-app-link/`,
  ALLOCATE_AB_TEST: `${restBaseUrl}/ab_testing/v1/allocate/`,
  CITIES_LIST: `${restBaseUrl}/cities/v1/`,
  CITIES_AUTO_COMPLETE_LIST: `${restBaseUrl}/cities/v1/autocomplete/`,
  NEIGHBORHOOD_AUTO_COMPLETE: `${restBaseUrl}/cities/v1/neighborhood/autocomplete/:id/`,
  CITIES_NEIGHBORHOOD: `${restBaseUrl}/cities/v1/neighborhood/:cityID/`,
  CHECK_DISCOUNT: `${restBaseUrl}/discount/v1/check_code/`,
  REVERT_DISCOUNT: `${restBaseUrl}/discount/v1/check_code/revert/`,
  FUNNEL_STEP: `${restBaseUrl}/evaluation/v1/funnel/steps/`,
  DIVAR_OAUTH: `${restBaseUrl}/authentication/divar-oauth/`,
  REDIRECT_LIST: `${restBaseUrl}/evaluation/v1/:id/redirect/`,
  CAR_SEARCH_EXACT: `${restBaseUrl}/find_cars/v1/search/exact/`,
  EVALUATION_REPORT_SCHEMA: `${restBaseUrl}/evaluation/v1/inspection/:inspectionToken/schema/`,
  EVALUATION_REPORT_PDF: `${restBaseUrl}/report/inspection-report-pdf/:inspectionToken`,
  EVALUATION_PORSLINE: `${restBaseUrl}/inspection-porsline-link/`,
  DIVAR_REPORT: `${restBaseUrl}/divar/report/purchase/v1/report/:inspectionId`,
  PURCHASE_DIVAR_REPORT: `${restBaseUrl}/divar/report/purchase/v1/report/purchase/:inspectionId`,
  PURCHASE_DIVAR_REPORT_START: `${restBaseUrl}/divar/report/purchase/v1/report/payment/start/:inspectionId`,
  GET_STATIC_PAGE: `${restBaseUrl}/banner/snippets/`,
  EXPERT_ORDERS_LIST: `${restBaseUrl}/inspection/v1/expert/:expertID/orders`,
  DIVAR_CHAT_AUTH_TOKEN: `${restBaseUrl}/evaluation/v1/divar/set-access-token/`,
  EVALUATE_PORSLINE: `${restBaseUrl}/evaluation/v1/inspection-porsline-link/`,
  INSPECTION_COMMENT: `${restBaseUrl}/evaluation/v1/inspection-report-comment/:inspectionID/`,
  INSPECTION_REPORT_COMMENT: `${restBaseUrl}/evaluation/v1/inspection-report-comment/`,
  GET_POST_INFO: `${restBaseUrl}/evaluation/v1/divar/get-post/:postId`,
  CONTACT_US: `${restBaseUrl}/contact-us`,
  PROFORMA: `${restBaseUrl}/evaluation/v1/proforma-payment-detail/:invoiceToken/`,
  SETTELMENT: `${restBaseUrl}/evaluation/v1/settlement-payment-detail/:invoiceToken/`,
};

export const leasingEndpoints = {
  ADD_DIVAR_WIDGET: `${leasingBaseUrl}/divar-widget/register/`,
  LEASING_ORDER_DETAILS: `${leasingBaseUrl}/orders/customer-orders/:orderID/`,
  LEASING_CUSTOMER_ORDERS: `${leasingBaseUrl}/orders/customer-orders/:uuid/customer/`,
  UPLOAD_FILE: `${leasingBaseUrl}/common/files/`,
  UPDATE_DATA: `${leasingBaseUrl}/orders/customer-orders/:uuid/update-seller-data/`,
  LEASING_DOCUMENTS: `${leasingBaseUrl}/orders/customer-orders/:orderUUID/documents/`,
  INSTALMENT_MONTHS: `${leasingBaseUrl}/providers/instalment-months/`,
  PROVIDER_PLANS: `${leasingBaseUrl}/providers/plans/`,
  INSTALMENT_INFO: `${leasingBaseUrl}/providers/:id/conditions/`,
  USER_INFO_FIELDS: `${leasingBaseUrl}/providers/:id/user-info/`,
  POST_LEASING_DOCUMENTS: `${leasingBaseUrl}/orders/customer-orders/:leasingUUID/documents`,
  REGISTER_ORDER: `${leasingBaseUrl}/orders/register/`,
  PROVIDER_LIST: `${leasingBaseUrl}/providers/`,
};

export const postStorageEndPoints = {
  CAR_POST_FUNNEL_UPLOAD_FILE: `${postStorageUrl}/car-posts/funnel/upload-file/`,
  GET_CAR_POST_FUNNEL: `${postStorageUrl}/car-posts/funnel/:token/`,
  GET_CAR_POST_STRENGTHS: `${postStorageUrl}/car-posts/funnel/strengths/`,
  GET_CAR_POST_DELETE_REASONS: `${postStorageUrl}/car-posts/funnel/deletion-reasons`,
  POST_CAR_POST_DELETE: `${postStorageUrl}/car-posts/funnel/:token/delete/`,
  PATCH_CAR_POST_UPDATE: `${postStorageUrl}/car-posts/funnel/update/:token`,
  GET_PDP_WITH_TOKEN: `${postStorageUrl}/car-posts/car-post-detail/:token/`,
  GET_PLP_DATA: `${postStorageUrl}/car-posts/car-post-list/`,
  SUBMIT_PDP_FEEDBACK: `${postStorageUrl}/car-posts/feedback/register/:token/`,
  RELATED_CARS: `${postStorageUrl}/car-posts/car-post-related/`,
  PDP_FEEDBACK_TOPICS: `${postStorageUrl}/car-posts/feedback/titles/`,
};

/**
 * indicates the url pattern that should be converted to actual url
 * a key/value object of variables that should be replaced in URL
 * @param url
 * @param variables
 */
export const endPointUrlNormalizer = (
  url: string,
  variables: { [key: string]: string },
): string => {
  const parts = url.split('/');
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part.includes(':')) {
      const variableName = part.split(':')[1];
      if (variableName in variables) {
        parts[i] = part.replace(`:${variableName}`, variables[variableName]);
      } else {
        // Variable not found in data, return original URL
        return url;
      }
    }
  }
  return parts.join('/');
};
