import { useRouter } from 'next/router';
import { useEffect, type FC, type PropsWithChildren } from 'react';
import Cookies, { type CookieSetOptions } from 'universal-cookie';

const cookieOptions: CookieSetOptions = {
  path: '/',
  maxAge: 2592000,
  sameSite: 'lax',
};

const utmItems = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

const AppThirdPartiesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { query, asPath } = useRouter();

  useEffect(() => {
    const cookie = new Cookies();

    const camp1 = cookie.get('analytics_campaign');
    const camp2 = cookie.get('analytics_campaign2');
    const urlObj: { [key: string]: string | string[] | undefined } = {};

    Object.keys(query)
      .filter((keyItem) => utmItems.includes(keyItem))
      .forEach((keyItem) => {
        urlObj[keyItem] = query[keyItem];
      });

    const hasUrl = Boolean(Object.keys(urlObj).length);

    if (hasUrl) {
      cookie.set('analytics_campaign', urlObj, cookieOptions);
      cookie.set('analytics_campaign2', urlObj, cookieOptions);
    } else if (camp1 && !camp2) {
      cookie.set('analytics_campaign2', camp1, cookieOptions);
    }
  }, [query, asPath]);

  return <>{children}</>;
};

export default AppThirdPartiesProvider;
