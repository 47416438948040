import { ParsedUrlQuery } from 'querystring';

export const getQueryValueAsArray = (query: ParsedUrlQuery, key: string) => {
  const selectedQueryItem = query[key];
  if (typeof selectedQueryItem === 'string') {
    return [selectedQueryItem];
  } else if (Array.isArray(selectedQueryItem)) {
    return selectedQueryItem;
  } else {
    return null;
  }
};

export const getQueryValueAsString = (
  query: ParsedUrlQuery,
  key: string,
  lastItem: boolean = true,
) => {
  const selectedQueryItem = query[key];
  if (typeof selectedQueryItem === 'string') {
    return selectedQueryItem;
  } else if (Array.isArray(selectedQueryItem)) {
    return selectedQueryItem.at(lastItem ? -1 : 0) as string;
  }
  return null;
};
