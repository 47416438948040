import { Box, Radio, Stack, Typography } from '@mui/material';
import {
  CarProfileDto,
  PriceEstimatorDTO,
} from 'services/rest/car-exchange/carExchange';

import Button from '@mui/material/Button';
import EmptyCar from 'assets/images/emptyCar.png';
import useFunnelContext from 'hooks/useFunnelContext';
import Image from 'next/image';
import { useState } from 'react';
import { usePriceEstimation, useUpdateLead } from 'services/rest/car-exchange';
import { getUserDataFromToken, getUserPhone } from 'utils/auth';
import { CAR_EXCHANGE_KEY, CarExchangeState } from '../../configs';

const CarItem = ({
  car,
  selected,
  onSelect,
}: {
  car: CarProfileDto;
  selected: boolean;
  onSelect: (data: PriceEstimatorDTO | undefined) => void;
}) => {
  const { data } = usePriceEstimation(
    { id: car.id },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Stack
      gap={{ xs: 2, md: 3 }}
      onClick={() => onSelect(data)}
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'start',
        flexDirection: 'row',
        border: '1px solid',
        borderColor: selected ? 'secondary.main' : 'surface.paper',
        borderRadius: '0.5rem',
        padding: '1rem',
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      <Radio
        checked={selected}
        value={car.id}
        name="radio-buttons"
        sx={{
          position: 'absolute',
          top: '0.5rem',
          right: '0.5rem',
          '&.Mui-checked': {
            color: 'secondary.main',
          },
        }}
      />
      <Stack
        width={{ xs: '8rem', md: '14rem' }}
        height={{ xs: '4rem', md: '7rem' }}
        sx={{ position: 'relative' }}
      >
        <Image
          src={car.primary_image ? car.primary_image.image : EmptyCar}
          alt="empty car image"
          layout="fill"
          style={{ borderRadius: '0.5rem' }}
        />
      </Stack>
      <Stack
        gap={{ xs: 2, md: 3 }}
        height={{ md: '100%' }}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          py: '0.5rem',
          width: '100%',
        }}
      >
        <Stack gap={{ xs: 0, md: 1 }}>
          {car.name && (
            <Typography
              sx={{
                textAlign: 'start',
                fontSize: { xs: '0.875rem', md: '1rem' },
                pr: '2rem',
              }}
            >
              {car.name}
            </Typography>
          )}
          <Typography
            color={car.name ? 'iconDefault.main' : 'black'}
            sx={{
              textAlign: 'start',
              fontSize: { xs: '0.875rem', md: '1rem' },
              pr: car.name ? 0 : '2rem',
            }}
          >
            {car.brand_model_fa} {car.manufactured_year}
          </Typography>
        </Stack>

        {data && data.today_price && (
          <Typography
            sx={{
              textAlign: 'start',
              fontSize: { xs: '0.75rem', md: '1rem' },
              whiteSpace: 'nowrap',
            }}
          >
            حدود قیمت: {data.today_price.toLocaleString()} (تومان)
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const CarList = ({
  cars,
  onChangeMode,
  uuid,
}: {
  cars: CarProfileDto[];
  onChangeMode: () => void;
  uuid: string;
}) => {
  const [selectedCar, setSelectedCar] = useState<CarProfileDto | null>(null);
  const [selectedPrice, setSelectedPrice] = useState<
    PriceEstimatorDTO | undefined
  >(undefined);
  const updateLead = useUpdateLead();
  const funnelData = useFunnelContext();

  const userData = getUserDataFromToken();
  const phoneNumber = getUserPhone();

  const updateLocalStorage = (car: CarProfileDto, price: number) => {
    const carExchangeState: CarExchangeState = JSON.parse(
      localStorage.getItem(CAR_EXCHANGE_KEY) ?? '',
    );
    carExchangeState.car = JSON.stringify(car);
    carExchangeState.price = price;
    localStorage.setItem(CAR_EXCHANGE_KEY, JSON.stringify(carExchangeState));
  };

  const handleUpdateLead = () => {
    updateLead.mutate(
      {
        uuid: uuid,
        carprofile: Number(selectedCar?.id),
        car_estimated_price: selectedPrice?.today_price ?? null,
      },
      {
        onSuccess: () => {
          if (selectedCar) {
            updateLocalStorage(selectedCar, selectedPrice?.today_price ?? 0);
            if (!selectedPrice?.today_price)
              funnelData?.changeStep('car-price');
            else funnelData?.changeStep('determining-budget');
          }
        },
      },
    );
  };

  return (
    <Stack
      gap={4}
      sx={{
        height: { xs: 'calc(100vh - 112px)', md: 'calc(100vh - 232px)' },
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography sx={{ fontSize: '1rem', fontWeight: 600 }}>
        قصد معاوضه کدام خودرو خود را دارید؟
      </Typography>
      <Stack
        gap={4}
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {cars.map((car) => (
          <CarItem
            car={car}
            key={car.id}
            selected={car === selectedCar}
            onSelect={(data) => {
              setSelectedCar(car);
              setSelectedPrice(data);
            }}
          />
        ))}
      </Stack>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          flexShrink: 0,
          pt: { md: '1.5rem' },
          borderTop: { md: '1px solid #EBEBEB' },
        }}
      >
        <Typography
          onClick={onChangeMode}
          sx={{
            fontSize: '14px',
            fontWeight: 600,
            color: (theme) => theme.palette.primary.main,
            cursor: 'pointer',
          }}
        >
          افزودن خودروی جدید
        </Typography>
        <Button
          id={'traceable'}
          _traceservicename={'car-exchange'}
          _traceeventname={'car-exchange-select-car-submit'}
          _traceeventtype={'submit'}
          _traceeventmetadata={JSON.stringify({
            brand_model: selectedCar?.brand_model_fa,
            manufactured_year: selectedCar?.manufactured_year,
            price: selectedPrice?.today_price ?? 0,
            ...userData,
            phoneNumber,
          })}
          disabled={!selectedCar}
          onClick={handleUpdateLead}
          sx={{
            width: { xs: '50%', md: '12.5rem' },
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          تایید و ادامه
        </Button>
      </Box>
    </Stack>
  );
};

export default CarList;
