import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './styles';
import { useRouter } from 'next/router';
import HomeIcon from 'components/icons/navigation/HomeIcon';
import MyCarIcon from 'components/icons/navigation/MyCarIcon';
import CarPriceIcon from 'components/icons/navigation/CarPriceIcon';
import CarAdsIcon from '../../components/icons/navigation/CarAdsIcon';
import ENV from '../../constants/environments';
import useTwaApp from '../../hooks/useTwaApp';

const list = [
  {
    icon: HomeIcon,
    isExternal: false,
    label: 'خدمات',
    link: `/`,
    key: '/',
  },
  {
    icon: MyCarIcon,
    label: 'خودروی من',
    link: `${ENV.APP_URL}/car-profile`,
    key: 'my-car',
    isExternal: true,
  },
  {
    icon: CarAdsIcon,
    label: 'آگهی‌ها',
    link: `/buy-used-cars`,
    key: 'buy-used-cars',
    isExternal: false,
  },
  {
    icon: CarPriceIcon,
    label: 'قیمت روز',
    link: `/car-price`,
    key: 'car-price',
    isExternal: false,
  },
];

const BottomNav: FC = () => {
  const { pathname } = useRouter();
  const { isTwa } = useTwaApp();
  return (
    <Stack component="nav" sx={styles.container}>
      {list.map((listItem) => {
        const Icon = listItem.icon;
        const parts = pathname.split('/');
        const match = `/${parts[1]}`;
        const isEnabled = pathname === listItem.link || listItem.link === match;
        const itemLink = () => {
          if (isTwa && listItem.key === 'my-car') {
            return `${listItem.link}?app_referrer=twa`;
          }
          return listItem.link;
        };
        return (
          <Stack
            sx={styles.linkItem}
            component="a"
            key={listItem.key}
            href={itemLink()}
          >
            <Box
              sx={{
                margin: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '2.2rem',
                backgroundColor: isEnabled ? '#F3F2F8' : 'white',
                paddingX: '1.25rem',
                paddingY: '0.1rem',
                borderRadius: '1rem',
              }}
            >
              <Icon
                type="navigation"
                color={isEnabled ? '#694FB4' : undefined}
              />
            </Box>
            <Typography
              fontSize="0.75rem"
              lineHeight="1rem"
              fontWeight="600"
              sx={{ transition: 'color 0.3s', marginTop: '.25rem' }}
              textAlign="center"
              color={(theme) =>
                isEnabled
                  ? theme.palette.text.primary
                  : theme.palette.iconDefault.main
              }
            >
              {listItem.label}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default BottomNav;
