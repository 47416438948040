import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ENV from 'constants/environments';
import { FC } from 'react';
import styles from './styles';
import useLogic from './useLogic';

const GetUserPhone: FC = () => {
  const { errorMessage, formik, canSubmit, phoneChangeHandler, closeModal } =
    useLogic();

  return (
    <>
      <Stack
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        sx={styles.container}
      >
        <Stack sx={styles.header}>
          <IconButton onClick={closeModal} sx={styles.backButton}>
            <CloseRoundedIcon />
          </IconButton>
          <Typography fontSize="1.25rem" fontWeight={600}>
            ورود یا ثبت‌نام
          </Typography>
        </Stack>
        <Stack sx={styles.contents}>
          <Typography fontSize="0.875rem" mt={{ xs: '2rem', md: '1rem' }}>
            برای ادامه، شماره موبایل خود را وارد کنید.
          </Typography>
          <TextField
            placeholder="۰۹xxxxxxxxx"
            type="tel"
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <LocalPhoneRoundedIcon sx={styles.inputAndormanet} />
              ),
            }}
            label="شماره موبایل"
            value={formik.values.phoneNumber}
            onChange={phoneChangeHandler}
            name="phoneNumber"
            autoFocus
            color="secondary"
            size="small"
            inputMode="tel"
            error={!!errorMessage}
            helperText={errorMessage || undefined}
            sx={styles.phoneInput}
          />

          <Typography
            color={(th) => th.palette.common.black}
            fontSize="0.75rem"
            fontWeight={400}
            component="span"
            mt="1rem"
            textAlign="left"
          >
            با ورود به کارنامه،{' '}
            <a href={`${ENV.LANDING_URL}/car-inspection/terms`}>
              <Typography
                color={(th) => th.palette.primary.main}
                fontSize="0.75rem"
                fontWeight={400}
                component="span"
                textAlign="left"
                sx={{
                  cursor: 'pointer',
                  borderBottom: (th) => `1px dashed ${th.palette.primary.main}`,
                }}
              >
                قوانین کارنامه{' '}
              </Typography>
            </a>
            و{' '}
            <a href={`${ENV.LANDING_URL}/privacy`}>
              <Typography
                color={(th) => th.palette.primary.main}
                fontSize="0.75rem"
                fontWeight={400}
                component="span"
                textAlign="left"
                sx={{
                  cursor: 'pointer',
                  borderBottom: (th) => `1px dashed ${th.palette.primary.main}`,
                }}
              >
                سیاست نامه حریم خصوصی{' '}
              </Typography>
            </a>{' '}
            میپذیرم.
          </Typography>
        </Stack>
        <Stack sx={styles.specer} />
        <Stack sx={styles.submitContainer}>
          <Button
            disabled={!canSubmit}
            onClick={() => formik.handleSubmit()}
            type="submit"
            sx={styles.submitButton}
          >
            تائید و ادامه
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default GetUserPhone;
