import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { PartBrandItem } from 'containers/car-services-order/components/PartBrandItem';
import { PartBrands, PartItem } from 'containers/car-services-order/requests';
import React, { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose(): void;
  data: PartBrands | undefined;
  onSelect: (id: number, name: string, item: PartItem) => void;
  defaultValue: PartItem | undefined;
};

const Header = ({ title, onClose }: { title: string; onClose: () => void }) => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        padding: '1.63rem 1rem 1.13rem',
        borderBottom: '1px solid #c9cccf',
      }}
    >
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: 600,
          lineHeight: '1.75rem',
        }}
      >
        {`انتخاب ${title}`}
      </Typography>

      <IconButton
        component={CloseIcon}
        sx={{
          position: 'absolute',
          left: '1rem',
          top: '50%',
          padding: 0,
          width: '1.5rem',
          height: '1.5rem',
          transform: 'translateY(-50%)',
          color: (theme) => theme.palette.iconDefault.main,
        }}
        onClick={onClose}
      />
    </Stack>
  );
};

const PartBrandSelectionDrawer = ({
  isOpen,
  onClose,
  data,
  onSelect,
  defaultValue,
}: Props) => {
  const [selectedPartId, setSelectedPartId] = useState<number | undefined>();
  const onItemSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPartId(parseInt(event.target.value));
    const partBrand = data?.parts.find((item) => {
      return String(item.id) === String(event.target.value);
    });
    if (partBrand && data) onSelect(data.id, data.name, partBrand);
    onClose();
  };

  useEffect(() => {
    if (defaultValue?.id) setSelectedPartId(defaultValue.id);
  }, [defaultValue?.id]);
  return (
    <Drawer
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '1.75rem 1.75rem 0 0',
          height: 'fit-content',
          maxHeight: '80%',
          minHeight: '20%',
          overflow: 'hidden',
        },
      }}
      keepMounted={false}
      anchor={'bottom'}
      open={isOpen}
      onClose={onClose}
    >
      <Header onClose={onClose} title={data?.name ?? ''} />
      <Stack sx={{ overflow: 'auto', padding: '1.5rem 1rem' }}>
        {data ? (
          data.parts.map((item) => (
            <PartBrandItem
              key={item.id}
              part={item}
              onChange={onItemSelect}
              value={selectedPartId}
              defaultValue={defaultValue}
            />
          ))
        ) : (
          <CircularProgress sx={{ alignSelf: 'center' }} />
        )}
      </Stack>
    </Drawer>
  );
};

export default PartBrandSelectionDrawer;
