import * as Sentry from '@sentry/nextjs';
import Cookies from 'universal-cookie';
import { getUserPhone } from 'utils/auth';

const setUserSentry = (username: string, viewr_id: string) => {
  Sentry.getCurrentScope().setUser({ username, id: viewr_id });
  Sentry.setUser({ username });
};
export const setSentryUserDataFromToken = () => {
  const cookie = new Cookies();
  const currentUsePhone = getUserPhone();
  const viewerId = (cookie.get('viewer_id') as string) || '';

  setUserSentry(currentUsePhone || viewerId || '', viewerId || '');
};

export const clearSentryUser = () => {
  const cookie = new Cookies();
  const viewerId = (cookie.get('viewer_id') as string) || '';
  setUserSentry(viewerId, viewerId || '');
};
