const MyCarIcon = ({ color, type }: NavigationIconProps) => {
  return (
    <svg
      width={type === 'component' ? '38' : '24'}
      height={type === 'component' ? '36' : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 18.9V20C5 20.2778 4.90221 20.5139 4.70662 20.7083C4.51102 20.9028 4.27352 21 3.99413 21C3.71471 21 3.47917 20.9028 3.2875 20.7083C3.09583 20.5139 3 20.2778 3 20V12.4C3 12.3222 3.00417 12.2444 3.0125 12.1667C3.02083 12.0889 3.04167 12.0083 3.075 11.925L5.025 6.025C5.125 5.70833 5.30625 5.45833 5.56875 5.275C5.83125 5.09167 6.125 5 6.45 5H17.55C17.875 5 18.1687 5.09167 18.4313 5.275C18.6938 5.45833 18.875 5.70833 18.975 6.025L20.925 11.925C20.9583 12.0083 20.9792 12.0889 20.9875 12.1667C20.9958 12.2444 21 12.3222 21 12.4V20C21 20.2778 20.9022 20.5139 20.7066 20.7083C20.511 20.9028 20.2735 21 19.9941 21C19.7147 21 19.4792 20.9028 19.2875 20.7083C19.0958 20.5139 19 20.2778 19 20V18.9H5ZM5.075 10.65H18.925L17.55 6.5H6.45L5.075 10.65ZM7.14413 16.15C7.53138 16.15 7.85417 16.0188 8.1125 15.7563C8.37083 15.4938 8.5 15.175 8.5 14.8C8.5 14.4111 8.36875 14.0806 8.10625 13.8083C7.84375 13.5361 7.525 13.4 7.15 13.4C6.76112 13.4 6.43056 13.5355 6.15832 13.8066C5.88611 14.0777 5.75 14.4069 5.75 14.7941C5.75 15.1814 5.88554 15.5042 6.15663 15.7625C6.42769 16.0208 6.75686 16.15 7.14413 16.15ZM16.875 16.15C17.2639 16.15 17.5944 16.0188 17.8667 15.7563C18.1389 15.4938 18.275 15.175 18.275 14.8C18.275 14.4111 18.1395 14.0806 17.8684 13.8083C17.5973 13.5361 17.2681 13.4 16.8809 13.4C16.4936 13.4 16.1708 13.5355 15.9125 13.8066C15.6542 14.0777 15.525 14.4069 15.525 14.7941C15.525 15.1814 15.6562 15.5042 15.9187 15.7625C16.1813 16.0208 16.5 16.15 16.875 16.15ZM4.5 17.4H19.5V12.15H4.5V17.4Z"
        fill={color ?? '#A9A9AA'}
      />
      <g clipPath="url(#clip0_1674_1811)">
        <circle cx="15.5" cy="5.5" r="2.5" fill="white" />
        <path
          d="M19.1047 7.6471L19.0275 7.56958C19.4248 6.85702 19.5978 6.0038 19.4484 5.11223L19.4483 5.11147C19.1765 3.50389 17.8411 2.22543 16.221 2.02852C13.7835 1.72909 11.7296 3.78296 12.029 6.22047C12.2259 7.84065 13.5044 9.17604 15.112 9.44783L15.1127 9.44796C16.0044 9.5973 16.8577 9.42432 17.5703 9.02692L17.6468 9.10338L19.7718 11.2284C20.172 11.6286 20.8236 11.6286 21.2239 11.2284C21.624 10.8283 21.6241 10.1771 21.2244 9.77677C21.2242 9.7766 21.224 9.77644 21.2239 9.77627L19.1047 7.6471ZM15.7503 7.49982C14.7815 7.49982 14.0003 6.71868 14.0003 5.74982C14.0003 4.78097 14.7815 3.99982 15.7503 3.99982C16.7192 3.99982 17.5003 4.78097 17.5003 5.74982C17.5003 6.71868 16.7192 7.49982 15.7503 7.49982Z"
          fill={color ?? '#A9A9AA'}
          stroke="white"
        />
        <path
          d="M15.6498 9.2874C14.6665 9.2874 13.8311 8.94365 13.1436 8.25615C12.4561 7.56865 12.1123 6.73324 12.1123 5.7499C12.1123 4.76657 12.4561 3.93115 13.1436 3.24365C13.8311 2.55615 14.6665 2.2124 15.6498 2.2124C16.6331 2.2124 17.4686 2.55615 18.1561 3.24365C18.8436 3.93115 19.1873 4.76657 19.1873 5.7499C19.1873 6.13324 19.1373 6.48115 19.0373 6.79365C18.9373 7.10615 18.804 7.37907 18.6373 7.6124L21.3373 10.3249C21.4873 10.4832 21.5623 10.6687 21.5623 10.8812C21.5623 11.0937 21.4831 11.2749 21.3248 11.4249C21.1748 11.5749 20.9915 11.6499 20.7748 11.6499C20.5581 11.6499 20.3748 11.5749 20.2248 11.4249L17.5373 8.7374C17.2956 8.90407 17.0144 9.0374 16.6936 9.1374C16.3727 9.2374 16.0248 9.2874 15.6498 9.2874ZM15.6498 7.7124C16.1998 7.7124 16.6644 7.52282 17.0436 7.14365C17.4227 6.76449 17.6123 6.2999 17.6123 5.7499C17.6123 5.1999 17.4227 4.73532 17.0436 4.35615C16.6644 3.97699 16.1998 3.7874 15.6498 3.7874C15.0998 3.7874 14.6352 3.97699 14.2561 4.35615C13.8769 4.73532 13.6873 5.1999 13.6873 5.7499C13.6873 6.2999 13.8769 6.76449 14.2561 7.14365C14.6352 7.52282 15.0998 7.7124 15.6498 7.7124Z"
          fill={color ?? '#A9A9AA'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1674_1811">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(10 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MyCarIcon;
