const KarnamaIcon = ({ size }: NavigationIconProps) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        className="cls-1"
        fill="#00A754"
        d="M19.43,7.57l-3.24,1.87v-.44c0-1.11-.9-2.01-2.01-2.01H5.58c-1.11,0-2.01,.9-2.01,2.01v6c0,1.11,.9,2.01,2.01,2.01H14.18c1.11,0,2.01-.9,2.01-2.01v-.44l3.24,1.87c.44,.26,1-.06,1-.58v-7.71c0-.51-.55-.83-1-.58Zm-6.37,3.57c0,.16-.07,.3-.19,.4l-3.8,3.11c-.15,.12-.37,.12-.52,0l-1.67-1.37c-.12-.1-.19-.25-.19-.4v-1.88l1.99,1.64c.08,.06,.18,.06,.26,0l4.12-3.38v1.88Z"
      />
    </svg>
  );
};
export default KarnamaIcon;
