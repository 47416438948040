import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LeasingValidationPageState {
  prepayment?: number;
  installmentMonthCount?: number;
  selectedPackage: GetProviderPackagesItem | null;
  selectedPackageId: string | undefined;
  selectedLeasingPackage: ProviderItem | null;
  providers: ProviderItem[];
  userInfo: {
    full_name: string;
    national_code: string;
    phone_number: string;
    city: null | number;
    address: string;
  };
}

const initialState: LeasingValidationPageState = {
  prepayment: undefined,
  providers: [],
  selectedLeasingPackage: null,
  installmentMonthCount: undefined,
  selectedPackageId: undefined,
  selectedPackage: null,
  userInfo: {
    full_name: '',
    national_code: '',
    phone_number: '',
    address: '',
    city: null,
  },
};

const { actions, reducer } = createSlice({
  name: 'leasing-validation',
  initialState: initialState,
  reducers: {
    setProviders: (
      state,
      action: PayloadAction<LeasingValidationPageState['providers']>,
    ) => {
      state.providers = action.payload;
    },
    setPrepayment: (
      state,
      action: PayloadAction<LeasingValidationPageState['prepayment']>,
    ) => {
      state.prepayment = action.payload;
    },
    setSelectedLeasingPackage: (
      state,
      action: PayloadAction<
        LeasingValidationPageState['selectedLeasingPackage']
      >,
    ) => {
      state.selectedLeasingPackage = action.payload;
    },
    setInstallmentMonthCount: (
      state,
      action: PayloadAction<
        LeasingValidationPageState['installmentMonthCount']
      >,
    ) => {
      state.installmentMonthCount = action.payload;
    },
    setSelectedPackage: (
      state,
      action: PayloadAction<LeasingValidationPageState['selectedPackage']>,
    ) => {
      state.selectedPackage = action.payload;
    },
    setSelectedPackageId: (
      state,
      action: PayloadAction<LeasingValidationPageState['selectedPackageId']>,
    ) => {
      state.selectedPackageId = action.payload;
    },
    setuserInfo: (
      state,
      action: PayloadAction<LeasingValidationPageState['userInfo']>,
    ) => {
      state.userInfo.address = action.payload.address;
      state.userInfo.city = action.payload.city;
      state.userInfo.full_name = action.payload.full_name;
      state.userInfo.national_code = action.payload.national_code;
      state.userInfo.phone_number = action.payload.phone_number;
    },
  },
});

export const {
  setInstallmentMonthCount: setLeasingValidationInstallmentMonthCount,
  setProviders: setLeasingProviders,
  setPrepayment: setLeasingValidationPrepayment,
  setSelectedPackage: setLeasingValidationSelectedPackage,
  setuserInfo: setLeasingValidationUserInfo,
  setSelectedLeasingPackage,
  setSelectedPackageId: setLeasingSelectedPackageId,
} = actions;

export default reducer;
