import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import { apiClient } from 'services/clients';
import { postStorageEndPoints } from 'services/configs';

export const getRelatedAds = (params: GetRelatedAdsParams) => {
  return apiClient<GetRelatedAdsResponse>({
    method: 'GET',
    url: postStorageEndPoints.RELATED_CARS,
    params,
    paramsSerializer: {
      indexes: null,
    },
  }).then((res) => res.data);
};

export const getRelatedAdsKey = (params: GetRelatedAdsParams): QueryKey => {
  return [
    ...(params.brand_models || ['no-brand-model']),
    ...(params.cities || ['no-cities']),
    params.max_price || 'max_price_empty',
    params.min_price || 'min_price_empty',
    params.max_year || 'max_year_empty',
    params.min_year || 'min_year_empty',
    params.size || 10,
  ];
};

export const useGetRelatedAds = (
  params: GetRelatedAdsParams,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getRelatedAds>>,
    unknown,
    Awaited<ReturnType<typeof getRelatedAds>>
  >,
) => {
  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRelatedAds>>
  > = () => {
    return getRelatedAds(params);
  };

  const queryKey = getRelatedAdsKey(params);

  return useQuery({
    queryFn,
    queryKey,
    ...options,
  });
};
