import usePageClickTracer from 'hooks/usePageClickTracer';
import { FC, PropsWithChildren } from 'react';

const AppClickTracerProvider: FC<PropsWithChildren> = ({ children }) => {
  usePageClickTracer();

  return <>{children}</>;
};

export default AppClickTracerProvider;
