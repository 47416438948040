import Cookie from 'universal-cookie';

interface UtmObjectType {
  readonly campaign: string;
  readonly utm_campaign: string;
  readonly campaign_id: string;
  readonly content: string;
  readonly medium: string;
  readonly source: string;
  readonly term: string;
  readonly utm_content: string;
  readonly utm_medium: string;
  readonly utm_source: string;
  readonly utm_term: string;
  readonly document_referrer: string;
}

const utmObject: UtmObjectType = {
  campaign: 'campaign_id',
  utm_campaign: 'campaign_id',
  campaign_id: 'campaign_id',
  content: 'utm_content',
  medium: 'utm_medium',
  source: 'utm_source',
  term: 'utm_term',
  utm_content: 'utm_content',
  utm_medium: 'utm_medium',
  utm_source: 'utm_source',
  utm_term: 'utm_term',
  document_referrer: 'document_referrer',
};

export interface MakeUtmObjectType {
  utm_campaign?: string;
  campaign_id?: string;
  document_referrer?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

export function makeUtmObject(
  userCookie?: string | object | null | undefined,
): MakeUtmObjectType {
  const cookie =
    typeof userCookie === 'undefined' ? new Cookie() : new Cookie(userCookie);
  const campObj =
    cookie.get('analytics_campaign2') || cookie.get('analytics_campaign2');
  if (campObj && campObj !== null) {
    const result: { [key: string]: any } = {};
    Object.keys(utmObject).forEach((utmKey) => {
      if (utmKey in campObj) {
        result[utmObject[utmKey as keyof UtmObjectType]] = campObj[utmKey];
      }
    });

    if (result?.campaign_id) {
      result.utm_campaign = result.campaign_id || undefined;
    }

    result.document_referrer = document.referrer;
    return result;
  } else {
    return {};
  }
}
