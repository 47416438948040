import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CarUsage =
  | 'no_mileage'
  | 'low_mileage'
  | 'mid_mileage'
  | 'high_mileage'
  | string;
type CarColor = 'white' | 'grey' | 'black' | 'other_colors' | string;
type CarCategory = 'urban' | 'offroad' | string;
type CarType = 'hatchback' | 'sedan' | 'suv' | string;

export interface ConcierageBuyPageState {
  steps: {
    stepIndex: number;
    userType: 'expert' | 'newbie' | 'both';
    prevStep: 'expert' | 'newbie';
  };
  isLoading: boolean;
  toPaymentPage: boolean;
  wasPaymentSuccessful?: boolean;
  userOrderInfo: {
    budget: string;
    isLeasing: boolean;
    province: number;
    fullName: string;
    canPresenceInTehran: boolean;
    selectedPlan: string;
    phoneNumber: string;
    otp: string;
    discountCode: string;
    isDiscountCodeValid: boolean;
    lastLevel?: 'brand' | 'model' | 'type' | string;
    lastLevelId?: number;
    planPurchaseId?: number;
    invoiceToken?: string;
    orderId?: string;
    isAgreeWithTerms: boolean;
  };
  expertUserOrder: {
    expertBrandModelType: string;
    expertCarUsageList: CarUsage[];
    expertCarColorList: CarColor[];
  };
  newbieUserOrder: {
    newbieCarCategories: CarCategory[];
    newbieCarTypes: CarType[];
    newbieCarExample: string;
    newbieGearBoxType: ('manual' | 'automatic' | string)[];
    newbieCarUsageList: CarUsage[];
    newbieCarColorList: CarColor[];
  };
}

const initialState: ConcierageBuyPageState = {
  steps: { stepIndex: 0, userType: 'expert', prevStep: 'expert' },
  isLoading: false,
  toPaymentPage: false,
  userOrderInfo: {
    budget: '',
    isLeasing: false,
    fullName: '',
    province: -1,
    canPresenceInTehran: false,
    selectedPlan: '',
    phoneNumber: '',
    otp: '',
    discountCode: '',
    isDiscountCodeValid: false,
    isAgreeWithTerms: false,
  },
  expertUserOrder: {
    expertBrandModelType: '',
    expertCarUsageList: [],
    expertCarColorList: [],
  },
  newbieUserOrder: {
    newbieCarCategories: [],
    newbieCarTypes: [],
    newbieCarExample: '',
    newbieGearBoxType: [],
    newbieCarUsageList: [],
    newbieCarColorList: [],
  },
};

const concierageBuySlice = createSlice({
  name: 'concierageBuyPage',
  initialState,
  reducers: {
    setCBSteps: (
      state,
      action: PayloadAction<ConcierageBuyPageState['steps']>,
    ) => {
      state.steps = action.payload;
    },
    setCBIsLoading: (
      state,
      action: PayloadAction<ConcierageBuyPageState['isLoading']>,
    ) => {
      state.isLoading = action.payload;
    },
    setCBToPaymentPage: (
      state,
      action: PayloadAction<ConcierageBuyPageState['toPaymentPage']>,
    ) => {
      state.toPaymentPage = action.payload;
    },
    setCBWasPaymentSuccesfull: (
      state,
      action: PayloadAction<ConcierageBuyPageState['wasPaymentSuccessful']>,
    ) => {
      state.wasPaymentSuccessful = action.payload;
    },
    setCBUserOrderInfo: (
      state,
      action: PayloadAction<Partial<ConcierageBuyPageState['userOrderInfo']>>,
    ) => {
      state.userOrderInfo = { ...state.userOrderInfo, ...action.payload };
    },
    setCBUserOrderInfoPhoneNumber: (
      state,
      action: PayloadAction<
        ConcierageBuyPageState['userOrderInfo']['phoneNumber']
      >,
    ) => {
      state.userOrderInfo.phoneNumber = action.payload;
    },
    setCBExpertUserOrder: (
      state,
      action: PayloadAction<Partial<ConcierageBuyPageState['expertUserOrder']>>,
    ) => {
      state.expertUserOrder = { ...state.expertUserOrder, ...action.payload };
    },
    setCBNewbieUserOrder: (
      state,
      action: PayloadAction<Partial<ConcierageBuyPageState['newbieUserOrder']>>,
    ) => {
      state.newbieUserOrder = { ...state.newbieUserOrder, ...action.payload };
    },
    resetCBUserOrderInfo: (state) => ({
      ...state,
      userOrderInfo: initialState.userOrderInfo,
    }),
    resetCBExpertUserOrder: (state) => ({
      ...state,
      expertUserOrder: initialState.expertUserOrder,
    }),
    resetCBNewbieUserOrder: (state) => ({
      ...state,
      newbieUserOrder: initialState.newbieUserOrder,
    }),
  },
});

export const {
  setCBSteps,
  setCBIsLoading,
  setCBToPaymentPage,
  setCBWasPaymentSuccesfull,
  setCBExpertUserOrder,
  setCBNewbieUserOrder,
  setCBUserOrderInfo,
  setCBUserOrderInfoPhoneNumber,
  resetCBExpertUserOrder,
  resetCBNewbieUserOrder,
  resetCBUserOrderInfo,
} = concierageBuySlice.actions;

export default concierageBuySlice.reducer;
