import Layout from 'containers/car-services-order/components/layout';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  useCreateLead,
  useGetCarProfiles,
} from '../../../../services/rest/car-exchange';
import { CarProfileDto } from '../../../../services/rest/car-exchange/carExchange';
import { CAR_EXCHANGE_KEY, CarExchangeState } from '../../configs';
import AddNewCar from './AddCar';
import CarList from './CarList';
import SelectOneCar from './SelectOneCar';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

export type ModeType = 'default' | 'addCar' | 'carAdded' | 'edit';

const SelectCar: FC<PropTypes> = ({ currentStep, totalStep: totalStep }) => {
  const layoutRef = useRef<HTMLDivElement | null>(null);
  const { data } = useGetCarProfiles();
  const [carId, setCarId] = useState<string>('');
  const [mode, setMode] = useState<ModeType>('default');
  const createLead = useCreateLead();
  const [uuid, setUuid] = useState<string>('');

  const selectedCar = useMemo<CarProfileDto | undefined>(() => {
    if (data && carId) return data.find((car) => car.id === carId);
  }, [carId, data]);

  const updateLocalStorage = (id: string) => {
    const carExchangeState: CarExchangeState = JSON.parse(
      localStorage.getItem(CAR_EXCHANGE_KEY) ?? '',
    );
    carExchangeState.uuid = id;

    localStorage.setItem(CAR_EXCHANGE_KEY, JSON.stringify(carExchangeState));
  };

  useEffect(() => {
    createLead.mutate(
      {},
      {
        onSuccess: (res) => {
          setUuid(res.data.uuid);
          updateLocalStorage(res.data.uuid);
        },
      },
    );
  }, []);

  return (
    <Layout
      ref={layoutRef}
      header={{
        title: 'انتخاب خودرو',
        currentPage: currentStep,
        totalPage: totalStep,
        goPrevStep: false,
        prevLink: '/',
      }}
      extraScroll={false}
      isReactiveToResize={true}
    >
      {selectedCar && mode === 'carAdded' && (
        <SelectOneCar
          car={selectedCar}
          mode={mode}
          uuid={uuid}
          onChangeMode={(selectedMode) => setMode(selectedMode)}
        />
      )}
      {mode === 'addCar' && (
        <AddNewCar
          mode={mode}
          onChangeMode={(id, selectedMode) => {
            setCarId(id);
            setMode(selectedMode);
          }}
        />
      )}
      {mode === 'edit' && data && (
        <AddNewCar
          mode={mode}
          onChangeMode={(id, selectedMode) => {
            setCarId(id);
            setMode(selectedMode);
          }}
          car={data.length === 1 ? data[0] : selectedCar}
        />
      )}
      {data && mode === 'default' && (
        <>
          {data?.length === 1 ? (
            <SelectOneCar
              car={data[0]}
              onChangeMode={(selectedMode) => setMode(selectedMode)}
              uuid={uuid}
            />
          ) : data.length > 1 ? (
            <CarList
              cars={data}
              onChangeMode={() => setMode('addCar')}
              uuid={uuid}
            />
          ) : (
            <AddNewCar
              onChangeMode={(id, selectedMode) => {
                setCarId(id);
                setMode(selectedMode);
              }}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default SelectCar;
