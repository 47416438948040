import { Modal, Stack, Typography } from '@mui/material';

export const BundleDescriptionModal = ({
  open,
  onClose,
  title,
  description,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          borderRadius: '1.75rem',
          position: 'absolute',
          top: '50%',
          left: '50%',
          padding: '1.5rem',
          minWidth: '90%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography
          sx={{
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: '2rem',
            marginBottom: '1rem',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: '1rem', fontWeight: 400, lineHeight: '1.875rem' }}
        >
          {description}
        </Typography>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '1.875rem',
            padding: '0.63rem 0',
            alignSelf: 'end',
            cursor: 'pointer',
            color: (theme) => theme.palette.secondary.main,
          }}
          onClick={onClose}
        >
          متوجه شدم
        </Typography>
      </Stack>
    </Modal>
  );
};
