import { QueryFlags } from 'hooks/useQueryFlags';
import { stringify } from 'querystring';

export const seoPriceList = [
  {
    price: '300000000',
    minMaxFromLabel: '300',
    minMaxToLabel: '300',
    minPriceLabel: '300 میلیون',
    maxPriceLabel: '300 میلیون',
  },
  {
    price: '400000000',
    minMaxFromLabel: '400',
    minMaxToLabel: '400',
    minPriceLabel: '400 میلیون',
    maxPriceLabel: '400 میلیون',
  },
  {
    price: '500000000',
    minMaxFromLabel: '500',
    minMaxToLabel: '500',
    minPriceLabel: '500 میلیون',
    maxPriceLabel: '500 میلیون',
  },
  {
    price: '600000000',
    minMaxFromLabel: '600',
    minMaxToLabel: '600',
    minPriceLabel: '600 میلیون',
    maxPriceLabel: '600 میلیون',
  },
  {
    price: '700000000',
    minMaxFromLabel: '700',
    minMaxToLabel: '700',
    minPriceLabel: '700 میلیون',
    maxPriceLabel: '700 میلیون',
  },
  {
    price: '800000000',
    minMaxFromLabel: '800',
    minMaxToLabel: '800',
    minPriceLabel: '800 میلیون',
    maxPriceLabel: '800 میلیون',
  },
  {
    price: '900000000',
    minMaxFromLabel: '900',
    minMaxToLabel: '900',
    minPriceLabel: '900 میلیون',
    maxPriceLabel: '900 میلیون',
  },
  {
    price: '1000000000',
    minMaxFromLabel: '1',
    minMaxToLabel: '1 میلیارد',
    minPriceLabel: '1 میلیارد',
    maxPriceLabel: '1 میلیارد',
  },
  {
    price: '2000000000',
    minMaxFromLabel: '2',
    minMaxToLabel: '2 میلیارد',
    minPriceLabel: '2 میلیارد',
    maxPriceLabel: '2 میلیارد',
  },
  {
    price: '2000000000',
    minMaxFromLabel: '2',
    minMaxToLabel: '2 میلیارد',
    minPriceLabel: '2 میلیارد',
    maxPriceLabel: '2 میلیارد',
  },
  {
    price: '3000000000',
    minMaxFromLabel: '3',
    minMaxToLabel: '3 میلیارد',
    minPriceLabel: '3 میلیارد',
    maxPriceLabel: '3 میلیارد',
  },
  {
    price: '4000000000',
    minMaxFromLabel: '4',
    minMaxToLabel: '4 میلیارد',
    minPriceLabel: '4 میلیارد',
    maxPriceLabel: '4 میلیارد',
  },
  {
    price: '5000000000',
    minMaxFromLabel: '5',
    minMaxToLabel: '5 میلیارد',
    minPriceLabel: '5 میلیارد',
    maxPriceLabel: '5 میلیارد',
  },
  {
    price: '6000000000',
    minMaxFromLabel: '6',
    minMaxToLabel: '6 میلیارد',
    minPriceLabel: '6 میلیارد',
    maxPriceLabel: '6 میلیارد',
  },
  {
    price: '7000000000',
    minMaxFromLabel: '7',
    minMaxToLabel: '7 میلیارد',
    minPriceLabel: '7 میلیارد',
    maxPriceLabel: '7 میلیارد',
  },
  {
    price: '8000000000',
    minMaxFromLabel: '8',
    minMaxToLabel: '8 میلیارد',
    minPriceLabel: '8 میلیارد',
    maxPriceLabel: '8 میلیارد',
  },
  {
    price: '9000000000',
    minMaxFromLabel: '9',
    minMaxToLabel: '9 میلیارد',
    minPriceLabel: '9 میلیارد',
    maxPriceLabel: '9 میلیارد',
  },
];

/**
 * this function creates the new url of car-price page based on
 *  the passed new filters.
 * if only one brand passed, it will goes to brand page.
 * if minPrice and maxPrice passed and the values of these variables are
 *  in seo price list, it will goes on price page.
 * otherwaze it goes on root page of car-price with passing the
 *  filters in query.
 */
export const createFilterUrl = (
  brand: string[],
  minPrice: string,
  maxPrice: string,
  flags: QueryFlags,
) => {
  const newQuery: {
    [queryFlagKey: string]: string | string[];
  } = {
    brand_en: brand,
    max_price: maxPrice,
    min_price: minPrice,
  };
  if (!maxPrice) {
    delete newQuery.max_price;
  }
  if (!minPrice) {
    delete newQuery.min_price;
  }
  if (!brand.length) {
    delete newQuery.brand_en;
  }
  Object.keys(flags).forEach((flagKey) => {
    if (flags[flagKey]) {
      newQuery[flagKey] = 'true';
    }
  });
  if (brand.length === 1) {
    delete newQuery.brand_en;
    return `/car-price/${brand[0]}${
      Object.keys(newQuery).length ? '?' : ''
    }${stringify(newQuery)}`;
  } else if (
    maxPrice &&
    minPrice &&
    seoPriceList.findIndex((i) => i.price === maxPrice) !== -1 &&
    seoPriceList.findIndex((i) => i.price === minPrice) !== -1 &&
    Number(maxPrice) > Number(minPrice)
  ) {
    delete newQuery.max_price;
    delete newQuery.min_price;
    return `/car-price/price/${minPrice}/${maxPrice}${
      Object.keys(newQuery).length ? '?' : ''
    }${stringify(newQuery)}`;
  } else if (
    maxPrice &&
    seoPriceList.findIndex((i) => i.price === maxPrice) !== -1
  ) {
    delete newQuery.max_price;
    return `/car-price/max-price/${maxPrice}${
      Object.keys(newQuery).length ? '?' : ''
    }${stringify(newQuery)}`;
  } else if (
    minPrice &&
    seoPriceList.findIndex((i) => i.price === minPrice) !== -1
  ) {
    delete newQuery.min_price;
    return `/car-price/min-price/${minPrice}${
      Object.keys(newQuery).length ? '?' : ''
    }${stringify(newQuery)}`;
  } else {
    return `/car-price${Object.keys(newQuery).length ? '?' : ''}${stringify(
      newQuery,
    )}`;
  }
};
