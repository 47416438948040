import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import useDocumentHeight from 'hooks/useDocumentHeight';
import useFunnelContext from 'hooks/useFunnelContext';
import { useRouter } from 'next/router';
import { PropsWithChildren, forwardRef, useState } from 'react';
import { CAR_SERVICES_LOCALSTORAGE_KEY, useCarServices } from '../../configs';
import styles from './styles';

interface LayoutProps {
  header: {
    title: string;
    totalPage: number;
    currentPage: number;
    goPrevStep?: boolean;
    prevLink?: string | null;
    hasInfoIcon?: boolean;
    onInfoClick?: () => void;
    pureBack?: boolean;
  };
  extraScroll?: boolean;
  hasFlexGrow?: boolean;
  hasFullWidth?: boolean;
  noContentWrapperPadding?: boolean;
  backgroundColor?: any;
  isReactiveToResize?: boolean;
  noContentDesktopWrapperPadding?: boolean;
}

const Layout = forwardRef<HTMLDivElement, PropsWithChildren<LayoutProps>>(
  (
    {
      header: {
        title: headerTitle,
        totalPage,
        currentPage,
        goPrevStep = true,
        prevLink = null,
        hasInfoIcon = false,
        onInfoClick,
        pureBack = false,
      },
      children,
      extraScroll = false,
      hasFlexGrow = false,
      hasFullWidth = false,
      noContentWrapperPadding = false,
      noContentDesktopWrapperPadding = false,
      isReactiveToResize = false,
      backgroundColor,
    },
    ref,
  ) => {
    const funnelData = useFunnelContext();
    const { replace, back } = useRouter();
    const { clearData } = useCarServices();
    const barPercentage = (currentPage / totalPage) * 100;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { height: vpHeight, offset } = useDocumentHeight();

    return (
      <>
        <Stack
          sx={{
            ...styles.container,
            width: '100%',
            height: isReactiveToResize
              ? `calc(${(vpHeight ?? 0) + (offset ?? 0)}px)`
              : '100%',
            position: isReactiveToResize ? 'relative' : 'unset',
            left: isReactiveToResize ? 0 : 'unset',
            right: isReactiveToResize ? 0 : 'unset',
            top: isReactiveToResize ? 0 : 'unset',
          }}
        >
          <Stack sx={styles.header}>
            <IconButton
              onClick={() => {
                if (!pureBack) {
                  if (prevLink) {
                    replace(prevLink);
                  } else if (goPrevStep) {
                    funnelData?.prevStep(true);
                  }
                } else {
                  back();
                }
              }}
              sx={styles.headerBackButton}
            >
              <ArrowForwardIcon />
            </IconButton>
            <Typography sx={styles.headerTitle}>{headerTitle}</Typography>
            {hasInfoIcon && (
              <IconButton
                onClick={onInfoClick}
                sx={{
                  position: 'absolute',
                  right: '1rem',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <InfoOutlinedIcon
                  sx={{
                    color: (th) => th.palette.info.main,
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                />
              </IconButton>
            )}
          </Stack>
          <Box
            key="funnel-bar"
            sx={{ ...styles.percentBar, width: `${barPercentage}%` }}
          />
          <Stack ref={ref} component="div" sx={styles.contentContainer}>
            <Stack
              className="no-thumb"
              sx={{
                ...styles.content,
                padding: {
                  xs: noContentWrapperPadding ? '0' : '24px',
                  md: noContentDesktopWrapperPadding ? '0' : '24px',
                },
                minHeight: isReactiveToResize
                  ? '100%'
                  : !hasFlexGrow
                  ? extraScroll
                    ? '200vh'
                    : '110vh'
                  : 'initial',
                width: hasFullWidth ? '100vw' : '100%',
                flexGrow: hasFlexGrow ? 1 : 0,
                backgroundColor: backgroundColor ?? 'white',
              }}
            >
              {children}
            </Stack>
          </Stack>
        </Stack>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Stack
            width="calc(100% - 32px)"
            sx={{
              backgroundColor: (th) => th.palette.surface.default,
              maxWidth: '400px',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            borderRadius="1.75rem"
            p="1.5rem"
          >
            <Typography
              fontSize="1.5rem"
              fontWeight={400}
              color={(th) => th.palette.common.black}
            >
              حذف سفارش
            </Typography>
            <Typography
              mt="1rem"
              fontSize="1.1rem"
              fontWeight={400}
              color={(th) => th.palette.common.black}
            >
              در صورت بازگشت سفارش فعلی شما حذف میشود.
            </Typography>
            <Typography
              mt="1rem"
              fontSize="1.1rem"
              fontWeight={400}
              color={(th) => th.palette.common.black}
            >
              آیا از بازگشت اطمینان دارید؟
            </Typography>

            <Stack direction="row" justifyContent="end">
              <Button
                variant="text"
                onClick={() => {
                  localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
                  clearData(null);
                  funnelData?.changeStep('car-info');
                  setIsModalOpen(false);
                }}
                color="warning"
                sx={{ alignSelf: 'flex-end', marginTop: 2 }}
              >
                حذف سفارش
              </Button>
              <Button
                variant="text"
                onClick={() => setIsModalOpen(false)}
                color="primary"
                sx={{ alignSelf: 'flex-end', marginTop: 2 }}
              >
                بستن
              </Button>
            </Stack>
          </Stack>
        </Modal>
      </>
    );
  },
);

Layout.displayName = 'Layout';

export default Layout;
