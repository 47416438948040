import CircleIcon from '@mui/icons-material/Circle';
import { Divider, Stack, Typography } from '@mui/material';

export const BundlePriceItem = ({
  brandName,
  price,
}: {
  brandName: string;
  price: number;
}) => {
  return (
    <Stack flexDirection="column">
      <Stack flexDirection="row">
        <CircleIcon
          sx={{
            width: '0.375rem',
            height: '0.375rem',
            marginTop: '10px',
            color: (theme) => theme.palette.iconDefault.main,
          }}
        />
        <Typography
          sx={{
            color: (theme) => theme.palette.iconDefault.main,
            marginX: '0.75rem',
          }}
          fontSize="0.875rem"
          fontWeight="400"
          lineHeight="1.5rem"
        >
          {brandName}
        </Typography>
      </Stack>
      <Stack
        gap={0.5}
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
      >
        <Typography fontSize="0.875rem" fontWeight="600" lineHeight="1.75rem">
          {price.toLocaleString()}
        </Typography>
        <Typography fontSize="0.675rem" fontWeight="400" lineHeight="1.25rem">
          تومان
        </Typography>
      </Stack>
      <Divider sx={{ margin: '0.5rem 0 1rem' }} />
    </Stack>
  );
};
