const HomeIcon = ({ color, type }: NavigationIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={type === 'component' ? '38' : '24'}
      height={type === 'component' ? '36' : '24'}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.5 19.5H9.25V14C9.25 13.7875 9.32188 13.6094 9.46563 13.4656C9.60938 13.3219 9.7875 13.25 10 13.25H14C14.2125 13.25 14.3906 13.3219 14.5344 13.4656C14.6781 13.6094 14.75 13.7875 14.75 14V19.5H18.5V9.75L12 4.875L5.5 9.75V19.5ZM4 19.5V9.75C4 9.5125 4.05313 9.2875 4.15937 9.075C4.26562 8.8625 4.4125 8.6875 4.6 8.55L11.1 3.675C11.3613 3.475 11.66 3.375 11.996 3.375C12.332 3.375 12.6333 3.475 12.9 3.675L19.4 8.55C19.5875 8.6875 19.7344 8.8625 19.8406 9.075C19.9469 9.2875 20 9.5125 20 9.75V19.5C20 19.9125 19.8531 20.2656 19.5594 20.5594C19.2656 20.8531 18.9125 21 18.5 21H14C13.7875 21 13.6094 20.9281 13.4656 20.7844C13.3219 20.6406 13.25 20.4625 13.25 20.25V14.75H10.75V20.25C10.75 20.4625 10.6781 20.6406 10.5344 20.7844C10.3906 20.9281 10.2125 21 10 21H5.5C5.0875 21 4.73438 20.8531 4.44063 20.5594C4.14688 20.2656 4 19.9125 4 19.5Z"
        fill={color ?? '#A9A9AA'}
      />
    </svg>
  );
};
export default HomeIcon;
