import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { apiClient } from 'services/clients';
import { userTraceEndpoints } from 'services/configs';
import Cookies from 'universal-cookie';
import {
  KARNAMEH_COOKIE_DOMAIN_VALUE,
  KARNEMEH_COOKIE_KEY_NAME,
} from 'utils/auth';
import { getIDBTableItem } from 'utils/indexeddbUtil';
import { getAppReferrer } from 'utils/sessionStorageManager';
import { makeUtmObject } from 'utils/utm';
import { generateUUID } from 'utils/uuid';

const cookie = new Cookies();

export const sendUserAction = async (params: SendUserActionParams) => {
  const isClientSide = typeof window !== 'undefined';
  let viewerId = (cookie.get('viewer_id') as string) || '';
  const url = isClientSide ? window.location.href : '';
  const referer = isClientSide ? document.referrer : '';

  const utmObj = makeUtmObject();
  if (!viewerId && isClientSide) {
    const newId = generateUUID();
    viewerId = newId;

    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    cookie.set('viewer_id', newId, {
      expires: aYearFromNow,
      domain: KARNAMEH_COOKIE_DOMAIN_VALUE,
      sameSite: 'lax',
    });
  }
  const headers = {} as any;
  const auth = cookie.get(KARNEMEH_COOKIE_KEY_NAME);
  if (auth) headers.authorization = auth;

  const najvaToken = isClientSide ? localStorage.getItem('najvaToken') : null;
  let najvaTokenData = null;
  try {
    najvaTokenData = await getIDBTableItem(
      'najva-native-subscription-database',
      'token',
      'najva_token',
    );
  } catch (e) {}

  const { event_metadata: eventMetaData, ...mainParams } = params;

  return apiClient({
    method: 'POST',
    url: userTraceEndpoints.REGISTER,
    headers,
    data: {
      ...mainParams,
      event_metadata: {
        ...eventMetaData,
        referer,
      },
      viewer_id: viewerId,
      app_referrer: getAppReferrer() === 'twa' ? 'twa' : 'web',
      url,
      utm: { ...utmObj },
      attribution_metadata: {
        najva_push_token: najvaToken ?? (najvaTokenData as any)?.value ?? null,
      },
    },
  });
};

export const useSendUserAction = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, SendUserActionParams, unknown>,
    'mutationFn'
  >,
) => {
  return useMutation({ ...options, mutationFn: sendUserAction });
};
