const TwitterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_1968_27485)">
        <path
          d="M21.5274 22H15.1164L9.24671 13.4584L1.89813 22H0L8.40437 12.2311L0 0H6.41095L11.9681 8.08801L18.9279 0H20.8261L12.8105 9.31532L21.5274 22ZM16.0121 20.6355H18.9279L5.5 1.42931H2.5842L16.0083 20.6355H16.0121Z"
          fill="#00A754"
        />
      </g>
      <defs>
        <clipPath id="clip0_1968_27485">
          <rect width="21.5274" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TwitterIcon;
