import createStyle from 'utils/createStyle';
import theme from 'theme';

const styles = createStyle({
  footerColumnTitle: {
    marginBottom: '16px',
    fontSize: '1rem',
    fontWeight: '600',
    lineHeight: '2',
    color: 'rgba(0,0,0,.56)',
  },
  footerColumnItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.75rem',
    fontWeight: '500',
    color: 'rgba(0,0,0,.56)',
    textDecoration: 'none',
    transition: 'color .36s ease',
    margin: '0 0 16px',
    lineHeight: 2,
    marginRight: '30px',
    cursor: 'pointer',
    ':last-of-type': {
      marginRight: '0px',
    },
    ':hover': {
      color: 'rgba(0,0,0,.87)',
    },
  },
  mobileFooterContainer: {
    width: '100%',
    backgroundColor: 'white',
    paddingTop: '3.12rem',
    // paddingX: '1.5rem',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  footerColumnContainer: {
    maxWidth: '566px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      alignItems: 'center',
      width: '100%',
    },
  },
  desktopMobileFooterContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  },
  footerSectionWrapper: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
    },
  },
  footerContainer: {
    width: '100%',
    maxWidth: '90.75rem !important',
    marginY: '0 !important',
    padding: '0 !important',
    marginX: 'auto',
    borderTop: (t) => `1px solid ${t.palette.surface.paper}`,
  },
  footerSection: {
    borderTop: '1px solid rgba(0,0,0,.12)',
    padding: '80px 0px',
  },
  footerCall: {
    marginLeft: '6px',
    color: 'rgba(0,0,0,.56)',
    textDecoration: 'unset',
    fontSize: '.75rem',
    fontWeight: '500',
  },
  phoneIcon: {
    width: '18px',
    height: '18px',
    transform: 'rotateY(180deg)',
  },
  instagramButton: {
    backgroundImage: 'linear-gradient(267deg,#eb36ac,#f85a4d,#ffb032)',
    padding: '4px 8px',
    fontSize: '.75rem',
    fontWeight: '700',
    lineHeight: '2',
    color: '#fff',
    borderRadius: '4px',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
    },
  },
  instagramButtonDesc: {
    margin: '0',
    fontSize: '.625rem',
    lineHeight: '2.4',
    color: 'rgba(0,0,0,.56)',
    textAlign: 'center',
    cursor: 'text',
  },
  signsContainer: {
    paddingY: '2rem',
    paddingX: '1.5rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: (t) => t.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginTop: '2rem',
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  logoItem: {
    position: 'relative',
    width: '53px',
    height: '53px',
    marginRight: '10px',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
      width: '110px',
      marginBottom: '65px',
    },
  },
});

export default styles;
