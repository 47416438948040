import { ThemeOptions } from '@mui/material';

const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: { disableElevation: true, variant: 'contained' },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(1),
        minHeight: '40px',
      }),
    },
  },
  MuiButtonGroup: {
    defaultProps: { disableElevation: true, variant: 'contained' },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(1),
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({ borderRadius: theme.spacing(1) }),
    },
  },
  MuiTextField: {
    defaultProps: { InputProps: { sx: { fontSize: ['14px', '16px'] } } },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({ minHeight: theme.spacing(7) }),
    },
  },
};

export default components;
