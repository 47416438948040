import Toast from 'components/toast';

export function displayError(message: string) {
  if (message == '3') {
    return Toast({
      message: 'مقادیر خواسته شده را بدرستی وارد نمایید',
      type: 'error',
    });
  } else {
    return Toast({ message, type: 'error' });
  }
}
