const TelegramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <g clipPath="url(#clip0_1968_27495)">
        <path
          d="M10.5 21C16.2975 21 21 16.2975 21 10.5C21 4.70253 16.2975 0 10.5 0C4.70253 0 0 4.70253 0 10.5C0 16.2975 4.70253 21 10.5 21Z"
          fill="#00A754"
        />
        <path
          d="M4.36737 10.4051C4.36737 10.4051 9.6142 8.25322 11.437 7.49373C12.1332 7.18993 14.5003 6.22157 14.5003 6.22157C14.5003 6.22157 15.5952 5.79752 15.5003 6.82917C15.4686 7.25322 15.2281 8.74056 14.9876 10.3482C14.6205 12.6266 14.2281 15.114 14.2281 15.114C14.2281 15.114 14.1648 15.8102 13.6522 15.9304C13.1395 16.0507 12.2851 15.5064 12.1332 15.3861C12.0129 15.2975 9.85471 13.9304 9.0699 13.2596C8.85471 13.076 8.6142 12.7152 9.10154 12.2912C10.1965 11.2912 11.5003 10.0444 12.2851 9.25955C12.6522 8.89246 13.0129 8.04436 11.4939 9.07601C9.34205 10.5633 7.21547 11.9558 7.21547 11.9558C7.21547 11.9558 6.72813 12.2596 5.81673 11.9874C4.90534 11.7152 3.84205 11.3482 3.84205 11.3482C3.84205 11.3482 3.1142 10.8925 4.35471 10.4051H4.36737Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1968_27495">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TelegramIcon;
