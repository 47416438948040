import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getLastUpdateText } from 'containers/car-price/data';
import { apiClient } from 'services/clients';
import { priceEstimatorEndpoints, api2Endpoints } from 'services/configs';

export interface CarPriceCar {
  name_en: string;
  name_fa: string;

  brand_en: string;
  brand_fa: string;

  model_en: string;
  model_fa: string;

  tip_en: string;
  tip_fa: string;

  last_update_text: string;

  logo_url: string;

  price: number;
  mileage: number;
  last_update: string;
  change_rate: number; // درصد تغییرات
  production_year: number; // سال ساختش
}

export type CarPriceCars = CarPriceCar[];

interface GetCarPricePricesResponse {
  date: string;
  brand_model: string;
  year: number;
  price: number;
  mileage: number;
  change: number;
}

export const getCarPricePrices = () => {
  return apiClient<GetCarPricePricesResponse[]>({
    method: 'GET',
    url: priceEstimatorEndpoints.LATEST_ZERO_PRICES,
  });
};

export interface PostCarPricePricesDetailsResponse {
  name_en: string;
  name_fa: string;
  brand_en: string;
  brand_fa: string;
  model_en: string;
  model_fa: string;
  type_en: string;
  type_fa: string;
}

export const getCarPriceCarsDetails = (brands: string[]) => {
  return apiClient<{ name_details: PostCarPricePricesDetailsResponse[] }>({
    method: 'POST',
    url: api2Endpoints.POPULAR_CAR_DETAIL,
    data: brands,
  });
};

/**
 * this function calls two request to get datas of cars.
 * @returns the merged datas of apis
 */
export const getCarPriceCars = async () => {
  const { data: initialData } = await getCarPricePrices();
  const {
    data: { name_details: preInitialDetails },
  } = await getCarPriceCarsDetails(initialData.map((i) => i.brand_model));

  const initialDetails = preInitialDetails.reduce<{
    [key: string]: PostCarPricePricesDetailsResponse;
  }>((result, item) => {
    if (!(item.name_en in result)) {
      result[item.name_en] = item;
    }
    return result;
  }, {});

  const mergedData: CarPriceCar[] = initialData
    .map((initialDataItem) => {
      const findedDetails = initialDetails[initialDataItem.brand_model];

      if (typeof findedDetails === 'undefined') {
        return null;
      }

      /**
       * in brand_en, model_en and tip_en we replace the spaces with "-".
       * this is because of we use them and put them in url.
       * in the other way we have no connection with back-end anymore
       *  to send data for it. so we have allowed to do anything with
       *  these values
       */
      return {
        name_en: findedDetails.name_en.toLowerCase(),
        name_fa: findedDetails.name_fa,
        brand_en: findedDetails.brand_en.toLowerCase(),
        brand_fa: findedDetails.brand_fa,
        model_en: findedDetails.model_en.toLowerCase(),
        model_fa: findedDetails.model_fa,
        tip_en: findedDetails.type_en.toLowerCase(),
        tip_fa: findedDetails.type_fa,
        change_rate: initialDataItem.change,
        price: initialDataItem.price,
        mileage: initialDataItem.mileage,
        last_update: initialDataItem.date,
        production_year: initialDataItem.year,
        last_update_text: getLastUpdateText(initialDataItem.date),
        logo_url: `https://cdn.karnameh.com/statics/brand-models/logos/${findedDetails.brand_en}_blackwhite.png`,
      } as CarPriceCar;
    })
    .filter((i) => i !== null) as CarPriceCar[];

  return Promise.resolve(mergedData);
};

export const getCarPriceCarsKey = ['getCarPriceCars'];

export const useGetCarPriceCars = (
  options?: UseQueryOptions<Awaited<ReturnType<typeof getCarPriceCars>>>,
) => {
  return useQuery({
    queryFn: getCarPriceCars,
    queryKey: getCarPriceCarsKey,
    ...options,
  });
};
