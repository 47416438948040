import type {
  CSSProperties,
  FC,
  HTMLAttributeAnchorTarget,
  ReactNode,
} from 'react';
import NextLink, { LinkProps } from 'next/link';

interface Props extends LinkProps {
  children: ReactNode;
  style?: CSSProperties;
  target?: HTMLAttributeAnchorTarget;
}

const AppLink: FC<Props> = ({ children, ...props }) => {
  return (
    <NextLink {...props} passHref={true}>
      {children}
    </NextLink>
  );
};

export default AppLink;
