import {
  QueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { CAR_SERVICES_LOCALSTORAGE_KEY } from 'containers/car-services-order/configs';
import { apiClient } from 'services/clients';
import {
  SuggestedPartsParams,
  TierType,
} from 'services/rest/car-services/car-services';
import Cookies from 'universal-cookie';
import { KARNEMEH_COOKIE_KEY_NAME } from 'utils/auth';
import {
  aftermarketEndpoints,
  api2Endpoints,
  endPointUrlNormalizer,
  myCarEndpoints,
} from 'services/configs';

export interface SaleAssisstantOrder {
  address: string;
  car_brand_model: string;
  car_brand_model_year: number;
  city: number;
  current_stage: string;
  customer: { full_name: string };
  usage: number;
  document_referrer?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  campaign_id?: string;
}

interface SaleAssisstantOrderResponse {
  concierge_sale_id: number;
}

interface SaleAssisstantOrderParams extends SaleAssisstantOrder {
  request_origin: 'funnel' | 'proforma';
}

const postSaleAssisstantOrderUrl = api2Endpoints.CONCIERGE_SALE_REGISTER;

const postSaleAssisstantOrder = (data: SaleAssisstantOrderParams) => {
  return apiClient<SaleAssisstantOrderResponse>({
    method: 'POST',
    url: postSaleAssisstantOrderUrl,
    data,
  });
};

export const usePostSaleAssisstantOrder = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, SaleAssisstantOrder, unknown>,
    'mutationFn'
  >,
) => {
  return useMutation({
    ...options,
    mutationFn: postSaleAssisstantOrder,
  });
};

interface PlansResponseItem {
  description: string;
  duration: number;
  features: { id: number; title: string; is_available: boolean }[];
  has_prepayment: boolean;
  id: number;
  is_visible: boolean;
  price: number;
  service_name: string;
  title: string;
  uuid: string;
}

type PlansResponse = PlansResponseItem;

const getPlansUrl = api2Endpoints.PURCHASE_PLAN;

const getPlans = () =>
  apiClient<PlansResponse>({
    method: 'GET',
    url: getPlansUrl,
    params: {
      service_name: 'CONCIERGE_SALE',
    },
  });

const getPlansKey = ['/plan-purchase/v1/plan/?service_name=CONCIERGE_SALE'];

export const useGetPlans = (
  options?: Omit<QueryOptions, 'queryFn' | 'queryKey'>,
) => {
  return useQuery<unknown, unknown, PlansResponse>({
    ...options,
    queryKey: getPlansKey,
    queryFn: getPlans,
  });
};

interface PutSaleAssisstantOrderParams {
  registerId: number;
  data: {
    plan_uuid?: string;
    address?: string;
    car_brand_model?: string;
    car_brand_model_year?: number;
    city?: number;
    current_stage?: string;
    customer?: { full_name: string };
    usage?: number;
  };
}

interface PutSaleAssisstantOrderResponse {
  invoice_token: string;
}

const putSaleAssisstantOrder = (params: PutSaleAssisstantOrderParams) => {
  return apiClient<PutSaleAssisstantOrderResponse>({
    url: endPointUrlNormalizer(api2Endpoints.CONCIERGE_SALE_PUT_SALE, {
      registerId: params.registerId.toString(),
    }),
    method: 'PUT',
    data: params.data,
  });
};

export const usePutSaleAssisstantOrder = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, PutSaleAssisstantOrderParams, unknown>,
    'mutationFn'
  >,
) => {
  return useMutation({ ...options, mutationFn: putSaleAssisstantOrder });
};

const getSaleAssisstantOrder = (params: PutSaleAssisstantOrderParams) => {
  return apiClient<PutSaleAssisstantOrderResponse>({
    url: endPointUrlNormalizer(api2Endpoints.CONCIERGE_SALE_PUT_SALE, {
      registerId: params.registerId.toString(),
    }),
    method: 'GET',
  });
};

export const useGetSaleAssisstantOrder = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, PutSaleAssisstantOrderParams, unknown>,
    'mutationFn'
  >,
) => {
  return useMutation({ ...options, mutationFn: getSaleAssisstantOrder });
};

export type GetCarManufactureYearsResponse = number[];

interface GetCarManufactureYearsParams {
  nameEn: string;
}

const getCarManufactureYears = (params: GetCarManufactureYearsParams) => {
  return apiClient<GetCarManufactureYearsResponse>({
    method: 'GET',
    url: endPointUrlNormalizer(myCarEndpoints.MANUFACTURE_YEARS, {
      carName: params.nameEn,
    }),
  }).then((res) => {
    return res.data;
  });
};

export const minManufactureYearKey = ['getCarManufactureYears'];

export const useGetManufactureYears = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof getCarManufactureYears>>,
      unknown,
      GetCarManufactureYearsParams,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: minManufactureYearKey,
    mutationFn: getCarManufactureYears,
  });
};

interface DiscountCodeParams {
  code: string;
  orderId: string;
}
interface SubmitDiscountResponse {
  discount_amount: string;
  discount_code: string;
}

const postDiscountCode = ({ code, orderId }: DiscountCodeParams) => {
  return apiClient<SubmitDiscountResponse>({
    url: endPointUrlNormalizer(aftermarketEndpoints.DISCOUNT, {
      orderID: orderId,
    }),
    method: 'POST',
    data: { discount_code: code },
  }).then((res) => res.data);
};

export const useSubmitDiscountCode = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, DiscountCodeParams, unknown>,
    'mutationFn'
  >,
) => {
  return useMutation({ ...options, mutationFn: postDiscountCode });
};

const deleteDiscountCode = ({ code, orderId }: DiscountCodeParams) => {
  const cookie = new Cookies();
  const token = cookie.get(KARNEMEH_COOKIE_KEY_NAME);
  return apiClient({
    url: endPointUrlNormalizer(aftermarketEndpoints.DELETE_DISCOUNT, {
      orderID: orderId,
      code: code,
    }),
    // url: `/public/orders/${orderId}/discount/${code}/`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.data);
};

export const useDeleteDiscountCode = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, DiscountCodeParams, unknown>,
    'mutationFn'
  >,
) => {
  return useMutation({ ...options, mutationFn: deleteDiscountCode });
};

export interface SelectiveRelatedPartsParams extends SuggestedPartsParams {}

export type PartItem = {
  id: number;
  name: string;
  market_price: number;
  part_price_id: number;
};

export type PartBrands = {
  id: number;
  name: string;
  parts: PartItem[];
};

export type SelectiveRelatedPartsResponse = PartBrands[];

export const getSelectiveRelatedParts = (
  _params: SelectiveRelatedPartsParams,
) => {
  const params = new URLSearchParams();
  params.append('brand_model', _params.brand_model);
  params.append('manufactured_year', _params.manufacture_year);
  _params.ids.forEach((id) => {
    params.append('service_types', `${id}`);
  });
  return apiClient<SelectiveRelatedPartsResponse>({
    method: 'GET',
    url: aftermarketEndpoints.SELECTED_PARTS,
    params,
  });
};

export const getSelectiveRelatedPartsKeys = (
  params: SelectiveRelatedPartsParams,
) => [
  `${params.brand_model}-${params.manufacture_year}-SelectiveParts`,
  ...params.ids,
];

export const useGetSelectiveRelatedParts = (
  params: SelectiveRelatedPartsParams,
  options?: Omit<
    UseQueryOptions<
      SelectiveRelatedPartsResponse,
      unknown,
      SelectiveRelatedPartsResponse,
      ReturnType<typeof getSelectiveRelatedPartsKeys>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = getSelectiveRelatedPartsKeys(params);
  return useQuery({
    ...options,
    queryKey: queryKey,
    queryFn: () => {
      return getSelectiveRelatedParts(params).then((res) => res.data);
    },
  });
};

export type ShiftTimeParam = {
  order: string;
};
export type ShiftTimeResponse = {
  [key: string]: {
    id: number;
    start_time: string;
    end_time: string;
  }[];
};
export const getShiftTimes = (_params: ShiftTimeParam) => {
  const params = new URLSearchParams();
  params.append('order', _params.order);

  return apiClient<ShiftTimeResponse>({
    method: 'GET',
    url: aftermarketEndpoints.TIME_SLOTS,
    params,
  });
};

const getShiftTimeKey = (param: string) => ['shiftTimes', param];

export const useGetShiftTimes = (
  params: ShiftTimeParam,
  options?: Omit<
    UseQueryOptions<ShiftTimeResponse, unknown, ShiftTimeResponse, string[]>,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = getShiftTimeKey(params.order);
  return useQuery({
    ...options,
    queryKey: queryKey,
    queryFn: () => {
      return getShiftTimes(params).then((res) => res.data);
    },
  });
};

export type CreateQuotationParams = {
  brand_model_fa: string;
  brand_model_en: string;
  mileage: number;
  manufactured_year: number;
};
export type QuotationType = {
  id: number;
  created_at: string;
  updated_at: string;
  uuid: string;
  city: string;
  brand_model_en: string;
  brand_model_fa: string;
  manufactured_year: string;
  mileage: number;
  description: string;
  address: string;
  visit_time: string | null;
  tier: string | null;
  order: string | null;
  parts?: number[];
  service_types: number[];
};
export interface CreateQuotationResponse extends QuotationType {}

const createQuotation = (data: CreateQuotationParams) => {
  return apiClient<CreateQuotationResponse>({
    method: 'POST',
    url: aftermarketEndpoints.QUOTATIONS,
    data,
  });
};

export const useCreateQuotation = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, CreateQuotationParams, unknown>,
    'mutationFn'
  >,
) => {
  return useMutation({
    ...options,
    mutationFn: createQuotation,
  });
};

export type UpdateQuotationParams = {
  uuid: string;
  manufactured_year?: string;
  mileage?: number;
  service_types?: number[];
  part_prices?: number[];
  tier?: TierType;
  parts?: number[];
};
export type UpdateQuotationResponse = {};

const updateQuotation = ({ uuid, ...data }: UpdateQuotationParams) => {
  return apiClient<UpdateQuotationResponse>({
    method: 'PATCH',
    url: endPointUrlNormalizer(aftermarketEndpoints.UPDATE_FETCH_QUOTATIONS, {
      uuid: uuid,
    }),
    data,
  });
};

export const useUpdateQuotation = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, UpdateQuotationParams, unknown>,
    'mutationFn'
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: updateQuotation,
    onSuccess: (...args) => {
      const draftData = localStorage.getItem(CAR_SERVICES_LOCALSTORAGE_KEY);
      const parsedData = draftData !== null ? JSON.parse(draftData) : null;
      queryClient.invalidateQueries([`quotation-${parsedData.quotationUUID}`]);
      options?.onSuccess?.(...args);
    },
  });
};

export type GetQuotationParam = {
  uuid: string;
};
export interface GetQuotationResponse extends QuotationType {}

export const getQuotation = (params: GetQuotationParam) => {
  return apiClient<GetQuotationResponse>({
    method: 'GET',
    url: endPointUrlNormalizer(aftermarketEndpoints.UPDATE_FETCH_QUOTATIONS, {
      uuid: params.uuid,
    }),
  });
};

export const useGetQuotation = (
  params: GetQuotationParam,
  options?: Omit<
    UseQueryOptions<
      GetQuotationResponse,
      unknown,
      GetQuotationResponse,
      string[]
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = `quotation-${params.uuid}`;
  return useQuery({
    ...options,
    queryKey: [queryKey],
    queryFn: () => {
      return getQuotation(params).then((res) => res.data);
    },
  });
};

interface ReminderParams {
  upcoming_autoservice?: string;
  previous_autoservice_date?: string;
  previous_autoservice_mileage?: number;
}

const createReminder = (props: ReminderParams) => {
  return apiClient({
    url: aftermarketEndpoints.REMINDERS,
    method: 'POST',
    data: props,
  }).then((res) => res.data);
};

export const useCreateReminder = (
  options?: Omit<
    UseMutationOptions<unknown, unknown, unknown, unknown>,
    'mutationFn'
  >,
) => {
  return useMutation({ ...options, mutationFn: createReminder });
};
