import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { GetServerSidePropsContext } from 'next';
import Cookies from 'universal-cookie';
import { removeToken } from 'utils/auth';
import ENV from '../constants/environments';
import { isTwaApp } from '../utils/sessionStorageManager';

const decode = (arr = []) => {
  const decoder = new TextDecoder();
  const encodedArray = Uint8Array.from(arr);

  return decoder.decode(encodedArray);
};

if (typeof window !== 'undefined') {
  const cookie = new Cookies(document.cookie);
  const groupType = cookie.get('ab-test-token');
  axios.defaults.headers.common['x-ab-test-group-type'] = decode(groupType);
}

axios.interceptors.response.use((config) => {
  if (config.status === 403 || config.status === 401) removeToken();
  return config;
});

const createGatewayClient = () => {
  const baseURL = ENV.API_GW_BASE_URL;
  return <Response>(
    configs: AxiosRequestConfig,
    req?: GetServerSidePropsContext['req'],
  ) => {
    const headers = configs.headers || {};

    if (
      typeof configs.url === 'string' &&
      /^\/(fast|rest|trade)/.test(configs.url)
    ) {
      headers['user-platform'] = isTwaApp() ? 'twa' : 'web';
    }

    if (typeof req !== 'undefined' && typeof window === 'undefined') {
      headers['X-Real-Ip'] = req.headers['x-forwarded-for'];
    }

    configs.headers = headers;

    return axios.request<Response>({ ...configs, baseURL });
  };
};

export const apiClient = createGatewayClient();

export type ErrorType<Error> = AxiosError<Error>;
