import createStyle from 'utils/createStyle';

const styles = createStyle({
  input: {
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: (theme) => theme.palette.iconDefault.main,
    fontSize: '12px',
  },
  loading: {
    width: '100%',
    padding: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  circularProgress: {
    color: (theme) => theme.palette.iconDefault.main,
    marginBottom: 1,
  },
  searchText: {
    color: (theme) => theme.palette.iconDefault.main,
    fontSize: '12px',
  },
  searchIcon: {
    color: (theme) => theme.palette.iconDefault.main,
  },
});

export default styles;
