import useFunnel from 'components/funnel/useFunnel';
import { createContext, useContext } from 'react';

export const funnelContext = createContext<ReturnType<typeof useFunnel> | null>(
  null,
);

const useFunnelContext = () => {
  const data = useContext(funnelContext);
  return data;
};

export default useFunnelContext;
