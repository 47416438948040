import { Box, styled } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import components from 'theme/components';
import palette from 'theme/palette';
import typography from 'theme/typography';

import '@mui/lab/themeAugmentation';

const theme = createTheme({
  direction: 'rtl',
  typography,
  palette,
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1366,
      xl: 1536,
    },
  },
});

export const ExtraBoldFontTitleItem = styled(Box)`
  font-size: 16px;
  font-weight: 500;
`;

export default theme;
