import ENV from 'constants/environments';
import { CarPricePropTypes } from 'containers/car-price';
import { seoPriceList } from 'containers/car-price/components/Filters/data';
import { ParsedUrlQuery } from 'querystring';
import {
  HeaderContents,
  getHeader,
} from 'services/rest/dynamic-contents/dynamic-contents';
import {
  CarPriceCar,
  CarPriceCars,
  getCarPriceCars,
} from 'services/rest/zero-price';
import { getQueryValueAsArray, getQueryValueAsString } from 'utils/query';
import { searchInArray } from 'utils/search';

export interface GroupedItem {
  brand_en: string;
  brand_fa: string;
  brand_logo: string;
  items: CarPriceCars;
}

export interface CarPriceMetaData {
  title: string;
  description: string;
  h1: string;
}

/**
 * filters the data based on search query if the query contains the
 *  search value
 */
export const filterBySearch = (data: CarPriceCars, query: ParsedUrlQuery) => {
  const querySearch = getQueryValueAsString(query, 'search') || '';
  if (querySearch) {
    return searchInArray(data, querySearch, (item) => [
      item.name_en,
      item.name_fa,
    ]);
  }
  return data;
};

/**
 * gets the query key and filters the data base on that key in query
 */
export const filterByStringVariable = (
  data: CarPriceCars,
  query: ParsedUrlQuery,
  queryKey: 'brand_en' | 'model_en' | 'tip_en',
) => {
  const queryValue = getQueryValueAsArray(query, queryKey);
  if (queryValue) {
    return data.filter((dataItem) => {
      return queryValue.includes(dataItem[queryKey].replaceAll(' ', '-'));
    });
  } else {
    return data;
  }
};

/**
 * filters the data based on the max_price value in query
 */
export const filterByMaxPrice = (data: CarPriceCars, query: ParsedUrlQuery) => {
  const queryMaxPrice = getQueryValueAsString(query, 'max_price');
  if (queryMaxPrice) {
    if (/^\d+$/.test(queryMaxPrice)) {
      const maxPrice = Number(queryMaxPrice);
      return data.filter((item) => item.price <= maxPrice);
    }
  }
  return data;
};

/**
 * filters the data based on the min_price value in query
 */
export const filterByMinPrice = (data: CarPriceCars, query: ParsedUrlQuery) => {
  const queryMinPrice = getQueryValueAsString(query, 'min_price');
  if (queryMinPrice) {
    if (/^\d+$/.test(queryMinPrice)) {
      const minPrice = Number(queryMinPrice);
      return data.filter((item) => item.price >= minPrice);
    }
  }
  return data;
};

/**
 * gets a date and returns the relative human readable text to current time
 */
export const getLastUpdateText = (lastUpdate: string) => {
  const current = new Date().getTime();
  const previous = new Date(lastUpdate).getTime();

  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    // return Math.round(elapsed / 1000) + ' ثانیه پیش';
    return 'امروز';
  } else if (elapsed < msPerHour) {
    // return Math.round(elapsed / msPerMinute) + ' دقیقه پیش';
    return 'امروز';
  } else if (elapsed < msPerDay) {
    // return Math.round(elapsed / msPerHour) + ' ساعت پیش';
    return 'امروز';
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + ' روز پیش';
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + ' ماه پیش';
  } else {
    return Math.round(elapsed / msPerYear) + ' سال پیش';
  }
};

/**
 * makes the schema for car-price pages based on filtered datas on each page
 */
export const makeCarPriceSchema = (
  data: CarPriceCars,
  pageTitle: string,
  pageDescription: string,
  latestUpdate: string,
) => {
  const itemsSchema = data.map((dataItem) => {
    return {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@type': 'Product',
        image: dataItem.logo_url,
        name: dataItem.name_en,
        alternateName: `${dataItem.name_en}`,
        brand: {
          '@type': 'Brand',
          name: dataItem.brand_en,
          alternateName: `${dataItem.brand_en}`,
        },
        dateModified: dataItem.last_update,
        productionDate: String(dataItem.production_year),
        mileageFromOdometer: {
          '@type': 'QuantitativeValue',
          value: String(dataItem.mileage),
          unitCode: 'KMT',
        },
        offers: {
          '@type': 'Offer',
          price: String(dataItem.price * 10),
          priceCurrency: 'IRR',
        },
        changeRate: {
          '@type': 'QuantitativeValue',
          value: String(dataItem.change_rate),
          unitCode: 'PER',
        },
      },
    };
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: pageTitle,
    description: pageDescription,
    mainEntity: {
      '@type': 'AggregateOffer',
      priceCurrency: 'IRR',
      offerCount: String(itemsSchema.length),
      validFrom: latestUpdate,
      itemListElement: itemsSchema,
    },
  };
};

interface CachedData {
  created_at: number;
  data: Omit<
    CarPricePropTypes,
    | 'metaData'
    | 'followPage'
    | 'currentDate'
    | 'pageType'
    | 'timeInServer'
    | 'createdAt'
    | 'lastCatchTime'
  >;
}

// stores the data that will be caching.
// don't change this value except in "getCarPriceData" function.
let cachedData: CachedData | null = null;

const cacheTime = 1000 * 60 * 30; // 30 minute

/**
 * caches the data received from getCarPriceCars and all needed headers.
 *  returns the same data for one hour.
 * otherwaze it gets the data from the apis.
 */
export const getCarPriceData = async () => {
  const currentTime = new Date().getTime();
  if (
    cachedData !== null &&
    typeof cachedData.created_at === 'number' &&
    currentTime - cachedData.created_at < cacheTime &&
    cachedData.data.data.length
  ) {
    return {
      data: cachedData.data.data,
      firstBanner: cachedData.data.firstBanner,
      secondBanner: cachedData.data.secondBanner,
      createdAt: cachedData.created_at,
    };
  } else {
    const dataPromise = getCarPriceCars();
    const firstBannerPromise = getHeader('car-price-page-row-banner-1');
    const secondBannerPromise = getHeader('car-price-page-row-banner-2');

    const [data, firstBanner, secondBanner] = await Promise.allSettled([
      dataPromise,
      firstBannerPromise,
      secondBannerPromise,
    ]).then((result) => {
      return [
        result[0].status === 'fulfilled' ? result[0].value : [],
        result[1].status === 'fulfilled' ? result[1].value : null,
        result[2].status === 'fulfilled' ? result[2].value : null,
      ];
    });

    cachedData = {
      created_at: new Date().getTime(),
      data: {
        data: (data as CarPriceCar[]).length
          ? (data as CarPriceCar[])
          : cachedData && cachedData.data.data.length
          ? cachedData.data.data
          : [],
        firstBanner: firstBanner as HeaderContents | null,
        secondBanner: secondBanner as HeaderContents | null,
      },
    };

    return {
      data,
      firstBanner,
      secondBanner,
      createdAt: cachedData.created_at || null,
    };
  }
};

/**
 * @returns a string from the list of all brand items with
 *  /car-price/{brand} pathnames in sitemap style
 */
export const makeBrandSitemapList = (
  data: CarPriceCars,
  currentDate: string,
) => {
  const groupedResultObject = data.reduce<{
    [brandKey: string]: { brand_en: string };
  }>((result, filterItem) => {
    if (!(filterItem.brand_en in result)) {
      result[filterItem.brand_en] = {
        brand_en: filterItem.brand_en,
      };
    }

    return result;
  }, {});

  return Object.keys(groupedResultObject)
    .map((groupedResultItemKey) => {
      return groupedResultObject[groupedResultItemKey];
    })
    .map((groupItem) => {
      return `<url>
          <loc>${ENV.LANDING_URL}/car-price/${groupItem.brand_en.replaceAll(
        ' ',
        '-',
      )}</loc>
          <lastmod>${currentDate}</lastmod>
        </url>`;
    })
    .join('\n');
};

/**
 * @returns a string from the list of all brand and model items with
 *  /car-price/{brand}/{model} pathnames in sitemap style
 */
export const makeBrandModelSitemapList = (
  data: CarPriceCars,
  currentDate: string,
) => {
  const groupedResultObject = data.reduce<{
    [brandKey: string]: { brand_en: string; model_en: string };
  }>((result, filterItem) => {
    if (
      !(`${filterItem.brand_en}--${filterItem.model_en}` in result) &&
      filterItem.model_en
    ) {
      result[`${filterItem.brand_en}--${filterItem.model_en}`] = {
        brand_en: filterItem.brand_en,
        model_en: filterItem.model_en,
      };
    }

    return result;
  }, {});

  return Object.keys(groupedResultObject)
    .map((groupedResultItemKey) => {
      return groupedResultObject[groupedResultItemKey];
    })
    .map((groupItem) => {
      return `<url>
          <loc>${ENV.LANDING_URL}/car-price/${groupItem.brand_en.replaceAll(
        ' ',
        '-',
      )}/${groupItem.model_en.replaceAll(' ', '-')}</loc>
          <lastmod>${currentDate}</lastmod>
        </url>`;
    })
    .join('\n');
};

/**
 * @returns a string from the list of all brand, model and tip items with
 *  /car-price/{brand}/{model}/{tip} pathnames in sitemap style
 */
export const makeBrandModelTipSitemapList = (
  data: CarPriceCars,
  currentDate: string,
) => {
  const groupedResultObject = data.reduce<{
    [brandKey: string]: { brand_en: string; model_en: string; tip_en: string };
  }>((result, filterItem) => {
    if (
      !(
        `${filterItem.brand_en}--${filterItem.model_en}--${filterItem.tip_en}` in
        result
      ) &&
      filterItem.model_en &&
      filterItem.tip_en
    ) {
      result[
        `${filterItem.brand_en}--${filterItem.model_en}--${filterItem.tip_en}`
      ] = {
        brand_en: filterItem.brand_en,
        model_en: filterItem.model_en,
        tip_en: filterItem.tip_en,
      };
    }

    return result;
  }, {});

  return Object.keys(groupedResultObject)
    .map((groupedResultItemKey) => {
      return groupedResultObject[groupedResultItemKey];
    })
    .map((groupItem) => {
      return `<url>
          <loc>${ENV.LANDING_URL}/car-price/${groupItem.brand_en.replaceAll(
        ' ',
        '-',
      )}/${groupItem.model_en.replaceAll(
        ' ',
        '-',
      )}/${groupItem.tip_en.replaceAll(' ', '-')}</loc>
          <lastmod>${currentDate}</lastmod>
        </url>`;
    })
    .join('\n');
};

/**
 * @returns a string from the list of all min and max prices with
 *  /car-price/price/{minPrice}/{maxPrice} pathnames based on the
 *  seoPriceList in sitemap style
 */
export const makeMinMaxPriceSitemap = (currentDate: string) => {
  const result = [];

  for (let i = 0; i < seoPriceList.length; i++) {
    for (let j = i + 1; j < seoPriceList.length; j++) {
      result.push(`<url>
          <loc>${ENV.LANDING_URL}/car-price/price/${seoPriceList[i].price}/${seoPriceList[j].price}</loc>
          <lastmod>${currentDate}</lastmod>
        </url>`);
    }
  }

  return result.join('\n');
};

/**
 * @returns a string from the list of all min prices with
 *  /car-price/min-price/{price} pathnames based on the seoPriceList
 *  in sitemap style
 */
export const makeMinPriceSitemap = (currentDate: string) => {
  const result = [];

  for (let i = 0; i < seoPriceList.length; i++) {
    result.push(`<url>
          <loc>${ENV.LANDING_URL}/car-price/min-price/${seoPriceList[i].price}</loc>
          <lastmod>${currentDate}</lastmod>
        </url>`);
  }

  return result.join('\n');
};

/**
 * @returns a string from the list of all max prices with
 *  /car-price/max-price/{price} pathnames based on the seoPriceList
 *  in sitemap style
 */
export const makeMaxPriceSitemap = (currentDate: string) => {
  const result = [];

  for (let i = 0; i < seoPriceList.length; i++) {
    result.push(`<url>
          <loc>${ENV.LANDING_URL}/car-price/max-price/${seoPriceList[i].price}</loc>
          <lastmod>${currentDate}</lastmod>
        </url>`);
  }

  return result.join('\n');
};

export const faqList = [
  {
    question: 'قیمت‌ خودروهای صفر بر چه اساسی تعیین می‌شود؟',
    answer:
      'کارشناسان کارنامه به بازار خرید و فروش خودرو کاملا اشراف داشته و بر اساس اطلاعات آخرین معاملات و داد‌و‌ستدهای روز و همچنین قیمت اعلامی خودروی صفر از سوی کارخانه، قیمت‌گذاری را انجام می‌دهند.',
  },
  {
    question:
      'قیمت خودروهای صفری که کارنامه ارائه می‌دهد چه قدر دقیق و به روز است؟',
    answer:
      'مجموعه کارنامه همکار تجاری سایت دیوار است و به تمامی دیتاهای این سایت در زمینه خرید و فروش خودرو دسترسی دارد. از طرفی هم چون کارنامه در بیشتر شهرها فعالیت می‌کند، اطلاع از آمارهای دقیق و به‌روز مراکز تعویض پلاک در سراسر کشور دارد. در نتیجه قیمت‌‌های ارائه شده توسط تیم کارنامه کاملا به روز و مبتنی بر داده‌های دقیق و صحیح است.',
  },
  {
    question: 'چطور قیمت خودروی دلخواهم را پیدا کنم؟',
    answer:
      'برای بدست آوردن قیمت خودروی موردنظرتان فقط کافی است که در کادر مربوط به جستجو مدل آن را وارد کنید تا کارنامه لیستی از خودروهای مشابه به همراه قیمت‌شان را برای شما نمایش دهد.',
  },
  {
    question: 'آیا برای قیمت‌گذاری خودروی کارکرده هم مرجعی وجود دارد؟',
    answer:
      'بله، کارنامه هم به صورت آنلاین و هم به صورت حضوری خودروی مدنظر شما را قیمت‌گذاری می‌کند. اگر بخواهید محاسبه قیمت را به شکل آنلاین ببینید کافیست به صفحه قیمت خودرو کارکرده بروید و با مشخص کردن امکانات مدنظرتان، قیمت تخمینی را به‌دست آورید. چنانچه بخواهید به شکل دقیق‌تری قیمت خودروی دلخواهتان را محاسبه کنید هم، با ثبت سفارش در این صفحه (کارشناسی خودرو) می‌توانید دقیق و بدون خطا به قیمت واقعی دست‌ پیدا کنید.',
  },
];

export const explainData = [
  {
    title: 'قیمت روز خودرو',
    description:
      'کارشناسان کارنامه با اشراف بر بازار خریدوفروش خودرو، دسترسی به اطلاعات آخرین معاملات روز و همچنین دراختیار داشتن داده‌های سایت دیوار و مراکز تعویض پلاک، هر خودرویی را که بخواهید قیمت‌گذاری می‌کنند. قیمت‌گذاری مجموعه کارنامه دقیق، باکمترین خطا و به‌روز است.',
  },
  {
    title: 'آخرین تغییرات خودروی صفر در بازار خودرو',
    description:
      'کارشناسان کارنامه با اشراف بر بازار خریدوفروش خودرو، دسترسی به اطلاعات آخرین معاملات روز و همچنین دراختیار داشتن داده‌های سایت دیوار و مراکز تعویض پلاک، هر خودرویی را که بخواهید قیمت‌گذاری می‌کنند. قیمت‌گذاری مجموعه کارنامه دقیق، باکمترین خطا و به‌روز است.',
  },
];

export const deprecatedCarPricePathList: { [key: string]: string } = {
  '/car-price/quick-automatic-full-plus': '/car-price/quick',
  '/car-price/mg-360-automatic-turbo': '/car-price',
  '/car-price/arisan-arisan-bi-fuel': '/car-price',
  '/car-price/mvm-x22-automatic-luxury': '/car-price/mvm',
  '/car-price/dena-plus-1700cc-turbo': '/car-price/dena',
  '/car-price/mvm-x22-automatic-sport-excellent': '/car-price/mvm',
  '/car-price/mvm-x22-manual-sport-excellent': '/car-price/mvm',
  '/car-price/mercedes-benz-e-class-e200': '/car-price/mercedes-benz',
  '/car-price/mvm-315-hatchback-sport-excellent': '/car-price/mvm',
  '/car-price/alfa-romeo-giulietta': '/car-price',
  '/car-price/mvm-x33s-sport': '/car-price/mvm',
  '/car-price/toyota-fj-cruiser': '/car-price/toyota',
  '/car-price/pride-131-se': '/car-price/pride',
  '/car-price/alfa-romeo-4c': '/car-price',
  '/car-price/amico-asna-bi-fuel': '/car-price/amico',
  '/car-price/peugeot-206-sd-v8': '/car-price/peugeot',
  '/car-price/pride-pickup-151-se': '/car-price/pride',
  '/car-price/brilliance-h330-automatic-1650cc': '/car-price/brilliance',
  '/car-price/brilliance-c3-automatic-1650cc': '/car-price/brilliance',
  '/car-price/ario-automatic-1600cc': '/car-price',
  '/car-price/peugeot-207i-automatic': '/car-price/peugeot',
  '/car-price/peugeot-206-2': '/car-price/peugeot',
  '/car-price/hyundai-sonata-yf': '/car-price/hyundai',
  '/car-price/quick-automatic-full': '/car-price/quick',
  '/car-price/changan-cs35-ir': '/car-price/changan',
  '/car-price/brilliance-h230-manual': '/car-price/brilliance',
  '/car-price/hyundai-sonata-lf': '/car-price/hyundai',
  '/car-price/brilliance-h220-manual': '/car-price/brilliance',
  '/car-price/pride-111-se': '/car-price/pride',
  '/car-price/pride-station': '/car-price/pride',
  '/car-price/pride-hatchback': '/car-price/pride',
  '/car-price/changan-cs35': '/car-price/changan',
  '/car-price/haima-s5': '/car-price/haima',
  '/car-price/mg-3': '/car-price',
  '/car-price/lifan-820': '/car-price/lifan',
  '/car-price/peugeot-404': '/car-price/peugeot',
  '/car-price/quick-manual': '/car-price/quick',
  '/car-price/jaguar-xj': '/car-price',
  '/car-price/audi-q5': '/car-price',
  '/car-price/porsche-macan': '/car-price',
  '/car-price/peugeot-205': '/car-price/peugeot',
  '/car-price/peugeot-2008': '/car-price/peugeot',
  '/car-price/paykan-petrol': '/car-price/paykan',
  '/car-price/runna-el': '/car-price/runna',
  '/car-price/saina-automatic': '/car-price/saina',
  '/car-price/samand-sarir': '/car-price/samand',
  '/car-price/brilliance-v5': '/car-price/brilliance',
  '/car-price/runna-lx': '/car-price/runna',
  '/car-price/mvm-530': '/car-price/mvm',
  '/car-price/pride-automatic': '/car-price/pride',
  '/car-price/bmw-i8': '/car-price/bmw',
  '/car-price/dongfeng-h30': '/car-price',
  '/car-price/jac-j4': '/car-price/jac',
  '/car-price/toyota-rav4': '/car-price/toyota',
  '/car-price/besturn-b30': '/car-price/besturn',
  '/car-price/smart-forfour': '/car-price',
};

const getAverageOfCarPrices = (data: CarPriceCars) => {
  const sum = data.reduce<number>((result, dataItem) => {
    result += dataItem.change_rate;

    return result;
  }, 0);

  let average = sum / data.length;

  return Math.trunc(average * 1000) / 1000;
};

export const firstExplainSectionConfigs = [
  {
    route: '/car-price/[brand_en]',
    getDescription: (data: CarPriceCars) => {
      const average = getAverageOfCarPrices(data);

      return {
        title: `قیمت روز خودرو ${data[0].brand_fa}`,
        description: `میانگین ${data.length > 1 ? 'گروهی ' : ' '}قیمت ${
          data[0].brand_fa
        }، براساس معامله‌های انجام‌شده در بازار خودرو، قیمت نمایشگاه‌ها و آگهی‌های ثبت شده، نسبت به روز معاملاتی قبل ${
          average === 0
            ? 'تغییر نداشته است.'
            : `${Math.abs(average)} درصد  ${
                average >= 0 ? 'افزایش' : 'کاهش'
              } داشته است.`
        }`,
      };
    },
  },
  {
    route: '/car-price/[brand_en]/[model_en]',
    getDescription: (data: CarPriceCars) => {
      const average = getAverageOfCarPrices(data);

      return {
        title: `قیمت روز خودرو ${data[0].brand_fa} ${data[0].model_fa}`,
        description: `میانگین ${data.length > 1 ? 'گروهی ' : ' '}قیمت ${
          data[0].brand_fa
        }، براساس معامله‌های انجام‌شده در بازار خودرو، قیمت نمایشگاه‌ها و آگهی‌های ثبت شده، نسبت به روز معاملاتی قبل ${
          average === 0
            ? 'تغییر نداشته است.'
            : `${Math.abs(average)} درصد  ${
                average >= 0 ? 'افزایش' : 'کاهش'
              } داشته است.`
        }`,
      };
    },
  },
  {
    route: '/car-price/[brand_en]/[model_en]/[tip_en]',
    getDescription: (data: CarPriceCars) => {
      const average = getAverageOfCarPrices(data);

      return {
        title: `قیمت روز خودرو ${data[0].brand_fa} ${data[0].model_fa} ${data[0].tip_fa}`,
        description: `میانگین ${data.length > 1 ? 'گروهی ' : ' '}قیمت ${
          data[0].brand_fa
        }، براساس معامله‌های انجام‌شده در بازار خودرو، قیمت نمایشگاه‌ها و آگهی‌های ثبت شده، نسبت به روز معاملاتی قبل ${
          average === 0
            ? 'تغییر نداشته است.'
            : `${Math.abs(average)} درصد  ${
                average >= 0 ? 'افزایش' : 'کاهش'
              } داشته است.`
        }`,
      };
    },
  },
];
