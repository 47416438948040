import { Button, Modal, Stack, Typography } from '@mui/material';
import Footer from 'containers/car-services-order/components/Footer';
import SelectServicesItem from 'containers/car-services-order/components/SelectServicesItem';
import Layout from 'containers/car-services-order/components/layout';
import {
  CAR_SERVICES_LOCALSTORAGE_KEY,
  basicCheckupId,
  initialData,
  useCarServices,
} from 'containers/car-services-order/configs';
import {
  useGetQuotation,
  useUpdateQuotation,
} from 'containers/car-services-order/requests';
import useFunnelContext from 'hooks/useFunnelContext';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  useFetchServiceTypes,
  useGetOrderDetails,
  useUpdateOrderMutation,
} from 'services/rest/car-services';
import { OrderStateType } from 'services/rest/car-services/car-services';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const SelectServices: FC<PropTypes> = (props) => {
  const updateQuotation = useUpdateQuotation();
  const { replace } = useRouter();
  const funnelData = useFunnelContext();
  const [isGuideOpen, setGuideOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const {
    data: carServicesData,
    setData: setCarData,
    clearData,
  } = useCarServices();
  const { data: quotationData } = useGetQuotation(
    {
      uuid: carServicesData.quotationUUID ?? '',
    },
    { enabled: Boolean(carServicesData.quotationUUID) },
  );
  const { data: orderData } = useGetOrderDetails(
    String(carServicesData.orderId),
    { enabled: Boolean(carServicesData.orderId) },
  );
  const { mutate: updateOrder } = useUpdateOrderMutation();
  const { currentStep, totalStep } = props;

  const navigateToLeadSubmitted = () => {
    const fullName = 'مشتری';
    replace(
      `/services/car-services/lead-submission/?status=not-available&name=${fullName}&id=${carServicesData.orderId}`,
    );
    clearData(undefined);
    localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
  };

  const navigateToNotSupported = () => {
    const fullName = 'مشتری';
    replace(
      `/services/car-services/lead-submission/?status=failed&message=متاسفانه فعلا امکان سرویس‌دهی به خودروی شما را نداریم.&name=${fullName}`,
    );
    clearData(undefined);
    localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
  };

  const makeQuoteAConsultantLead = () => {
    setCarData((prev) => ({ ...prev, leadType: 'CONSULTANT' }));
    funnelData?.changeStep('user-phone');
    // const fullName = 'مشتری عزیز';
    // replace(
    //   `/services/car-services/submission/?status=consultant-lead&name=${fullName}&id=${carServicesData.orderId}`,
    // );
    // clearData(undefined);
    // localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
  };

  // const setOrderStateToConsultant = () => {
  //   if(carServicesData.orderId){
  //     updateOrder({id: carServicesData.orderId, service_types: })
  //     return
  //   }
  //   updateQuotation.mutate(
  //     {
  //       uuid: carServicesData.quotationUUID ?? '',
  //       // source: 'online_web_consultancy_lead',
  //     },
  //     {
  //       onSuccess: () => {
  //         navigateToConsultantLead();
  //       },
  //     },
  //   );
  // };

  const {
    data: serviceTypes,
    isRefetching,
    isLoading,
  } = useFetchServiceTypes(
    {
      brand_model:
        orderData?.car_profile.brand_model_en ??
        quotationData?.brand_model_en ??
        '',
      manufactured_year:
        orderData?.car_profile.manufactured_year ??
        quotationData?.manufactured_year ??
        '',
      check_service_price: true,
    },
    {
      refetchOnWindowFocus: false,
      enabled: orderData?.car_profile.brand_model_en
        ? !!orderData?.car_profile.brand_model_en
        : !!quotationData?.brand_model_en,
      onSuccess: (response) => {
        const requiredService = response.results.find(
          (item) => item.slug === 'تعویض-روغن-و-فیلتر-روغن',
        );
        if (response.results.length === 0) {
          navigateToNotSupported();
        }
        if (!requiredService) {
          navigateToLeadSubmitted();
        }
      },
    },
  );

  const serviceTypeList = useMemo(() => {
    return serviceTypes?.results.sort((a, b) => {
      if (a.is_required && !b.is_required) {
        return -1;
      } else if (!a.is_required && b.is_required) {
        return 1;
      }

      if ([13, basicCheckupId].includes(a.id)) {
        if (a.id === 13 && b.id === basicCheckupId) return 1;
        else return -1;
      }
      if (a.has_unavailable_part && !b.has_unavailable_part) return 1;
      else if (!a.has_unavailable_part && b.has_unavailable_part) return -1;
      return 0;
    });
  }, [serviceTypes]);

  useEffect(() => {
    const requiredList =
      serviceTypes?.results.filter((i) => i.is_required).map((i) => i.id) ?? [];
    if (serviceTypes?.results) {
      setSelectedServices(
        orderData?.services && (orderData?.services.length ?? 0) > 0
          ? orderData.services.map((i) => i.id)
          : quotationData?.service_types &&
            (quotationData?.service_types.length ?? 0) > 0
          ? quotationData.service_types
          : requiredList,
      );
    }
  }, [serviceTypes]);

  useEffect(() => {
    if (funnelData) {
      funnelData.setLoading((prevState) => {
        if ((isLoading || isRefetching) && !prevState) {
          return true;
        } else if (!isLoading && !isRefetching && prevState) {
          return false;
        }
        return prevState;
      });
    }
  }, [isLoading, isRefetching, funnelData]);

  useEffect(() => {
    if (
      orderData?.state &&
      (['cancelled', 'done'] as OrderStateType[]).includes(orderData.state)
    ) {
      setCarData(initialData);
      if (typeof window !== 'undefined')
        location.replace('/services/car-services/car-info');
    }
  }, [orderData?.state, setCarData]);

  const itemClickHandler = (id: number) => {
    setSelectedServices((prevState) => {
      const newState: number[] = [...prevState];
      if (id === 10) return prevState;
      // user only can choose on of checkup not both
      if (id === 13) {
        const indexOfBasicCheckup = newState.indexOf(basicCheckupId);
        if (indexOfBasicCheckup !== -1) newState.splice(indexOfBasicCheckup, 1);
      } else if (id === basicCheckupId) {
        const indexOfFullCheckup = newState.indexOf(13);
        if (indexOfFullCheckup !== -1) newState.splice(indexOfFullCheckup, 1);
      }

      const findedIndex = newState.indexOf(id);
      if (findedIndex !== -1) {
        newState.splice(findedIndex, 1);
      } else {
        newState.push(id);
      }

      return newState;
    });
  };

  const submitHandler = () => {
    if (!carServicesData.quotationUUID) {
      return;
    }
    if (carServicesData.orderId) {
      updateOrder(
        {
          id: String(carServicesData.orderId),
          service_types: selectedServices,
        },
        {
          onSuccess: () => {
            funnelData?.changeStep('suggested-package');
          },
        },
      );
      return;
    }
    updateQuotation.mutate(
      {
        uuid: carServicesData.quotationUUID,
        service_types: selectedServices,
      },
      {
        onSuccess: () => {
          funnelData?.changeStep('suggested-package');
          // navigateToLeadSubmitted();
        },
        onError: () => {
          // if (error instanceof AxiosError) {
          //   if (error.response && error.response.status === 400) {
          //     if (error.response.data.non_field_errors) {
          // updateQuotation.mutate(
          //   {
          //     id: carServicesData.orderId ?? '',
          //     source: 'online_web_lead',
          //   },
          //   {
          //     onSuccess: () => {
          //       navigateToLeadSubmitted();
          //     },
          //   },
          // );
          // const errorList: string[] =
          //   error.response.data.non_field_errors;
          // const errorMessage = `در حال حاضر قادر به ارایه خدمت/خدمات ${errorList.join(
          //   ', ',
          // )} برای خودرو شما نیستیم.`;
          // displayError(errorMessage);
          // funnelData?.changeStep('select-services');
          //     }
          //   }
          // }
        },
      },
    );

    // funnelData?.nextStep();
  };

  return (
    <Layout
      header={{
        title: 'انتخاب سرویس',
        totalPage: totalStep,
        currentPage: currentStep,
        // goPrevStep: false,
        hasInfoIcon: true,
        onInfoClick: () => {
          setGuideOpen(true);
        },
      }}
      extraScroll={false}
      hasFlexGrow={true}
    >
      <Typography
        fontSize="1rem"
        fontWeight={600}
        color={(th) => th.palette.common.black}
      >
        سرویس‌های مد نظر خود را انتخاب کنید.
      </Typography>
      <Typography
        fontSize="0.875rem"
        lineHeight="1.75rem"
        fontWeight={600}
        color={(th) => th.palette.iconDefault.main}
        sx={{ marginTop: '0.5rem' }}
      >
        ماشین شما:{' '}
        <Typography
          component="span"
          sx={{
            color: (theme) => theme.palette.common.black,
            fontSize: 'inherit',
            fontWeight: 'inherit',
          }}
        >
          {orderData?.car_profile.brand_model_fa ??
            quotationData?.brand_model_fa}
        </Typography>
      </Typography>

      {!!serviceTypes && Array.isArray(serviceTypes.results) && (
        <Stack
          width="100%"
          borderRadius="16px"
          overflow="hidden"
          border={(th) => `1px solid ${th.palette.surface.paper}`}
          mt={2}
          sx={{ marginBottom: '8rem' }}
        >
          {serviceTypeList?.map((item) => {
            return (
              <SelectServicesItem
                key={item.id}
                id={item.id}
                is_required={item.is_required}
                name={item.name}
                onClick={(id) => itemClickHandler(id)}
                checked={item.is_required || selectedServices.includes(item.id)}
                // startPrice={200000}
                description={item.description}
                hasUnavailableParts={item.has_unavailable_part}
                minimumPartPrices={item.minimum_part_prices}
                minimumServicePrice={item.minimum_service_price}
                isDisabled={item.has_unavailable_part}
              />
            );
          })}
        </Stack>
      )}

      {/* <Stack
        sx={{
          width: '100%',
          marginTop: { xs: '1.5rem', md: '1.5rem' },
          marginBottom: { xs: '3rem', md: '3rem' },
        }}
      ></Stack> */}
      <Footer
        isLoading={isLoading || isRefetching || updateQuotation.isLoading}
        isDisabled={!selectedServices || selectedServices.length === 0}
        id="car-services-select-services-btn"
        onClick={submitHandler}
      >
        <Typography
          onClick={makeQuoteAConsultantLead}
          sx={{
            fontSize: '0.875rem',
            fontWeight: 600,
            color: (theme) => theme.palette.secondary.main,
            width: '100%',
            textAlign: 'center',
            margin: '0.5rem 0 1.5rem',
            cursor: 'pointer',
          }}
        >
          نمی‌دانم به چه سرویسی احتیاج دارم
        </Typography>
      </Footer>
      <Modal
        open={isGuideOpen}
        onClose={() => {
          setGuideOpen(false);
        }}
      >
        <Stack
          width="calc(100% - 32px)"
          sx={{
            backgroundColor: (th) => th.palette.surface.default,
            maxWidth: '400px',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          borderRadius="1.75rem"
          p="1.5rem"
        >
          <Typography
            fontSize="1.5rem"
            fontWeight={400}
            mb="1rem"
            color={(th) => th.palette.common.black}
          >
            راهنمای انتخاب سرویس
          </Typography>
          <Typography
            fontSize="1rem"
            fontWeight={400}
            color={(th) => th.palette.common.black}
            mb="0.5rem"
          >
            راهنمای سرویس قطعات خودرو براساس کارکرد (کیلومتر):
          </Typography>

          {[
            {
              title: 'تعویض روغن و فیلتر روغن:',
              subTitle: 'هر 6 ماه یا 5 هزار کارکرد.',
            },
            { title: 'تعویض فیلتر هوا:', subTitle: 'هر 5 هزار کارکرد.' },
            {
              title: 'تعویض فیلتر کابین:',
              subTitle: 'به فاصله هر دو تعویض روغن.',
            },
            { title: 'بازدید چرخ‌های جلو:', subTitle: 'هر 10 هزار کارکرد.' },
            {
              title: 'تعویض لنت جلو:',
              subTitle: 'هر 20 هزار کارکرد و مدل رانندگی.',
            },
            {
              title: 'تعویض لنت عقب:',
              subTitle: 'هر 80 هزار کارکرد و مدل رانندگی.',
            },
            {
              title: 'تعویض فیلتر بنزین:',
              subTitle: 'به فاصله هر دو تعویض روغن.',
            },
          ].map((item, index) => {
            return (
              <Stack
                key={index}
                direction="row"
                justifyContent="flex-start"
                alignItems="start"
              >
                <Stack
                  sx={{
                    width: '5px',
                    height: '5px',
                    borderRadius: '50%',
                    marginTop: '11px',
                    backgroundColor: (th) => th.palette.iconDefault.main,
                    mr: 2,
                  }}
                />
                <Typography
                  fontSize="1rem"
                  fontWeight={400}
                  sx={{ width: 'fit-content', marginBottom: '0.25rem' }}
                  color={(th) => th.palette.iconDefault.main}
                >
                  {item.title}{' '}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: 'inherit',
                      fontWeight: 'inherit',
                      color: (theme) => theme.palette.common.black,
                    }}
                  >
                    {item.subTitle}
                  </Typography>
                </Typography>
              </Stack>
            );
          })}
          <Button
            variant="text"
            onClick={() => setGuideOpen(false)}
            color="info"
            sx={{ alignSelf: 'flex-end', marginTop: 2 }}
          >
            متوجه شدم
          </Button>
        </Stack>
      </Modal>
    </Layout>
  );
};

export default SelectServices;
