import { CacheProvider, type EmotionCache } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import type { FC, PropsWithChildren } from 'react';
import theme from 'theme';
import createRtlEmotionCache from 'utils/createRtlEmotionCache';

interface Props {
  rtlEmotionCache?: EmotionCache;
}

const clientRtlEmotionCache = createRtlEmotionCache();

const AppMuiThemeProvider: FC<PropsWithChildren<Props>> = ({
  children,
  rtlEmotionCache = clientRtlEmotionCache,
}) => {
  return (
    <CacheProvider value={rtlEmotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export default AppMuiThemeProvider;
