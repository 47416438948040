import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PlaceIcon from '@mui/icons-material/Place';

import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import Toast from 'components/toast';
import Discount from 'containers/car-services-order/components/discount';
import Layout from 'containers/car-services-order/components/layout';
import {
  CAR_SERVICES_LOCALSTORAGE_KEY,
  confirmTextStyles,
  initialData,
  useCarServices,
} from 'containers/car-services-order/configs';
import {
  useDeleteDiscountCode,
  useSubmitDiscountCode,
} from 'containers/car-services-order/requests';
import PartPriceItemsBS from 'containers/car-services-order/steps/FinalConfirmation/PartPriceItemsBS';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Car from '../../../../../public/assets/icons/car-services/verified-car.svg';
import useFunnelContext from '../../../../hooks/useFunnelContext';
import {
  useGetOrderDetails,
  useSubmitOrderMutation,
} from '../../../../services/rest/car-services';
import {
  OrderStateType,
  SubmitOrderDTO,
} from '../../../../services/rest/car-services/car-services';

interface PropTypes {
  currentStep: number;
  totalStep: number;
  justTest?: boolean;
}

type IconTitleProp = {
  title: string | string[];
  icon: ReactNode;
  isFirst?: boolean;
};

const IconTitleRow = ({ title, icon, isFirst }: IconTitleProp) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: '1.25rem',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {icon}
      <Typography
        sx={{
          padding: isFirst ? '0 0 1rem' : '1rem 0',
          flex: 1,
          borderTop: isFirst
            ? 'none'
            : `1px solid ${theme.palette.surface.paper}`,
        }}
      >
        {typeof title === 'object'
          ? title.map((item, index) => (
              <>
                {item}
                {index !== title.length - 1 && <br />}
              </>
            ))
          : title}
      </Typography>
    </Stack>
  );
};

type RowProps = {
  title: string;
  value: string;
  isDiscount?: boolean;
  isImportant?: boolean;
  isBlue?: boolean;
};

export const RowItem = ({
  title,
  value,
  isDiscount,
  isImportant,
  isBlue,
}: RowProps) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '0.75rem',
      }}
    >
      <Typography
        sx={
          isBlue
            ? { ...confirmTextStyles.bodyLarge, color: '#006ED8' }
            : isImportant
            ? { ...confirmTextStyles.mediumTitle }
            : {
                ...confirmTextStyles.bodyLarge,
                color: theme.palette.iconDefault.main,
              }
        }
      >
        {title}
      </Typography>
      <Typography
        sx={{
          ...confirmTextStyles.bodyLarge,
          minWidth: '30%',
          textAlign: 'end',
          color: isBlue
            ? '#006ED8'
            : isDiscount
            ? theme.palette.iconNotif.main
            : theme.palette.common.black,

          fontWeight: isImportant ? 'bold' : 'normal',
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

const Separator = () => (
  <Stack
    sx={{
      width: '100%',
      height: '0.5rem',
      backgroundColor: (theme) => theme.palette.background.default,
      marginBottom: '1rem',
    }}
  />
);

const Wrapper: React.FC<PropsWithChildren> = ({ children }) => (
  <Stack sx={{ padding: { xs: '0 24px', md: '0' }, width: '100%' }}>
    {children}
  </Stack>
);

const FinalConfirmation = ({ currentStep, totalStep }: PropTypes) => {
  // const { copy } = useCopyToClipboard();
  // const localDiscount = localStorage.getItem(SPECIFIC_DISCOUNT_CODE);
  // const [isDiscountInfoVisible, setDiscountInfoVisible] = useState(true);
  const theme = useTheme();
  const funnelData = useFunnelContext();
  const { replace, query } = useRouter();
  const [isCodeValid, setCodeValid] = useState(false);
  const [discountAmount, setDiscountAmount] = useState('');
  const queryClient = useQueryClient();

  const { mutate: submitDiscountCode, isLoading: isDiscountSubmitLoading } =
    useSubmitDiscountCode();
  const { mutate: deleteDiscountCode } = useDeleteDiscountCode();
  const {
    data: carServicesData,
    clearData,
    setData: setCarServicesData,
  } = useCarServices();
  const submitOrder = useSubmitOrderMutation();
  const [isBSOpen, setIsBSOpen] = useState<boolean>(false);

  const {
    data: order,
    isLoading,
    isRefetching,
  } = useGetOrderDetails(
    carServicesData.orderId ? String(carServicesData?.orderId) : '',
    {
      enabled: !!carServicesData.orderId,
    },
  );

  const discountSubmitHandler = useCallback(
    (code: string) => {
      if (!order?.id || isDiscountSubmitLoading) return;
      submitDiscountCode(
        { code, orderId: order.id },
        {
          onSuccess: (response) => {
            queryClient.invalidateQueries([`order-${order.id}-details`]);
            setCodeValid(true);
            setDiscountAmount(response.discount_amount);
          },
          onError: () => {
            Toast({ message: 'کد تخفیف وارد شده صحیح نیست.', type: 'error' });
          },
        },
      );
    },
    [order?.id, submitDiscountCode, isDiscountSubmitLoading, queryClient],
  );
  const discountDeleteHandler = useCallback(
    (code: string) => {
      if (!order?.id) return;
      setCodeValid(false);
      deleteDiscountCode(
        { code, orderId: order.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([`order-${order.id}-details`]);
          },
        },
      );
    },
    [order?.id, deleteDiscountCode, queryClient],
  );

  const totalDiscount = useMemo(
    () =>
      order?.discounts?.reduce(
        (acc, discount) => acc + parseInt(String(discount.amount)),
        0,
      ) ?? 0,
    [order?.discounts],
  );

  const totalDiscountCode = useMemo(
    () =>
      order?.discount_codes?.reduce(
        (acc, discount) => acc + parseInt(String(discount.amount)),
        0,
      ) ?? 0,
    [order?.discount_codes],
  );

  useEffect(() => {
    if (query.discount_code && !!order) {
      discountSubmitHandler(query.discount_code as string);
    }
  }, [order?.id]);

  useEffect(() => {
    if (
      order?.state &&
      (['cancelled', 'done'] as OrderStateType[]).includes(order.state)
    ) {
      setCarServicesData(initialData);
      if (typeof window !== 'undefined')
        location.replace('/services/car-services/car-info');
    }
  }, [order?.state, setCarServicesData]);

  useEffect(() => {
    if (funnelData) {
      funnelData.setLoading((prevState) => {
        if ((isLoading || isRefetching) && !prevState) {
          return true;
        } else if (!isLoading && !isRefetching && prevState) {
          return false;
        }
        return prevState;
      });
    }
  }, [isLoading, isRefetching, funnelData]);

  if (!order) {
    return <></>;
  }

  const submitNewOrder = () => {
    if (!carServicesData.orderId) return;
    submitOrder.mutate(String(carServicesData.orderId), {
      onSuccess: (response: AxiosResponse<SubmitOrderDTO>) => {
        clearData(undefined);
        localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
        replace(response.data.data.payment_url);
      },
    });
  };

  return (
    <>
      <Layout
        header={{
          title: 'تایید نهایی',
          totalPage: totalStep,
          currentPage: currentStep,
        }}
        noContentWrapperPadding={true}
      >
        {/* {localDiscount && isDiscountInfoVisible && !query.discount_code && (
          <>
            <Stack
              sx={{
                padding: '1rem 0.69rem 1rem 1.81rem',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                backgroundColor: theme.palette.info.surface,
              }}
            >
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: '1.75rem',
                }}
              >
                کد تخفیف اختصاصی شما:
                <Typography
                  component="span"
                  sx={{ fontSize: 'inherit', fontWeight: 600 }}
                >
                  {localDiscount}
                </Typography>
              </Typography>
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setDiscountInfoVisible(false);
                  copy(localDiscount);
                  Toast({
                    message: 'کد تخفیف با موفقیت کپی شد.',
                    type: 'success',
                  });
                }}
              >
                <ContentCopy
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    color: theme.palette.secondary.main,
                  }}
                />
                <Typography
                  sx={{
                    margin: '0 0 0 0.25rem',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: '1.75rem',
                    color: theme.palette.secondary.main,
                  }}
                >
                  کپی کردن
                </Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: '100%',
                height: '0.25rem',
                backgroundColor: theme.palette.background.default,
              }}
            />
          </>
        )} */}
        <Wrapper>
          <Typography
            sx={{
              ...confirmTextStyles.title,
              marginBottom: '1.31rem',
              marginTop: '1.5rem',
            }}
          >
            جزئیات درخواست
          </Typography>
          <IconTitleRow
            icon={<Image src={Car} alt="car-icon" />}
            isFirst={true}
            title={[
              'سرویس دوره‌ای خودرو در محل',
              order.car_profile.brand_model_fa,
            ]}
          />
          <IconTitleRow
            icon={<PlaceIcon sx={{ color: theme.palette.iconDefault.main }} />}
            title={order.address}
          />
        </Wrapper>
        <Separator />
        <Stack
          sx={{
            padding: { xs: '0 24px 16px', md: '0 0 16px' },
            width: '100%',
          }}
        >
          <Discount
            onDeleteDiscount={discountDeleteHandler}
            onSubmitDiscount={discountSubmitHandler}
            isValidCode={isCodeValid}
            discountAmount={discountAmount}
            defaultCode={order.discount_codes}
            defaultInputValue={query.discount_code as string}
          />
        </Stack>
        <Separator />
        <Wrapper>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', marginBottom: '1.31rem' }}
          >
            <Typography sx={{ ...confirmTextStyles.title }}>
              جزئیات قیمت سرویس
            </Typography>
            <Stack
              sx={{ cursor: 'pointer' }}
              alignItems="center"
              display="flex"
              flexDirection="row"
              onClick={() => setIsBSOpen(true)}
            >
              <Typography
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: '400',
                  lineHeight: '1.5rem',
                  color: theme.palette.secondary.main,
                }}
              >
                مشاهده جزییات
              </Typography>
              <ArrowBackIosIcon
                sx={{
                  width: '1rem',
                  height: '1rem',
                  color: theme.palette.secondary.main,
                }}
              />
            </Stack>
          </Stack>
          <RowItem
            title="قیمت سرویس"
            value={`${Math.max(
              order.services_total_price + order.parts_total_price,
              0,
            ).toLocaleString()} تومان`}
            isBlue={false}
          />
          <RowItem
            title="هزینه ایاب ذهاب"
            value={`${Math.max(
              order.invisible_services_total_price,
              0,
            ).toLocaleString()} تومان`}
            isBlue={false}
          />

          {(order.discounts?.length ?? 0) > 0 && (
            <RowItem
              title="تخفیف سرویس"
              value={`${parseInt(
                String(totalDiscount),
              ).toLocaleString()}- تومان`}
              isDiscount={true}
            />
          )}
          {(order.discount_codes?.length ?? 0) > 0 && (
            <RowItem
              title="کد تخفیف"
              value={`${parseInt(
                String(totalDiscountCode),
              ).toLocaleString()}- تومان`}
              isDiscount={true}
            />
          )}
          <Divider sx={{ marginBottom: '1rem ' }} />
          <RowItem
            title="جمع قابل پرداخت"
            value={`${Math.max(
              order.total_price -
                totalDiscount -
                totalDiscountCode +
                order.invisible_services_total_price,
              0,
            ).toLocaleString()} تومان`}
            isImportant={true}
          />
          <Divider sx={{ marginBottom: '1rem ', marginTop: '0.5rem' }} />

          <Stack
            sx={{
              flexDirection: 'row',
              marginTop: { md: '32px' },
              justifyContent: 'space-between',
              bottom: '0',
              left: '0',
              right: '0',
              backgroundColor: theme.palette.common.white,

              position: { xs: 'fixed', md: 'fixed' },
              padding: { xs: ' 0rem', md: '0 2.5rem' },
              width: { xs: '100%', md: '55%' },
            }}
          >
            <Stack
              sx={{
                borderTop: `1px solid ${theme.palette.surface.paper}`,
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                padding: { xs: '1rem 1.5rem', md: '1.5rem 0' },
              }}
            >
              <Stack>
                <Typography
                  sx={{ ...confirmTextStyles.bodyLarge, lineHeight: 'initial' }}
                >
                  مبلغ نهایی
                </Typography>
                <Typography
                  sx={{
                    ...confirmTextStyles.mediumTitle,
                    lineHeight: 'initial',
                  }}
                >
                  {`${Math.max(
                    order.total_price -
                      totalDiscount -
                      totalDiscountCode +
                      order.invisible_services_total_price,
                    0,
                  ).toLocaleString()} تومان`}
                </Typography>
              </Stack>
              <Button
                disabled={submitOrder.isLoading}
                onClick={submitNewOrder}
                id="car-services-final-confirm-btn"
                sx={{
                  alignSelf: { md: 'end' },
                  width: '11.75rem',
                  height: '2.5rem',
                }}
              >
                {submitOrder.isLoading ? (
                  <CircularProgress sx={{ padding: '0.35rem' }} />
                ) : (
                  'تایید و پرداخت'
                )}
              </Button>
            </Stack>
          </Stack>
        </Wrapper>
      </Layout>
      <PartPriceItemsBS
        isOpen={isBSOpen && !!carServicesData.orderId}
        onClose={() => setIsBSOpen(false)}
        orderId={carServicesData.orderId ? String(carServicesData.orderId) : ''}
      />
    </>
  );
};

export default FinalConfirmation;
