import { getUserPhone } from 'utils/auth';
import useAppDispatch from 'hooks/useAppDispatch';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import useAppSelector from 'hooks/useAppSelector';

export type CarQuickSellStep =
  | '00-phone-number'
  | '01-otp'
  | '02-car-info'
  | '03-result';

export interface CarQuickSellState {
  carInfo: {
    info?: {
      applicant_full_name?: string | null;
      brand_model_fa?: string | null;
      car_brand_model_year_djalali?: number | null;
      car_brand_model_year?: number | null;
      address?: string | null;
      color?: string | null;
      usage?: number | null;
    };
    id?: string | null;
    phoneNumber?: string | undefined;
    otp?: string | null;
    address?: string | null;
    hasError?: boolean;
  };
}

const initialState: CarQuickSellState = {
  carInfo: {
    info: {
      applicant_full_name: null,
      brand_model_fa: null,
      car_brand_model_year_djalali: null,
      car_brand_model_year: null,
      address: null,
      color: null,
      usage: null,
    },
    id: null,
    phoneNumber: getUserPhone(),
    otp: null,
    address: null,
    hasError: false,
  },
};

const CarQuickSellSlice = createSlice({
  name: 'CarQuickSellPage',
  initialState,
  reducers: {
    setQSCarInfo: (
      state,
      action: PayloadAction<CarQuickSellState['carInfo']>,
    ) => {
      state.carInfo = {
        ...state.carInfo,
        ...action.payload,
      };
    },
  },
});

export const { setQSCarInfo } = CarQuickSellSlice.actions;

export const useDispatchQSCarInfo = () => {
  const dispatch = useAppDispatch();
  return (state: CarQuickSellState['carInfo']) => dispatch(setQSCarInfo(state));
};

export const useQSCarInfo = () =>
  useAppSelector((state) => state.quickSell.carInfo);

export default CarQuickSellSlice.reducer;
