import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Checkbox, Modal, Stack, Typography } from '@mui/material';
import {
  basicCheckupId,
  selectServicesItemsModalContents,
} from 'containers/car-services-order/configs';
import { FC, useState } from 'react';

interface PropTypes {
  id: number;
  is_required: boolean;
  name: string;
  onClick: (id: number) => void;
  checked: boolean;
  isDisabled?: boolean;
  description: string;
  hasUnavailableParts?: boolean;
  minimumPartPrices?: number;
  minimumServicePrice?: number;
}

const SelectServicesItem: FC<PropTypes> = (props) => {
  const {
    is_required: isRequired,
    id,
    name,
    onClick,
    checked,
    isDisabled,
    description,
    hasUnavailableParts,
    minimumPartPrices,
    minimumServicePrice,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const findedModalData = selectServicesItemsModalContents.find(
    (i) => i.id === id,
  );

  return (
    <>
      <Stack
        component="div"
        onClick={() => {
          if (isRequired || isDisabled) return;
          onClick(id);
        }}
        key={id}
        width="100%"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        p={'8px'}
        sx={{
          userSelect: 'none',
          cursor: 'pointer',
          borderBottom: (th) => `1px solid ${th.palette.surface.paper}`,
          ':last-of-type': {
            borderBottom: 'none',
          },
          minHeight: '4.4rem',
        }}
      >
        <Checkbox
          color={isRequired ? 'default' : 'primary'}
          disabled={isDisabled}
          checked={checked}
        />
        <Stack
          sx={{
            flexDirection: 'row',
            flexGrow: 1,
            marginLeft: '1rem',

            alignItems: 'center',
          }}
        >
          <Stack>
            <Typography
              fontSize="0.875rem"
              fontWeight={400}
              color={(th) =>
                hasUnavailableParts
                  ? th.palette.iconDefault.main
                  : th.palette.common.black
              }
            >
              {name}
            </Typography>
            {description && (
              <Typography
                marginTop="0.5rem"
                fontSize="0.875rem"
                fontWeight={400}
                color={(th) =>
                  hasUnavailableParts
                    ? th.palette.iconDefault.main
                    : th.palette.iconDefault.main
                }
              >
                {hasUnavailableParts
                  ? `در حال حاضر ${name.replace(
                      'تعویض',
                      '',
                    )} متناسب با خودرو شما موجود نیست.`
                  : `شروع قیمت از: ${(
                      (minimumPartPrices ?? 0) + (minimumServicePrice ?? 0)
                    ).toLocaleString()} تومان`}
              </Typography>
            )}
          </Stack>
          {id === basicCheckupId && (
            <Typography
              sx={{
                fontSize: '0.625rem',
                fontWeight: 400,
                marginLeft: '0.5rem',
                padding: '0.1rem 0.38rem',
                backgroundColor: '#87C765',
                borderRadius: '0.5rem',
                color: (theme) => theme.palette.common.white,
              }}
            >
              رایگان
            </Typography>
          )}
        </Stack>
        {findedModalData && (
          <Stack
            sx={{
              backgroundColor: (th) => th.palette.common.white,
              width: '1.5rem',
              height: '1.5rem',
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen((prevOpen) => !prevOpen);
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: (th) => th.palette.info.main,
                width: '1.5rem',
                height: '1.5rem',
              }}
            />
          </Stack>
        )}
      </Stack>
      {typeof findedModalData !== 'undefined' && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          sx={{ outline: 'none' }}
        >
          <Stack
            width="calc(100% - 32px)"
            sx={{
              backgroundColor: (th) => th.palette.surface.default,
              maxWidth: '400px',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            borderRadius="1.75rem"
            p="1.5rem"
          >
            <Typography
              fontSize="1.5rem"
              fontWeight={400}
              mb="1rem"
              color={(th) => th.palette.common.black}
            >
              {findedModalData.title}
            </Typography>
            <Typography
              fontSize="1rem"
              fontWeight={400}
              color={(th) => th.palette.common.black}
              mb="0.5rem"
            >
              {findedModalData.itemsTitle}
            </Typography>
            {findedModalData?.subTitle && (
              <Typography
                fontSize="1rem"
                fontWeight={600}
                color={(th) => th.palette.info.main}
                mb="0.2rem"
              >
                {findedModalData?.subTitle?.split('به همراه')}
                <Typography
                  component="span"
                  fontWeight={400}
                  sx={{ color: (theme) => theme.palette.common.black }}
                >
                  به همراه:
                </Typography>
              </Typography>
            )}
            {findedModalData.bullets.map((bulletItem) => {
              return (
                <Stack
                  key={bulletItem}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="start"
                >
                  <Stack
                    sx={{
                      width: '5px',
                      height: '5px',
                      borderRadius: '50%',
                      marginTop: '11px',
                      backgroundColor: (th) => th.palette.iconDefault.main,
                      mr: 2,
                    }}
                  />
                  <Typography
                    fontSize="1rem"
                    fontWeight={400}
                    sx={{ width: 'fit-content' }}
                    color={(th) => th.palette.iconDefault.main}
                  >
                    {bulletItem}
                  </Typography>
                </Stack>
              );
            })}
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              width="100%"
              p={2}
              mt={3}
              borderRadius="0.5rem"
              sx={{ backgroundColor: '#EBF9FC' }}
            >
              <Stack
                sx={{
                  backgroundColor: (th) => th.palette.background.default,
                  width: '1rem',
                  height: '1rem',
                  minWidth: '1rem',
                  minHeight: '1rem',
                  borderRadius: '50%',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <InfoIcon
                  sx={{
                    color: (th) => th.palette.info.main,
                    width: '1rem',
                    height: '1rem',
                  }}
                />
              </Stack>
              <Typography
                fontSize="0.75rem"
                color={(th) => th.palette.info.main}
                ml={2}
              >
                {findedModalData.warningText}
              </Typography>
            </Stack>
            <Button
              variant="text"
              onClick={() => setIsModalOpen(false)}
              color="info"
              sx={{ alignSelf: 'flex-end', marginTop: 2 }}
            >
              متوجه شدم
            </Button>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default SelectServicesItem;
