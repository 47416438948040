import { useRouter } from 'next/router';
import { stringify } from 'querystring';
import { getQueryValueAsString } from 'utils/query';

const queryFlagKeys = ['hideFooter', 'hideHeader', 'isolated', 'hideBottomNav'];

export interface QueryFlags {
  [key: string]: boolean;
}

const useQueryFlags = (
  requiredKeys?: string[],
  initialShowFalseValues?: boolean,
) => {
  const showFalseValuse =
    typeof initialShowFalseValues !== 'undefined'
      ? initialShowFalseValues
      : false;
  const { query } = useRouter();

  const queryFlags = queryFlagKeys.reduce<QueryFlags>(
    (result, queryFlagKey) => {
      if ((requiredKeys || queryFlagKeys).includes(queryFlagKey)) {
        const queryFlagValue = getQueryValueAsString(query, queryFlagKey);
        if (
          typeof queryFlagValue !== 'string' ||
          queryFlagValue.toLowerCase() !== 'true'
        ) {
          result[queryFlagKey] = false;
        } else {
          result[queryFlagKey] = true;
        }
      }

      return result;
    },
    {},
  );

  const queryFlagsSearchObject = Object.keys(queryFlags).reduce<{
    [key: string]: string;
  }>((result, queryFlagKey) => {
    if (queryFlags[queryFlagKey]) {
      result[queryFlagKey] = 'true';
    } else if (showFalseValuse) {
      result[queryFlagKey] = 'false';
    }

    return result;
  }, {});

  const queryFlagsSearchString = stringify(queryFlagsSearchObject);
  const queryFlagsSearchStringWithQuestion = queryFlagsSearchString
    ? `?${queryFlagsSearchString}`
    : '';

  return {
    queryFlags,
    queryFlagsSearchObject,
    queryFlagsSearchString,
    queryFlagsSearchStringWithQuestion,
  };
};

export default useQueryFlags;
