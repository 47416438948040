import { Box, Stack } from '@mui/material';
import GetUserOtp from 'components/Login/GetUserOtp';
import GetUserPhone from 'components/Login/GetUserPhone';
import Motions from 'components/Login/Motions';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import {
  loginWithModalResolveReject,
  setLoginWithModalResolveReject,
} from 'hooks/useLogin';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  loginSetLoggingPhoneNumber,
  loginSetState,
} from 'states/components-slices/authSlice';
import styles from './styles';

const Login: FC = () => {
  const openingState = useAppSelector((store) => store.auth.state);
  const [show, setShow] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const isOpen = openingState !== 'closed';

  const [otpEnteredLength, setOtpEnteredLength] = useState(0);

  // decides should the modal render or not
  useEffect(() => {
    if (typeof timeoutRef.current === 'number') {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(
      () => {
        setShow(isOpen);
      },
      isOpen ? 50 : 300,
    );
  }, [isOpen]);

  // close the scroll of page whenever the login modal is open.
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  const closeModal = useCallback(() => {
    dispatch(loginSetState('closed'));
    dispatch(loginSetLoggingPhoneNumber(''));
    if (loginWithModalResolveReject) {
      loginWithModalResolveReject.reject();
    }
    setLoginWithModalResolveReject(null);
  }, [dispatch]);

  if (!isOpen && !show) {
    return null;
  }

  const portalContainer = document.getElementById('portal')!;

  return createPortal(
    <>
      <Box
        component="div"
        onClick={closeModal}
        sx={{
          ...styles.backLayer,
          opacity: isOpen && show ? 1 : 0,
          pointerEvents: isOpen && show ? 'all' : 'none',
        }}
      />
      <Stack
        sx={{
          ...styles.modalContainer,
          opacity: isOpen && show ? 1 : 0,
          transform: {
            xs: `scale(${isOpen && show ? 1 : 1.1})`,
            md: `translate(-50%, -50%) scale(${isOpen && show ? 1 : 1.1})`,
          },
          pointerEvents: isOpen && show ? 'all' : 'none',
        }}
      >
        <Stack sx={styles.contents}>
          {openingState === 'phone-number' && <GetUserPhone />}
          {(openingState === 'just-otp' || openingState === 'otp') && (
            <GetUserOtp setOtpEnteredLength={setOtpEnteredLength} />
          )}
        </Stack>
        <Stack sx={styles.imageWrapper}>
          <Motions otpEnteredLength={otpEnteredLength} />
        </Stack>
      </Stack>
    </>,
    portalContainer,
  );
};

export default memo(Login);
