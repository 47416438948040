import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GlobalState {
  userInfo: {
    phoneNumber?: string;
  };
  userIp?: string;
}

const initialState: GlobalState = { userInfo: {}, userIp: '' };

const { reducer, actions } = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalUserInfo: (
      state,
      action: PayloadAction<GlobalState['userInfo']>,
    ) => {
      state.userInfo = action.payload;
    },
    setGlobalUserIp: (state, action: PayloadAction<GlobalState['userIp']>) => {
      state.userIp = action.payload;
    },
  },
});

export const { setGlobalUserInfo, setGlobalUserIp } = actions;
export default reducer;
