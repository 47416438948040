import { useEffect, useState } from 'react';
import { getAppReferrer, setAppReferrer } from '../utils/sessionStorageManager';

const useTwaApp = () => {
  const [isTwa, setIsTwa] = useState<boolean>(getAppReferrer() === 'twa');
  const handleAppReferrerValueChange = () => {
    setIsTwa(getAppReferrer() === 'twa');
  };

  const setAppReferrerSessionStorage = (referrer: 'app' | 'twa') => {
    setAppReferrer(referrer);
  };

  useEffect(() => {
    window.addEventListener('app-referrer-event', handleAppReferrerValueChange);
    return () => {
      window.removeEventListener(
        'app-referrer-event',
        handleAppReferrerValueChange,
      );
    };
  }, []);
  return { isTwa, setAppReferrerSessionStorage };
};

export default useTwaApp;
