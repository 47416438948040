import createStyle from 'utils/createStyle';

const styles = createStyle({
  paragraph: {
    color: (theme) => theme.palette.common.black,
    fontSize: '0.938rem',
    fontWeight: '600',
  },
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root.Mui-disabled': {
      '& > fieldset': {
        borderColor: (theme) => theme.palette.surface.paper,
      },
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '6px',
      paddingLeft: '6px',
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  formControl: {
    flexGrow: 1,
  },
  bottomContainer: {
    width: '100%',
    position: 'fixed',
    transform: 'translateY(-100%)',
    left: '0',
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1.5rem',
    zIndex: 1,
    '@media (min-width: 768px)': {
      transform: 'translateY(0)',
      position: 'unset',
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
      padding: '0px',
    },
  },
  button: {
    width: '100%',
    '@media (min-width: 768px)': {
      marginTop: '2rem',
      maxWidth: '12.5rem',
    },
  },
  startAndormentIcon: {
    color: '#6E6E6E',
    marginRight: 0,
    marginLeft: 0.5,
    width: '1.5rem',
    height: '1.5rem',
  },
  description: {
    color: '#6E6E6E',
    fontSize: '0.813rem',
    fontWeight: '400',
    marginTop: 1,
    marginBottom: 0,
  },
});

export default styles;
