import * as process from 'process';

const ENV = {
  APP_ENV: process.env.APP_ENV,
  LANDING_URL: process.env.LANDING_URL,
  BACKOFFICE_URL: process.env.BACKOFFICE_URL,
  API_2_URL: process.env.API_2_URL,
  WEB_SOCKET_URL: process.env.WEB_SOCKET_URL,
  MY_CAR_API_URL: process.env.MY_CAR_API_URL,
  APP_URL: process.env.APP_URL,
  API_GW_BASE_URL: process.env.API_GW_BASE_URL,
};

export default ENV;
