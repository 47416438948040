import CircleIcon from '@mui/icons-material/Circle';
import { Divider, Stack, Typography } from '@mui/material';
import { BundleHeader } from 'containers/car-services-order/components/BundleItem/BundleHeader';
import { CustomPartSelectorItem } from 'containers/car-services-order/components/BundleItem/CustomPartSelectorItem';
import PartBrandSelectionDrawer from 'containers/car-services-order/components/PartBrandSelectionDrawer';
import {
  basicCheckupId,
  initialData,
  useCarServices,
} from 'containers/car-services-order/configs';
import {
  PartItem,
  useGetQuotation,
  useGetSelectiveRelatedParts,
} from 'containers/car-services-order/requests';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useFetchServiceTypes,
  useGetOrderDetails,
} from 'services/rest/car-services';
import {
  OrderStateType,
  PartType,
  ServiceTypesResult,
  TierType,
} from 'services/rest/car-services/car-services';

export type Props = {
  tierType: TierType;
  onTierChanged(tier: TierType): void;
  servicePrice: number;
  selectedTier: TierType | undefined;
  updateCustomizedIds: (ids: number[]) => void;
  notCompleted?: boolean;
  isOtherTierAvailable: boolean;
};

type SelectedBrands = {
  service: Pick<ServiceTypesResult, 'id' | 'name'>;
  part: PartItem;
};

export const CustomizedBundleItem = ({
  onTierChanged,
  tierType,
  servicePrice,
  selectedTier,
  updateCustomizedIds,
  notCompleted,
  isOtherTierAvailable,
}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedParts, setSelectedParts] = useState([] as SelectedBrands[]);
  const [selectedServiceId, setSelectedServiceId] = useState<number>();
  const { data: carServicesData, setData: setCarServicesData } =
    useCarServices();
  const { data: quotationData } = useGetQuotation(
    {
      uuid: carServicesData.quotationUUID ?? '',
    },
    {
      enabled:
        !carServicesData.orderId && Boolean(carServicesData.quotationUUID),
    },
  );
  const { data: orderData } = useGetOrderDetails(
    String(carServicesData.orderId),
    { enabled: !!carServicesData.orderId },
  );
  const { data: serviceTypes } = useFetchServiceTypes(
    {
      brand_model:
        (carServicesData.orderId
          ? orderData?.car_profile.brand_model_en
          : quotationData?.brand_model_en) ?? '',
      manufactured_year:
        (carServicesData
          ? orderData?.car_profile.manufactured_year
          : quotationData?.manufactured_year) ?? '',
      check_service_price: true,
    },
    {
      enabled: Boolean(orderData || quotationData),
    },
  );
  const { data: relatedParts } = useGetSelectiveRelatedParts(
    {
      brand_model:
        (carServicesData.orderId
          ? orderData?.car_profile.brand_model_en
          : quotationData?.brand_model_en) ?? '',
      manufacture_year:
        (carServicesData.orderId
          ? orderData?.car_profile.manufactured_year
          : quotationData?.manufactured_year) ?? '',
      ids:
        (carServicesData.orderId
          ? orderData?.services.map((item) => item.id)
          : quotationData?.service_types) ?? [],
    },
    {
      enabled: Boolean(orderData || quotationData),
      onSuccess: (response) => {
        if (
          isOtherTierAvailable ||
          (response && response.length > 0) ||
          !carServicesData.orderId
        )
          return;
        // updateOrder.mutate(
        //   {
        //     id: carServicesData.orderId,
        //     source: 'online_web_lead',
        //   },
        //   {
        //     onSuccess: () => {
        //       const fullName = `${carServicesData.firstName} ${carServicesData.lastName}`;
        //       replace(
        //         `/services/car-services/submission/?name=${fullName}&id=${carServicesData.orderId}`,
        //       );
        //       clearData(undefined);
        //       localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
        //     },
        //   },
        // );
      },
    },
  );
  const wrapperRef = useRef<HTMLDivElement>(null);

  const selectedServices = useMemo(() => {
    const selectedItems = carServicesData.orderId
      ? orderData?.services.map((i) => i.id)
      : quotationData?.service_types;
    return (
      serviceTypes?.results
        .filter((item) => selectedItems?.includes(item.id))
        .reduce((acc, item) => {
          return [...acc, ...item.part_types];
        }, [] as PartType[])
        .sort((item) =>
          item.name.includes('روغن')
            ? item.name.includes('فیلتر')
              ? 1
              : -1
            : 0,
        ) ?? []
    );
  }, [
    serviceTypes,
    quotationData?.service_types,
    orderData?.services,
    carServicesData.orderId,
  ]);
  const fullyCompleted = useMemo(
    () => selectedParts.length === selectedServices.length,
    [selectedParts.length, selectedServices.length],
  );
  const totalPrice = useMemo(
    () =>
      selectedParts.reduce((acc, item) => (acc += item.part.market_price), 0),
    [selectedParts],
  );

  // const selectedPartss = useMemo(() => {
  //   selectedServices?.map((item) => ({ serviceId: item.id }));
  // }, [selectedServices]);

  const updateSelected = useCallback(
    (id: number, name: string, item: PartItem) => {
      setSelectedParts((prev) =>
        prev.find((brand) => brand.service.id === id)
          ? prev.map((brand) =>
              brand.service.id === id
                ? { service: brand.service, part: { ...item } }
                : { ...brand },
            )
          : [...prev, { service: { id, name }, part: { ...item } }],
      );
    },
    [],
  );

  const onTierChangeHandler = (value: TierType) => {
    onTierChanged(value);
    setSelectedServiceId(selectedServices[0]?.id);
    setOpen(true);
  };

  useEffect(() => {
    updateCustomizedIds(selectedParts.map((item) => item.part.id));
  }, [updateCustomizedIds, selectedParts]);

  useEffect(() => {
    if (selectedParts.length === 0) return;
    if (selectedParts.length !== selectedServices.length) {
      setTimeout(() => {
        const selectedIndex = selectedServices.findIndex(
          (item) =>
            item.id === selectedParts[selectedParts.length - 1].service.id,
        );
        if (selectedIndex + 1 === selectedServices.length) return;
        setSelectedServiceId(selectedServices[selectedIndex + 1].id);
        setOpen(true);
      }, 100);
    }
  }, [selectedParts, selectedServices]);

  useEffect(() => {
    if (isOpen && selectedParts.length === 0) {
      requestAnimationFrame(() => {
        wrapperRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    }
  }, [isOpen, selectedParts.length]);

  useEffect(() => {
    if (selectedTier === tierType && selectedParts.length === 0) {
      requestAnimationFrame(() => {
        wrapperRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    }
  }, [selectedTier, tierType, selectedParts.length]);

  useEffect(() => {
    if (
      orderData?.state &&
      (['cancelled', 'done'] as OrderStateType[]).includes(orderData.state)
    ) {
      setCarServicesData(initialData);
      if (typeof window !== 'undefined')
        location.replace('/services/car-services/car-info');
    }
  }, [orderData?.state, setCarServicesData]);

  return (
    <Stack
      ref={wrapperRef}
      sx={{
        border: (theme) =>
          `1px solid ${
            selectedTier === tierType
              ? theme.palette.secondary.main
              : theme.palette.surface.paper
          }`,
        width: '100%',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        backgroundColor: 'white',
        padding: '1rem 0.75rem 0.75rem',
      }}
    >
      <BundleHeader
        onTierChanged={onTierChangeHandler}
        tierItem={tierType}
        totalPrice={
          selectedParts.length === selectedServices.length
            ? totalPrice + servicePrice
            : totalPrice
        }
        price={
          selectedParts.length === selectedServices.length
            ? totalPrice + servicePrice
            : totalPrice
        }
        selectedTier={selectedTier}
      />
      <Divider sx={{ margin: '1rem 0 1.5rem' }} />
      {selectedServices?.map((item, index) => {
        const selectedBrand = selectedParts.find((brand) => {
          return brand.service.id === item.id;
        })?.part;
        return (
          <CustomPartSelectorItem
            key={index}
            title={item.name}
            brand={selectedBrand}
            onClick={() => {
              setSelectedServiceId(item.id);
              setOpen(true);
              onTierChanged(tierType);
            }}
            state={
              selectedBrand
                ? 'COMPLETED'
                : notCompleted && !selectedBrand
                ? 'ERROR'
                : 'NONE'
            }
          />
        );
      })}
      <Divider sx={{ marginY: '1rem' }} />
      <Stack flexDirection="row">
        <CircleIcon
          sx={{
            width: '0.375rem',
            height: '0.375rem',
            marginTop: '8px',
            color: (theme) =>
              fullyCompleted
                ? theme.palette.info.main
                : theme.palette.iconDeactive.main,
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              fullyCompleted
                ? theme.palette.info.main
                : theme.palette.iconDeactive.main,
            marginX: '0.75rem',
            fontSize: '0.875rem',
            fontWeight: '400',
            lineHeight: '1.5rem',
          }}
        >
          {serviceTypes?.results
            .filter((item) =>
              (carServicesData.orderId
                ? orderData?.services.map((i) => i.id)
                : quotationData?.service_types
              )?.includes(item.id),
            )
            .reduce(
              (acc, item, index) =>
                acc +
                `${index !== 0 ? ' + ' : ''} ${
                  item.id !== basicCheckupId ? 'اجرت' : ''
                } ${
                  item.id === basicCheckupId ? `${item.name} رایگان` : item.name
                }`,
              '',
            )}
        </Typography>
      </Stack>
      <Stack
        gap={0.5}
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: '600',
            lineHeight: '1.75rem',
            color: (theme) =>
              fullyCompleted
                ? theme.palette.common.black
                : theme.palette.iconDeactive.main,
          }}
        >
          {servicePrice?.toLocaleString()}
          <Typography
            component="span"
            sx={{
              fontSize: '0.675rem',
              fontWeight: '400',
              lineHeight: '1.25rem',
              color: (theme) =>
                fullyCompleted
                  ? theme.palette.common.black
                  : theme.palette.iconDeactive.main,
            }}
          >
            تومان
          </Typography>
        </Typography>
      </Stack>

      <PartBrandSelectionDrawer
        data={relatedParts?.find((item) => {
          return item.id === selectedServiceId;
        })}
        defaultValue={
          selectedParts.find((item) => item.service.id === selectedServiceId)
            ?.part
        }
        isOpen={isOpen}
        onSelect={updateSelected}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Stack>
  );
};
