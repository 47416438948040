import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import stylisRTLPlugin from 'stylis-plugin-rtl';

const isClient = typeof document !== 'undefined';

const createRtlEmotionCache = () => {
  const insertionPoint = isClient
    ? document.querySelector<HTMLMetaElement>(
        'meta[name="emotion-insertion-point"]',
      ) ?? undefined
    : undefined;

  const rtlEmotionCache = createCache({
    insertionPoint,
    key: 'muirtl',
    stylisPlugins: [prefixer, stylisRTLPlugin],
  });

  return rtlEmotionCache;
};

export default createRtlEmotionCache;
