import { QueryClient, dehydrate } from '@tanstack/react-query';
import ENV from 'constants/environments';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { getCars, getCarsKey } from 'services/rest/car';
import { getPLPList } from 'services/rest/plp';
import { getRelatedAds, getRelatedAdsKey } from 'services/rest/relatedAds';
import { getCarPriceCars, getCarPriceCarsKey } from 'services/rest/zero-price';
import store from 'states';
import { groupCars } from 'utils/cars';
import { getQueryValueAsArray, getQueryValueAsString } from 'utils/query';

export const TIP_QUERY_KEY = 'type-en';
export const CITY_QUERY_KEY = 'city';
export const MAX_PRICE_QUERY_KEY = 'max-price';
export const MIN_PRICE_QUERY_KEY = 'min-price';
export const MAX_YEAR_QUERY_KEY = 'max-year';
export const MIN_YEAR_QUERY_KEY = 'min-year';
export const MAX_USAGE_QUERY_KEY = 'max-usage';
export const BRAND_MODEL_QUERY_KEY = 'brand-model';
export const BRAND_MODEL_TYPE_QUERY_KEY = 'brand-model-type';
export const HAS_INSPECTION_QUERY_KEY = 'has-inspection';
export const SEARCH_QUERY_KEY = 'search';
export const SORT_QUERY_KEY = 'sort';
export const HAS_LEASING_QUERY_KEY = 'has-leasing';
export const MAX_PREPAYMENT_KEY = 'max-prepayment';
export const MIN_PREPAYMENT_KEY = 'min-prepayment';

export const PAGE_SIZE = 20;

export const BASE_URL = '/buy-used-cars';

export const cityList = [
  { id: '1', label: 'تهران' },
  { id: '2', label: 'کرج' },
  { id: '6', label: 'شیراز' },
];

export const sortList = [
  { id: 'newest', label: 'جدیدترین' },
  { id: 'price-descending', label: 'گرانترین' },
  { id: 'price-ascending', label: 'ارزانترین' },
];

const roundedPrices = [
  300_000_000, 400_000_000, 500_000_000, 600_000_000, 700_000_000, 800_000_000,
  900_000_000, 1_000_000_000, 2_000_000_000, 3_000_000_000, 4_000_000_000,
  5_000_000_000,
];

export const filtersList: PLPFiltersList = [
  {
    type: 'checkbox',
    queryKey: CITY_QUERY_KEY,
    list: cityList.map((i) => ({ label: i.label, value: i.id.toString() })),
    label: 'شهر',
    makeQueryObj: function (filterObj) {
      const { actives } = filterObj as PLPCurrentFilterCheckbox;

      if (actives.length) {
        return {
          [this.queryKey]: actives,
        };
      } else {
        return {};
      }
    },
    hasActiveQuery: function (query) {
      const checkboxQueryValue = getQueryValueAsArray(query, this.queryKey);
      if (Array.isArray(checkboxQueryValue) && checkboxQueryValue.length) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    type: 'brand-model-tip',
    queryKey: '',
    makeQueryObj: function (filterObj) {
      const { actives } = filterObj as PLPCurrentFilterBrandModelTip;
      const newFilters: ParsedUrlQuery = {};
      if (
        actives.length === 1 &&
        Array.isArray(actives[0]) &&
        actives[0].length
      ) {
        newFilters[BRAND_MODEL_TYPE_QUERY_KEY] = actives[0].map((i) =>
          i.toLowerCase(),
        );
      } else if (actives.length) {
        newFilters[BRAND_MODEL_QUERY_KEY] = actives.map((activeItem) => {
          if (Array.isArray(activeItem)) {
            return activeItem.join(' ').toLowerCase();
          } else {
            return activeItem.toLowerCase();
          }
        });
      }

      return newFilters;
    },
    hasActiveQuery: function (query) {
      const brandModelQueryValue = getQueryValueAsArray(
        query,
        BRAND_MODEL_QUERY_KEY,
      );

      if (Array.isArray(brandModelQueryValue) && brandModelQueryValue.length) {
        return true;
      } else if (
        getQueryValueAsArray(query, BRAND_MODEL_TYPE_QUERY_KEY)?.length
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    type: 'price',
    minQueryKey: MIN_PRICE_QUERY_KEY,
    maxQueryKey: MAX_PRICE_QUERY_KEY,
    makeQueryObj: function (filterObj) {
      const { maxPrice, minPrice } = filterObj as PLPCurrentFilterPrice;
      const newFilters: ParsedUrlQuery = {};
      if (minPrice) {
        newFilters[this.minQueryKey] = minPrice;
      }
      if (maxPrice) {
        newFilters[this.maxQueryKey] = maxPrice;
      }

      return newFilters;
    },
    hasActiveQuery: function (query) {
      const minPriceQueryValue = getQueryValueAsString(
        query,
        MIN_PRICE_QUERY_KEY,
      );
      const maxPriceQueryValue = getQueryValueAsString(
        query,
        MAX_PRICE_QUERY_KEY,
      );
      if (
        typeof minPriceQueryValue === 'string' ||
        typeof maxPriceQueryValue === 'string'
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    type: 'year',
    minQueryKey: MIN_YEAR_QUERY_KEY,
    maxQueryKey: MAX_YEAR_QUERY_KEY,
    makeQueryObj: function (filterObj) {
      const { maxYear, minYear } = filterObj as PLPCurrentFilterYear;
      const newFilters: ParsedUrlQuery = {};
      if (minYear) {
        newFilters[this.minQueryKey] = minYear;
      }
      if (maxYear) {
        newFilters[this.maxQueryKey] = maxYear;
      }

      return newFilters;
    },
    hasActiveQuery: function (query) {
      const minYearQueryValue = getQueryValueAsString(
        query,
        MIN_YEAR_QUERY_KEY,
      );
      const maxYearQueryValue = getQueryValueAsString(
        query,
        MAX_YEAR_QUERY_KEY,
      );
      if (
        typeof minYearQueryValue === 'string' ||
        typeof maxYearQueryValue === 'string'
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    type: 'usage',
    queryKey: MAX_USAGE_QUERY_KEY,
    makeQueryObj: function (filterObj) {
      const { maxUsage } = filterObj as PLPCurrentFilterUsage;
      const newFilters: ParsedUrlQuery = {};
      if (maxUsage) {
        newFilters[this.queryKey] = maxUsage;
      }

      return newFilters;
    },
    hasActiveQuery: function (query) {
      const maxYearQueryValue = getQueryValueAsString(
        query,
        MAX_USAGE_QUERY_KEY,
      );
      if (typeof maxYearQueryValue === 'string') {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    type: 'switch',
    label: 'فقط خرید اقساطی',
    queryKey: HAS_LEASING_QUERY_KEY,
    makeQueryObj(filterObj) {
      const { checked } = filterObj as PLPCurrentFilterSwitch;
      const newFilters: ParsedUrlQuery = {};
      if (checked) {
        newFilters[this.queryKey] = '1';
      }

      return newFilters;
    },
    hasActiveQuery(query) {
      const isChecked = getQueryValueAsString(query, HAS_LEASING_QUERY_KEY);
      if (typeof isChecked === 'string' && isChecked === '1') {
        return true;
      } else {
        return false;
      }
    },
  },
  {
    type: 'switch',
    label: 'فقط کارشناسی شده',
    queryKey: HAS_INSPECTION_QUERY_KEY,
    makeQueryObj(filterObj) {
      const { checked } = filterObj as PLPCurrentFilterSwitch;
      const newFilters: ParsedUrlQuery = {};
      if (checked) {
        newFilters[this.queryKey] = '1';
      }

      return newFilters;
    },
    hasActiveQuery(query) {
      const isChecked = getQueryValueAsString(query, HAS_INSPECTION_QUERY_KEY);
      if (typeof isChecked === 'string' && isChecked === '1') {
        return true;
      } else {
        return false;
      }
    },
  },
];

const isPLPFilterItem = (
  valueObj: PLPFilterItem | PLPFilterMaxMinItem,
): valueObj is PLPFilterItem => {
  return (
    'type' in valueObj && 'queryKey' in valueObj && Boolean(valueObj.queryKey)
  );
};

const isPLPFilterMaxMinItem = (
  valueObj: PLPFilterItem | PLPFilterMaxMinItem,
): valueObj is PLPFilterMaxMinItem => {
  return (
    'type' in valueObj && 'minQueryKey' in valueObj && 'maxQueryKey' in valueObj
  );
};

export const makeNewQueryObj = (
  query: ParsedUrlQuery,
  options: MakeNewQueryObjOptions = {
    page: 1,
    resetSort: false,
  },
) => {
  const plpState = store.getState().plp;
  const newFilters = plpState.currentFilters;
  const sortValue = plpState.sort;
  const searchValue = plpState.search;

  const newQuery = { ...query };

  newFilters.forEach((filterItem) => {
    // delete prev filters if exist.
    if (filterItem.type === 'brand-model-tip') {
      delete newQuery[BRAND_MODEL_QUERY_KEY];
      delete newQuery[BRAND_MODEL_TYPE_QUERY_KEY];
    } else if (isPLPFilterItem(filterItem) && filterItem.queryKey in newQuery) {
      delete newQuery[filterItem.queryKey];
    } else if (isPLPFilterMaxMinItem(filterItem)) {
      if (filterItem.minQueryKey in newQuery) {
        delete newQuery[filterItem.minQueryKey];
      }
      if (filterItem.maxQueryKey in newQuery) {
        delete newQuery[filterItem.maxQueryKey];
      }
    }

    // fill deleted items.
    const findedFilterItemIndex = filtersList.findIndex(
      (filterListItem) =>
        filterListItem.type === filterItem.type &&
        ((isPLPFilterItem(filterListItem) &&
          isPLPFilterItem(filterItem) &&
          filterListItem.queryKey === filterItem.queryKey) ||
          (isPLPFilterMaxMinItem(filterListItem) &&
            isPLPFilterMaxMinItem(filterItem) &&
            filterListItem.minQueryKey === filterItem.minQueryKey &&
            filterListItem.maxQueryKey === filterItem.maxQueryKey) ||
          filterListItem.type === 'brand-model-tip'),
    );
    if (
      findedFilterItemIndex !== -1 &&
      'makeQueryObj' in filtersList[findedFilterItemIndex] &&
      typeof filtersList[findedFilterItemIndex].makeQueryObj !== 'undefined'
    ) {
      const newFiltersSlice =
        filtersList[findedFilterItemIndex].makeQueryObj(filterItem);
      if (Object.keys(newFiltersSlice).length) {
        Object.assign(newQuery, newFiltersSlice);
      }
    }
  });

  // handle resetForm
  if (options.page === 1 && 'page' in newQuery) {
    delete newQuery.page;
  }

  // handle sort
  if (options.resetSort && typeof newQuery[SORT_QUERY_KEY] !== 'undefined') {
    delete newQuery[SORT_QUERY_KEY];
  } else if (sortValue) {
    if (sortValue !== sortList[0].id) {
      newQuery[SORT_QUERY_KEY] = sortValue;
    } else if (typeof newQuery[SORT_QUERY_KEY] !== 'undefined') {
      delete newQuery[SORT_QUERY_KEY];
    }
  }

  if (searchValue) {
    newQuery[SEARCH_QUERY_KEY] = searchValue;
  } else if (SEARCH_QUERY_KEY in newQuery) {
    delete newQuery[SEARCH_QUERY_KEY];
  }

  return newQuery;
};

const urlPatterns = [
  {
    pattern: `${BASE_URL}/brand-model-type/[...${BRAND_MODEL_TYPE_QUERY_KEY}]`,
    matches: (newQuery: ParsedUrlQuery) => {
      if (
        BRAND_MODEL_TYPE_QUERY_KEY in newQuery &&
        Array.isArray(newQuery[BRAND_MODEL_TYPE_QUERY_KEY])
      ) {
        return true;
      }
      return false;
    },
  },
  {
    pattern: `${BASE_URL}/city/[${CITY_QUERY_KEY}]`,
    matches: (newQuery: ParsedUrlQuery) => {
      if (
        CITY_QUERY_KEY in newQuery &&
        Array.isArray(newQuery[CITY_QUERY_KEY]) &&
        newQuery[CITY_QUERY_KEY].length === 1
      ) {
        return true;
      }
      return false;
    },
  },
  {
    pattern: `${BASE_URL}/range-price/[${MIN_PRICE_QUERY_KEY}]/[${MAX_PRICE_QUERY_KEY}]`,
    matches: (newQuery: ParsedUrlQuery) => {
      if (
        MIN_PRICE_QUERY_KEY in newQuery &&
        newQuery[MIN_PRICE_QUERY_KEY] &&
        roundedPrices.includes(Number(newQuery[MIN_PRICE_QUERY_KEY])) &&
        MAX_PRICE_QUERY_KEY in newQuery &&
        newQuery[MAX_PRICE_QUERY_KEY] &&
        roundedPrices.includes(Number(newQuery[MAX_PRICE_QUERY_KEY])) &&
        Number(newQuery[MIN_PRICE_QUERY_KEY]) <
          Number(newQuery[MAX_PRICE_QUERY_KEY])
      ) {
        return true;
      }
      return false;
    },
  },
  {
    pattern: `${BASE_URL}/min-price/[${MIN_PRICE_QUERY_KEY}]`,
    matches: (newQuery: ParsedUrlQuery) => {
      if (
        MIN_PRICE_QUERY_KEY in newQuery &&
        newQuery[MIN_PRICE_QUERY_KEY] &&
        roundedPrices.includes(Number(newQuery[MIN_PRICE_QUERY_KEY]))
      ) {
        return true;
      }
      return false;
    },
  },
  {
    pattern: `${BASE_URL}/max-price/[${MAX_PRICE_QUERY_KEY}]`,
    matches: (newQuery: ParsedUrlQuery) => {
      if (
        MAX_PRICE_QUERY_KEY in newQuery &&
        newQuery[MAX_PRICE_QUERY_KEY] &&
        roundedPrices.includes(Number(newQuery[MAX_PRICE_QUERY_KEY]))
      ) {
        return true;
      }
      return false;
    },
  },
  {
    pattern: `${BASE_URL}/range-year/[${MIN_YEAR_QUERY_KEY}]/[${MAX_YEAR_QUERY_KEY}]`,
    matches: (newQuery: ParsedUrlQuery) => {
      if (
        MIN_YEAR_QUERY_KEY in newQuery &&
        newQuery[MIN_YEAR_QUERY_KEY] &&
        MAX_YEAR_QUERY_KEY in newQuery &&
        newQuery[MAX_YEAR_QUERY_KEY]
      ) {
        return true;
      }
      return false;
    },
  },
  {
    pattern: `${BASE_URL}/min-year/[${MIN_YEAR_QUERY_KEY}]`,
    matches: (newQuery: ParsedUrlQuery) => {
      if (MIN_YEAR_QUERY_KEY in newQuery && newQuery[MIN_YEAR_QUERY_KEY]) {
        return true;
      }
      return false;
    },
  },
  {
    pattern: `${BASE_URL}/max-year/[${MAX_YEAR_QUERY_KEY}]`,
    matches: (newQuery: ParsedUrlQuery) => {
      if (MAX_YEAR_QUERY_KEY in newQuery && newQuery[MAX_YEAR_QUERY_KEY]) {
        return true;
      }
      return false;
    },
  },
];

export const findMatchedPattern = (newQuery: ParsedUrlQuery) => {
  const findedPatternIndex = urlPatterns.findIndex((urlPattern) => {
    return urlPattern.matches(newQuery);
  });

  if (findedPatternIndex === -1) {
    return BASE_URL;
  } else {
    return urlPatterns[findedPatternIndex].pattern;
  }
};

export const makePLPListParams = (query: ParsedUrlQuery): GetPLPListParams => {
  const brandModels = getQueryValueAsArray(query, BRAND_MODEL_QUERY_KEY) || [];

  const brandModelTypes =
    getQueryValueAsArray(query, BRAND_MODEL_TYPE_QUERY_KEY) || [];

  if (brandModelTypes.length) {
    brandModels.push(brandModelTypes.join(' '));
  }

  const result: GetPLPListParams = {
    brand_models: brandModels,
    cities:
      CITY_QUERY_KEY in query
        ? getQueryValueAsArray(query, CITY_QUERY_KEY) || []
        : undefined,
    should_attach_inspection_report:
      HAS_INSPECTION_QUERY_KEY in query
        ? (getQueryValueAsString(query, HAS_INSPECTION_QUERY_KEY) || '') === '1'
        : undefined,
    max_price:
      MAX_PRICE_QUERY_KEY in query
        ? Number(getQueryValueAsString(query, MAX_PRICE_QUERY_KEY) || '0')
        : undefined,
    min_price:
      MIN_PRICE_QUERY_KEY in query
        ? Number(getQueryValueAsString(query, MIN_PRICE_QUERY_KEY) || '0')
        : undefined,
    max_year:
      MAX_YEAR_QUERY_KEY in query
        ? Number(getQueryValueAsString(query, MAX_YEAR_QUERY_KEY) || '0') + 621
        : undefined,
    min_year:
      MIN_YEAR_QUERY_KEY in query
        ? Number(getQueryValueAsString(query, MIN_YEAR_QUERY_KEY) || '0') + 621
        : undefined,
    max_usage:
      MAX_USAGE_QUERY_KEY in query
        ? Number(getQueryValueAsString(query, MAX_USAGE_QUERY_KEY) || '0')
        : undefined,
    is_leasing_available:
      HAS_LEASING_QUERY_KEY in query
        ? (getQueryValueAsString(query, HAS_LEASING_QUERY_KEY) || '') === '1'
        : undefined,
    search_query: getQueryValueAsString(query, SEARCH_QUERY_KEY) || undefined,
    size: PAGE_SIZE,
    start:
      (Number(getQueryValueAsString(query, 'page') || '1') - 1) * PAGE_SIZE,
    sort: getQueryValueAsString(query, SORT_QUERY_KEY) || sortList[0].id,
    max_prepayment:
      MAX_PREPAYMENT_KEY in query
        ? Number(getQueryValueAsArray(query, MAX_PREPAYMENT_KEY) || '0')
        : undefined,
    min_prepayment:
      MIN_PREPAYMENT_KEY in query
        ? Number(getQueryValueAsArray(query, MIN_PREPAYMENT_KEY) || '0')
        : undefined,
  };

  Object.keys(result).forEach((resultKeyItem) => {
    if (
      resultKeyItem in result &&
      typeof result[resultKeyItem as keyof typeof result] === 'undefined'
    ) {
      delete result[resultKeyItem as keyof typeof result];
    }
  });

  return result;
};

export const getPLPServerSideProps = async (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
  metaData: PLPMetaData,
) => {
  try {
    const plpListParams = makePLPListParams(context.query);

    const queryClient = new QueryClient();

    const plpListPromise = getPLPList(plpListParams, context);

    const carsPromise = queryClient.prefetchQuery(
      getCarsKey({ vehicle_type: 'light_car', service_name: 'inspection' }),
      () =>
        getCars({
          vehicle_type: 'light_car',
          service_name: 'inspection',
        }).then((res) => {
          if (!res) {
            return Promise.reject(
              'error while calling getCars in server side of plp',
            );
          } else {
            return res;
          }
        }),
      { retry: false },
    );

    const relatedItemsPromise = queryClient.prefetchQuery({
      queryKey: getRelatedAdsKey({
        brand_models: plpListParams.brand_models,
        cities: plpListParams.cities?.map(Number) || undefined,
        max_price: plpListParams.max_price,
        min_price: plpListParams.min_price,
        size: 15,
      }),
      queryFn: () => {
        return getRelatedAds({
          brand_models: plpListParams.brand_models,
          cities: plpListParams.cities?.map(Number) || undefined,
          max_price: plpListParams.max_price,
          min_price: plpListParams.min_price,
          size: 15,
        });
      },
    });

    const carPriceCarsPromise = queryClient.prefetchQuery({
      queryKey: getCarPriceCarsKey,
      queryFn: getCarPriceCars,
    });

    const [plpList] = await Promise.all([
      plpListPromise,
      carsPromise,
      relatedItemsPromise,
      carPriceCarsPromise,
    ]);

    const dehydratedState = JSON.parse(JSON.stringify(dehydrate(queryClient)));

    return {
      props: {
        dehydratedState,
        plpList: JSON.parse(JSON.stringify(plpList)),
        metaData,
      },
    };
  } catch (err) {
    return {
      props: {
        metaData,
        plpList: null,
      },
    };
  }
};

interface CachedData {
  created_at: number;
  data: GroupedCars;
}

// stores the data that will be caching.
// don't change this value except in "getCarPriceData" function.
let cachedData: CachedData | null = null;

const cacheTime = 1000 * 60 * 60 * 24; // 30 minute

/**
 * caches the data received from getCarPriceCars and all needed headers.
 *  returns the same data for one hour.
 * otherwaze it gets the data from the apis.
 */
export const getPLPGroupedData = async () => {
  const currentTime = new Date().getTime();
  if (
    cachedData !== null &&
    typeof cachedData.created_at === 'number' &&
    currentTime - cachedData.created_at < cacheTime &&
    cachedData.data.length
  ) {
    return cachedData.data;
  } else {
    const allCars = await getCars({
      service_name: 'inspection',
      vehicle_type: 'light_car',
    });

    const data = groupCars(allCars);

    cachedData = {
      created_at: new Date().getTime(),
      data: data.length
        ? data
        : cachedData && cachedData.data
        ? cachedData.data
        : [],
    };

    return data;
  }
};

export const makePLPBrandModelTypeSitemap = async (currentDate: string) => {
  const result: string[] = [];

  const groupedCarsData = await getPLPGroupedData();

  if (groupedCarsData.length) {
    (function startMakingSitemap(groupedCars: GroupedCars) {
      groupedCars.forEach((groupedCarItem) => {
        let urlItem = `${ENV.LANDING_URL}`;
        if (groupedCarItem.brandEn) {
          urlItem += `/buy-used-cars/${BRAND_MODEL_TYPE_QUERY_KEY}/${encodeURIComponent(
            groupedCarItem.brandEn,
          )}`;
        }
        if (groupedCarItem.modelEn) {
          urlItem += `/${encodeURIComponent(groupedCarItem.modelEn)}`;
        }
        if (groupedCarItem.typeEn) {
          urlItem += `/${encodeURIComponent(groupedCarItem.typeEn)}`;
        }
        result.push(`<url>
          <loc>${urlItem}</loc>
          <lastmod>${currentDate}</lastmod>
        </url>`);
        if (Array.isArray(groupedCarItem.list) && groupedCarItem.list.length) {
          startMakingSitemap(groupedCarItem.list);
        }
      });
    })(groupedCarsData);
  }

  return result.join('\n').toLowerCase();
};

export const makePLPCitySitemap = async (currentDate: string) => {
  const result: string[] = [];

  cityList.forEach((cityItem) => {
    result.push(`<url>
    <loc>${ENV.LANDING_URL}/buy-used-cars/city/${cityItem.id}</loc>
      <lastmod>${currentDate}</lastmod>
    </url>`);
  });

  return result.join('\n').toLowerCase();
};
