import { Radio, Stack, Typography } from '@mui/material';
import { PartItem } from 'containers/car-services-order/requests';
import React from 'react';

const PriceComponent = ({
  value,
  currency,
  strikeThrough,
}: {
  value: string;
  currency?: string;
  strikeThrough?: boolean;
}) => {
  return (
    <Stack sx={{ position: 'relative', marginRight: '0.5rem' }}>
      {strikeThrough && (
        <Stack
          sx={{
            width: '100%',
            height: '1px',
            backgroundColor: (theme) => theme.palette.iconDefault.main,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      )}
      <Typography
        sx={{
          fontSize: strikeThrough ? '0.625rem' : '0.875rem',
          fontWeight: 600,
          lineHeight: strikeThrough ? '1.25rem' : '1.75rem',
          width: '100%',
          color: (theme) =>
            strikeThrough ? theme.palette.iconDefault.main : 'initial',
        }}
      >
        {parseInt(value).toLocaleString()}
        <Typography
          component="span"
          sx={{
            fontSize: '0.625rem',
            fontWeight: 400,
            lineHeight: '1.25rem',
          }}
        >
          {currency ?? ' تومان'}
        </Typography>
      </Typography>
    </Stack>
  );
};

// const DiscountBadge = ({ value }: { value: string }) => {
//   return (
//     <Typography
//       sx={{
//         borderRadius: '0.25rem',
//         padding: '0 0.25rem',
//         backgroundColor: (theme) => theme.palette.iconNotif.main,
//         color: (theme) => theme.palette.common.white,
//         fontSize: '0.75rem',
//         fontWeight: 600,
//       }}
//     >
//       {value}
//     </Typography>
//   );
// };

export const PartBrandItem = ({
  onChange,
  part,
  value,
}: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  part: PartItem;
  value: number | undefined;
  defaultValue: PartItem | undefined;
}) => {
  const isChecked = value === part.id;

  return (
    <Stack
      sx={{
        borderRadius: '0.5rem',
        border: (theme) =>
          isChecked
            ? `${1 / 16}rem solid ${theme.palette.secondary.main}`
            : `${1 / 16}rem solid #c9cccf`,
        padding: '0.75rem',
        flexDirection: 'row',
        marginBottom: '0.75rem',
      }}
      onClick={() => {
        onChange?.({
          target: { value: String(part.id) },
        } as React.ChangeEvent<HTMLInputElement>);
      }}
    >
      <Radio
        onChange={onChange}
        sx={{
          width: '3rem',
          height: '1.875rem',
          color: (theme) => theme.palette.secondary.main,
          '&.Mui-checked': {
            color: (theme) => theme.palette.secondary.main,
          },
        }}
        value={part.id}
        checked={isChecked}
      />
      <Stack sx={{ width: '100%' }}>
        <Typography
          sx={{
            color: (theme) => theme.palette.iconDefault.main,
            fontSize: '0.875rem',
            marginBottom: '0.5rem',
            fontWeight: 400,
            lineHeight: '1.875rem',
            width: '100%',
          }}
        >
          {part.name}
        </Typography>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <PriceComponent value={String(part.market_price)} />
          {/* <PriceComponent
            value={String(part.market_price)}
            strikeThrough
          />
          <DiscountBadge value="20%" /> */}
        </Stack>
      </Stack>
    </Stack>
  );
};
