import { createSlice } from '@reduxjs/toolkit';

interface LeasingCalculatorPage {
  data: {
    prePayment: number | null;
    carType: string;
    step: number;
    totalSteps: number;
    totalAmount: number | null;
  };
}

const initialState: LeasingCalculatorPage = {
  data: {
    prePayment: null,
    carType: 'foreign',
    step: 1,
    totalSteps: 2,
    totalAmount: null,
  },
};

const { actions, reducer } = createSlice({
  name: 'leasingCalculator',
  initialState,
  reducers: {
    setPrePaymentData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { setPrePaymentData } = actions;
export default reducer;
