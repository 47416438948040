import { useTheme } from '@mui/material';
import axios from 'axios';
import { REGEX_OTP } from 'constants/regex';
import { useFormik } from 'formik';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useLogin, {
  loginWithModalResolveReject,
  setLoginWithModalResolveReject,
} from 'hooks/useLogin';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  loginSetLoggingPhoneNumber,
  loginSetState,
} from 'states/components-slices/authSlice';
import { saveToken } from 'utils/auth';
import { faNumToEn } from 'utils/en-fa';

const RESEND_TIME_IN_SECONDS = 2 * 60; // tow minutes

const useLogic = (setOtpEnteredLength: Dispatch<SetStateAction<number>>) => {
  const phoneNumber = useAppSelector((store) => store.auth.loggingPhoneNumber);
  const modalState = useAppSelector((store) => store.auth.state);
  const dispatch = useAppDispatch();
  const {
    validateVerificationCode: submitOtp,
    sendOtp: submitPhoneNumber,
    isLoading,
  } = useLogin();

  const currentAc = useRef<AbortController | null>(null);

  const [errorMessage, setErrorMessage] = useState('');

  const [timerSeconds, setTimerSeconds] = useState(RESEND_TIME_IN_SECONDS);

  // timer countdown
  useEffect(() => {
    if (timerSeconds > 0) {
      setTimeout(() => {
        setTimerSeconds(timerSeconds - 1);
      }, 1000);
    }
  }, [timerSeconds]);

  const theme = useTheme();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: '',
    },
    onSubmit: (values) => {
      if (
        REGEX_OTP.test(values.otp) &&
        typeof phoneNumber === 'string' &&
        !isLoading
      ) {
        setErrorMessage('');
        submitOtp(phoneNumber, values.otp)
          .then((res) => {
            saveToken(res.token);
            dispatch(loginSetState('closed'));
            dispatch(loginSetLoggingPhoneNumber(''));
            if (loginWithModalResolveReject) {
              loginWithModalResolveReject.resolve({
                phoneNumber,
                token: res.token,
              });
            }
            setLoginWithModalResolveReject(null);
          })
          .catch((res) => {
            if (axios.isAxiosError(res) && res.response?.status === 400) {
              setErrorMessage('کد تایید شما مطابقت ندارد.');
            } else {
              setErrorMessage('خطایی رخ داده است. مجددا تلاش کنید.');
            }
          });
      }
    },
  });

  // store the length of entered otp for Motion component.
  useEffect(() => {
    setOtpEnteredLength(formik.values.otp.length);
  }, [formik.values.otp.length, setOtpEnteredLength]);

  const closeModal = useCallback(() => {
    setErrorMessage('');
    dispatch(loginSetLoggingPhoneNumber(''));
    dispatch(loginSetState('closed'));
    if (loginWithModalResolveReject) {
      loginWithModalResolveReject.reject();
    }
    setLoginWithModalResolveReject(null);
  }, [dispatch]);

  const changeStepToPhoneNumber = useCallback(() => {
    setErrorMessage('');
    dispatch(loginSetState('phone-number'));
  }, [dispatch]);

  const resendOtp = useCallback(() => {
    setErrorMessage('');
    if (typeof phoneNumber === 'string') {
      submitPhoneNumber(phoneNumber).then(() => {
        setTimerSeconds(RESEND_TIME_IN_SECONDS);
      });
    }
  }, [phoneNumber, submitPhoneNumber]);

  // allowing just numbers for Entering OTP
  const otpChangeHandler = useCallback(
    (newOtp: string) => {
      const newOtpValue = faNumToEn(newOtp.replace(/[^0-9۰-۹]/g, ''));

      formik.setFieldValue('otp', newOtpValue);
      if (REGEX_OTP.test(newOtpValue)) {
        formik.submitForm();
      }
    },
    [formik],
  );

  // WebOTP
  useEffect(() => {
    if (!currentAc.current && !!navigator?.credentials?.get) {
      const ac = new AbortController();
      currentAc.current = ac;
      setTimeout(() => {
        ac.abort();
      }, 10 * 60 * 1000);
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          if (otp) {
            formik.setFieldValue('otp', otp.code).then(() => {
              if (REGEX_OTP.test(otp.code)) {
                formik.submitForm();
              }
            });
          }
        });
    }
  }, [formik]);

  const canSubmit = !isLoading && REGEX_OTP.test(formik.values.otp);

  return {
    phoneNumber,
    modalState,
    errorMessage,
    timerSeconds,
    theme,
    formik,
    changeStepToPhoneNumber,
    closeModal,
    resendOtp,
    otpChangeHandler,
    canSubmit,
  };
};

export default useLogic;
