export const APP_REFERRER_KEY = 'karnameh-app-referrer';

export const setAppReferrer = (value: 'twa' | 'app') => {
  if (typeof sessionStorage === 'undefined') return;
  sessionStorage.setItem(APP_REFERRER_KEY, value);
  window.dispatchEvent(new Event('app-referrer-event'));
};

export const getAppReferrer = () => {
  if (typeof sessionStorage === 'undefined') return 'app';
  return sessionStorage.getItem(APP_REFERRER_KEY);
};

export const isTwaApp = () => {
  if (typeof sessionStorage === 'undefined') return false;
  return sessionStorage.getItem(APP_REFERRER_KEY) === 'twa';
};
