import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import {
  CircularProgress,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { FC, useState } from 'react';

import Spacer from 'components/Spacer';
import Toast from 'components/toast';
import Layout from 'containers/car-services-order/components/layout';
import useFunnelContext from 'hooks/useFunnelContext';
import useLogin from 'hooks/useLogin';
import { getUserPhone, isUserTokenExpired } from 'utils/auth';
import isValidPhoneNumber from 'utils/isValidPhoneNumber';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const UserPhone: FC<PropTypes> = ({ currentStep, totalStep }) => {
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const funnelData = useFunnelContext();
  const initialUserPhone = getUserPhone() || '';
  const { loginWithModal } = useLogin();

  const formik = useFormik({
    initialValues: {
      phoneNumber: initialUserPhone,
    },
    onSubmit: (newValues) => {
      if (!isValidPhoneNumber(newValues.phoneNumber)) {
        setIsPhoneNumberValid(false);
        return;
      }
      setIsPhoneNumberValid(true);
      setIsLoading(true);
      const userPhone = getUserPhone();
      if (isUserTokenExpired() || newValues.phoneNumber !== userPhone) {
        loginWithModal(newValues.phoneNumber)
          .then(() => {
            funnelData?.changeStep('user-name');
          })
          .catch(() => {
            setIsLoading(false);
            return Toast({
              message:
                'مشکلی در ارتباط با سرور پیش آمده لطفا دوباره تلاش کنید!',
              type: 'error',
            });
          });
      } else {
        funnelData?.changeStep('user-name');
        setIsLoading(false);
      }
    },
  });

  return (
    <Layout
      header={{
        title: 'ورود یا ثبت‌نام',
        totalPage: totalStep,
        currentPage: currentStep,
      }}
      extraScroll={false}
      isReactiveToResize={true}
    >
      <Stack sx={{ height: '100%', width: '100%', position: 'relative' }}>
        <Typography
          fontSize="15px"
          fontWeight={600}
          color={(th) => th.palette.common.black}
        >
          برای ادامه، شماره موبایل خود را وارد کنید.
        </Typography>

        <Stack
          component="div"
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '24px',
          }}
        >
          <TextField
            label="شماره موبایل"
            type="tel"
            autoComplete="off"
            size="small"
            placeholder="۰۹xxxxxxxxx"
            name="phoneNumber"
            color="secondary"
            inputProps={{
              maxLength: 11,
              autoComplete: 'nope',
            }}
            value={formik.values.phoneNumber}
            onChange={(e) => {
              let newValue = e.target.value;
              newValue = newValue
                .replace(/[^0-9۰-۹]/g, '')
                .replace(/[۰-۹]/g, (token) => {
                  return String.fromCharCode(
                    token.charCodeAt(0) -
                      ('۰'.charCodeAt(0) - '0'.charCodeAt(0)),
                  );
                });
              formik.setFieldValue('phoneNumber', newValue);
              setIsPhoneNumberValid(true);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneRoundedIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            error={!isPhoneNumberValid}
            helperText={!isPhoneNumberValid && 'شماره وارد شده اشتباه است!'}
          />
        </Stack>

        <Typography
          color={(th) => th.palette.iconDefault.main}
          fontSize="0.75rem"
          mt={2}
          lineHeight="1.5rem"
          fontWeight={400}
        >
          ورود به کارنامه به معنی قبول{' '}
          <Typography
            color={(th) => th.palette.primary.main}
            borderBottom="1px dashed"
            fontSize="0.75rem"
            fontWeight={400}
            component="a"
            href="/car-inspection/terms"
            target="_blank"
          >
            قوانین
          </Typography>
          {' و '}
          <Typography
            color={(th) => th.palette.primary.main}
            borderBottom="1px dashed"
            fontSize="0.75rem"
            fontWeight={400}
            component="a"
            href="/car-inspection/terms"
            target="_blank"
          >
            سیاست‌نامه حریم خصوصی
          </Typography>{' '}
          کارنامه است.
        </Typography>

        <Spacer />
        <Button
          id={'traceable'}
          _traceservicename={'car-exchange'}
          _traceeventname={'car-exchange-user-phone-submit'}
          _traceeventtype={'submit'}
          _traceeventmetadata={JSON.stringify({
            phoneNumber: formik.values.phoneNumber,
          })}
          disabled={!isValidPhoneNumber(formik.values.phoneNumber) || isLoading}
          onClick={() => formik.handleSubmit()}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            p: 0,
            width: { xs: '100%', md: 'auto' },
            minWidth: { md: '12.5rem' },
            height: '2.5rem',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ padding: '0.35rem' }} />
          ) : (
            'دریافت کد تایید'
          )}
        </Button>
      </Stack>
    </Layout>
  );
};

export default UserPhone;
