import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface StickyState {
  latestOrder: string;
}
const initialState: StickyState = {
  latestOrder:
    typeof window !== 'undefined'
      ? localStorage.getItem('latestOrder') || ''
      : '',
};

const { reducer, actions } = createSlice({
  name: 'stickyNotif',
  initialState,
  reducers: {
    setLatestOrder: (state, action: PayloadAction<string>) => {
      state.latestOrder = action.payload;
      localStorage.setItem('latestOrder', action.payload);
    },
  },
});

export const { setLatestOrder } = actions;
export default reducer;
