import createStyle from 'utils/createStyle';

const styles = createStyle({
  menuButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '.5rem',
    alignItems: 'center',
    marginRight: '24px',
    fontSize: '.75rem',
    fontWeight: '500',
    lineHeight: '2',
    color: 'rgba(0,0,0,.56)',
    cursor: 'pointer',
    backgroundColor: 'hsla(0,0%,100%,0)',
    border: 'none',
    transition: 'color .36s ease',
    textDecoration: 'none',
    '& span': {
      marginRight: '8px',
    },

    ':hover': {
      color: 'rgba(0,0,0,.87)',
      backgroundColor: 'transparent',
    },
  },
  subMenuButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.5rem',
    color: (theme) => theme.palette.text.primary,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  menuTitle: {
    color: (theme) => theme.palette.iconDefault.main,
    fontSize: '.875rem',
    fontWeight: '400',
    lineHeight: '1.875',
    letterSpacing: '.0115',
    ':hover': {
      color: (theme) => theme.palette.secondary.main,
    },
  },
  '..mui-style-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper':
    {
      backgroundColor: '#fff',
      borderRadius: '4px',
      boxShadow:
        '0 1px 6px 0 rgb(0 0 0 / 8%), 0 -8px 32px -4px rgb(0 0 0 / 4%), 0 16px 24px -6px rgb(0 0 0 / 4%) !important',
    },
});
export default styles;
