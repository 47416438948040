import { useMemo, useState } from 'react';
import {
  Link,
  Menu,
  MenuItem,
  Button,
  Box,
  Typography,
  Grid,
  Stack,
} from '@mui/material';
import { MenuListItemInterface } from 'interfaces/menuList.interface';
import styles from './styles';
import AppLink from '../AppLink';

interface MenuListInterface {
  list: Array<MenuListItemInterface>;
}

const MenuList = ({ list }: MenuListInterface) => {
  const [anchorEl, setAnchorEl] = useState<Array<null | HTMLElement>>([
    null,
    null,
  ]);
  const open: Array<boolean> = useMemo(
    () => anchorEl.map((i) => Boolean(i)),
    [anchorEl],
  );
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };
  const handleClose = (index: number) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };
  const renderMenuWithSubMenu = (item: MenuListItemInterface) => {
    const Icon = item.icon;
    return (
      <Box
        key={item.id}
        sx={{
          display: { xs: item?.noMobile ? 'none' : 'unset', md: 'unset' },
        }}
      >
        <Button
          id={`header-button-${item.id}`}
          aria-controls={open[item.id] ? `header-button-${item.id}` : undefined}
          aria-haspopup="true"
          aria-expanded={open[item.id] ? 'true' : undefined}
          onClick={(e) => handleClick(e, item.id)}
          variant="text"
          sx={{
            ...styles.menuButton,
            backgroundColor: open[item.id] ? '#F3F2F8' : 'white',
            ':hover': {
              backgroundColor: '#F3F2F8',
            },
          }}
        >
          {Icon && <Icon />}
          <Typography
            sx={{
              ...styles.menuTitle,
              color: open[item.id] ? '#694FB4' : '#6E6E6E',
            }}
          >
            {item.title}
          </Typography>
        </Button>
        <Menu
          id={`header-button-${item.id}`}
          anchorEl={anchorEl[item.id]}
          open={open[item.id] ?? false}
          onClose={() => handleClose(item.id)}
          MenuListProps={{
            disablePadding: true,
            'aria-labelledby': `header-button-${item.id}`,
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{
              margin: '0',
              backgroundColor: '#FFF',
              borderRadius: '0rem 0rem 0.5rem 0.5rem',
              maxWidth: '31rem',
              marginY: '0',
              paddingY: '2rem',
              paddingX: '2rem',
            }}
          >
            {item.subMenu
              ?.sort((a, b) => {
                return a.id - b.id;
              })
              .map((SM) => {
                const SubIcon = SM.icon;
                return (
                  <Grid
                    key={SM.id}
                    item
                    md={6}
                    onClick={() => handleClose(item.id)}
                  >
                    <MenuItem>
                      <AppLink href={SM.link ?? ''} passHref>
                        <Link sx={styles.subMenuButton}>
                          <Stack
                            sx={{
                              width: '3rem',
                              height: '3rem',
                              backgroundColor: '#EBF8F1',
                              borderRadius: '0.5rem',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {SubIcon && <SubIcon type="component" />}
                          </Stack>
                          <Typography sx={styles.menuTitle}>
                            {SM.title}
                          </Typography>
                        </Link>
                      </AppLink>
                    </MenuItem>
                  </Grid>
                );
              })}
          </Grid>
        </Menu>
      </Box>
    );
  };

  return (
    <>
      {list.map((item) => {
        const Icon = item.icon;
        if (Object.keys(item).includes('subMenu')) {
          return renderMenuWithSubMenu(item);
        } else {
          return (
            <AppLink href={item.link ?? ''} passHref key={item.link}>
              <Link
                sx={{
                  ...styles.menuButton,
                  padding: '.4rem',
                  ':hover': {
                    backgroundColor: '#F3F2F8',
                    borderRadius: '0.5rem',
                  },
                }}
              >
                {Icon && <Icon />}
                <Typography sx={styles.menuTitle}>{item.title}</Typography>
              </Link>
            </AppLink>
          );
        }
      })}
    </>
  );
};

export default MenuList;
