import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { getUserPhone } from 'utils/auth';

export type AffidavitStep =
  | '00-customer-name'
  | '01-user-info'
  | '02-login'
  | '03-invoice';

export interface AffidavitPageState {
  step: AffidavitStep | undefined;
  phoneNumber: string | undefined;
  isLoading: boolean;
  hasTOS: boolean;
  otp?: string;
  isPaymentRequired?: boolean;
  reportLink?: string | null;
  userType: 'customer' | 'seller' | undefined;
  userName: string | undefined;
  fullName: string | undefined;
  discountCode?: string;
  discountAmount?: number;
  totalPrice?: number;
  divarToken?: string;
}

const initialState: AffidavitPageState = {
  step: undefined,
  phoneNumber: getUserPhone(),
  isLoading: true,
  hasTOS: false,
  userType: undefined,
  userName: undefined,
  fullName: undefined,
};

const AffidavitSlice = createSlice({
  name: 'AffidavitPage',
  initialState,
  reducers: {
    setAFStep: (state, action: PayloadAction<AffidavitPageState['step']>) => {
      state.step = action.payload;
    },
    setIsLoading: (
      state,
      action: PayloadAction<AffidavitPageState['isLoading']>,
    ) => {
      state.isLoading = action.payload;
    },
    setAFPhoneNumber: (
      state,
      action: PayloadAction<AffidavitPageState['phoneNumber']>,
    ) => {
      state.phoneNumber = action.payload;
    },
    setAFHasTOS: (
      state,
      action: PayloadAction<AffidavitPageState['hasTOS']>,
    ) => {
      state.hasTOS = action.payload;
    },
    setAFOTP: (state, action: PayloadAction<AffidavitPageState['otp']>) => {
      state.otp = action.payload;
    },
    setAFisPaymentRequired: (
      state,
      action: PayloadAction<AffidavitPageState['isPaymentRequired']>,
    ) => {
      state.isPaymentRequired = action.payload;
    },
    setAFReportLink: (
      state,
      action: PayloadAction<AffidavitPageState['reportLink']>,
    ) => {
      state.reportLink = action.payload;
    },
    setAFUserType: (
      state,
      action: PayloadAction<AffidavitPageState['userType']>,
    ) => {
      state.userType = action.payload;
    },
    setAFUserName: (
      state,
      action: PayloadAction<AffidavitPageState['userName']>,
    ) => {
      state.userName = action.payload;
    },
    setAFFullName: (
      state,
      action: PayloadAction<AffidavitPageState['fullName']>,
    ) => {
      state.fullName = action.payload;
    },
    setDiscountCode: (
      state,
      action: PayloadAction<AffidavitPageState['discountCode']>,
    ) => {
      state.discountCode = action.payload;
    },
    setDiscountAmount: (
      state,
      action: PayloadAction<AffidavitPageState['discountAmount']>,
    ) => {
      state.discountAmount = action.payload;
    },
    setTotalPrice: (
      state,
      action: PayloadAction<AffidavitPageState['totalPrice']>,
    ) => {
      state.totalPrice = action.payload;
    },
    setAFDivarToken: (
      state,
      action: PayloadAction<AffidavitPageState['divarToken']>,
    ) => {
      state.divarToken = action.payload;
    },
  },
});

export const {
  setAFStep,
  setIsLoading,
  setAFPhoneNumber,
  setAFHasTOS,
  setAFOTP,
  setAFisPaymentRequired,
  setAFReportLink,
  setAFUserType,
  setAFUserName,
  setAFFullName,
  setDiscountCode,
  setDiscountAmount,
  setTotalPrice,
  setAFDivarToken,
} = AffidavitSlice.actions;

export const useDispatchAFStep = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['step']) => dispatch(setAFStep(state));
};

export const useDispatchAFIsLoading = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['isLoading']) =>
    dispatch(setIsLoading(state));
};

export const useDispatchAFPhoneNumber = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['phoneNumber']) =>
    dispatch(setAFPhoneNumber(state));
};

export const useDispatchAFHasTOS = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['hasTOS']) => dispatch(setAFHasTOS(state));
};

export const useDispatchAFOTP = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['otp']) => dispatch(setAFOTP(state));
};

export const useDispatchsetAFisPaymentRequired = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['isPaymentRequired']) =>
    dispatch(setAFisPaymentRequired(state));
};
export const useDispatchsetAFReportLink = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['reportLink']) =>
    dispatch(setAFReportLink(state));
};
export const useDispatchsetAFUserType = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['userType']) =>
    dispatch(setAFUserType(state));
};
export const useDispatchsetAFUserName = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['userName']) =>
    dispatch(setAFUserName(state));
};
export const useDispatchsetAFFullName = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['userName']) =>
    dispatch(setAFFullName(state));
};
export const useDispatchsetAFDiscountCode = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['discountCode']) =>
    dispatch(setDiscountCode(state));
};
export const useDispatchsetAFDiscountAmount = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['discountAmount']) =>
    dispatch(setDiscountAmount(state));
};

export const useDispatchsetAFTotalPrice = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['totalPrice']) =>
    dispatch(setTotalPrice(state));
};

export const useDispatchAFDivarToken = () => {
  const dispatch = useAppDispatch();
  return (state: AffidavitPageState['divarToken']) =>
    dispatch(setAFDivarToken(state));
};

export const useAFStep = () => useAppSelector((state) => state.affidavit.step);

export const useAFIsLoading = () =>
  useAppSelector((state) => state.affidavit.isLoading);

export const useAFPhoneNumber = () =>
  useAppSelector((state) => state.affidavit.phoneNumber);

export const useAFHasTOS = () =>
  useAppSelector((state) => state.affidavit.hasTOS);

export const useAFOTP = () => useAppSelector((state) => state.affidavit.otp);

export const useAFisPaymentRequired = () =>
  useAppSelector((state) => state.affidavit.isPaymentRequired);

export const useAFReportLink = () =>
  useAppSelector((state) => state.affidavit.reportLink);

export const useAFUserType = () =>
  useAppSelector((state) => state.affidavit.userType);

export const useAFUserName = () =>
  useAppSelector((state) => state.affidavit.userName);

export const useAFFullName = () =>
  useAppSelector((state) => state.affidavit.fullName);

export const useAFDiscountCode = () =>
  useAppSelector((state) => state.affidavit.discountCode);

export const useAFDiscountAmount = () =>
  useAppSelector((state) => state.affidavit.discountAmount);

export const useAFTotalPrice = () =>
  useAppSelector((state) => state.affidavit.totalPrice);

export const useAFDivarToken = () =>
  useAppSelector((state) => state.affidavit.divarToken);

export default AffidavitSlice.reducer;
