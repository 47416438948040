import { FunnelListItem } from 'components/funnel';
import FinalConfirmation from 'containers/car-services-order/steps/FinalConfirmation';
import SelectServices from 'containers/car-services-order/steps/SelectServices';
import SuggestedPackage from 'containers/car-services-order/steps/SuggestedPackage';
import UserAddress from 'containers/car-services-order/steps/UserAddress';
import UserInfo from 'containers/car-services-order/steps/UserInfo';
import UserOTP from 'containers/car-services-order/steps/UserOtp';
import UserPhone from 'containers/car-services-order/steps/UserPhone';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import ENV from 'constants/environments';
import createStyle from 'utils/createStyle';
import CarInfo from './steps/CarInfo';
import { ParsedUrlQuery } from 'querystring';

export const CAR_SERVICES_LOCALSTORAGE_KEY = 'car-services-draft-key';
export const UTM_COOKIE_KEY = 'utm-cookie-key';
export const isProduction = ENV.APP_ENV === 'production';
export const basicCheckupId = isProduction ? 88 : 55;

export type UTMKeyTypes =
  | 'utm_campaign'
  | 'utm_source'
  | 'utm_medium'
  | 'utm_content'
  | 'utm_term'
  | 'utm_document_referrer';

export type UtmDTO = {
  [key in UTMKeyTypes]: string | undefined;
};

const totalStep = 8;

export const saleAssisstantFunnelList: FunnelListItem[] = [
  {
    key: 'car-info',
    component: CarInfo,
    props: { currentStep: 1, totalStep } as Parameters<typeof CarInfo>[0],
    nextStepKey: 'select-services',
  },
  {
    key: 'select-services',
    component: SelectServices,
    props: { currentStep: 2, totalStep } as Parameters<
      typeof SelectServices
    >[0],
    prevStepKey: 'car-info',
    nextStepKey: 'suggested-package',
  },
  {
    key: 'suggested-package',
    component: SuggestedPackage,
    props: { currentStep: 3, totalStep } as Parameters<
      typeof SuggestedPackage
    >[0],
    prevStepKey: 'select-services',
    nextStepKey: 'user-phone',
  },
  {
    key: 'user-phone',
    component: UserPhone,
    props: { currentStep: 4, totalStep } as Parameters<typeof UserPhone>[0],
    prevStepKey: 'suggested-package',
    nextStepKey: 'otp',
  },
  {
    key: 'otp',
    component: UserOTP,
    props: { currentStep: 5, totalStep } as Parameters<typeof UserOTP>[0],
    prevStepKey: 'user-phone',
    nextStepKey: 'user-info',
  },
  {
    key: 'user-info',
    component: UserInfo,
    props: { currentStep: 6, totalStep } as Parameters<typeof UserInfo>[0],
    prevStepKey: 'user-phone',
    nextStepKey: 'user-address',
  },
  {
    key: 'user-address',
    component: UserAddress,
    props: { currentStep: 7, totalStep } as Parameters<typeof UserAddress>[0],
    nextStepKey: 'final-confirmation',
  },
  {
    key: 'final-confirmation',
    component: FinalConfirmation,
    props: { currentStep: 8, totalStep } as Parameters<
      typeof SuggestedPackage
    >[0],
    // prevStepKey: 'select-services',
    // nextStepKey: 'user-phone',
  },
];

export interface CarServicesData {
  orderId?: string | undefined;
  manufactured_year: string | null;
  usage: string;
  firstName: string;
  lastName: string;
  phone_number: string;
  city: number | null;
  address: string;
  services: number[];
  car: Car | null;
  time: string | null;
}

export type CarContextDataType = {
  quotationUUID: string | null;
  orderId: number | null;
  leadType?: 'CONSULTANT' | 'OTHER_LEAD';
  reminderMileage?: string;
  reminderDate?: string;
};

export const initialData: CarContextDataType = {
  quotationUUID: null,
  orderId: null,
};

export interface Car {
  last_level: string;
  last_level_id: number;
  name: string;
  name_en: string;
}

interface CarServicesContextData {
  data: CarContextDataType;
  setData: Dispatch<SetStateAction<CarContextDataType>>;
  clearData: Dispatch<SetStateAction<undefined | null>>;
  otpPhoneNumber: string | null;
  setOtpPhoneNumber: Dispatch<SetStateAction<string | null>>;
  registerId: number | null;
  setRegisterId: Dispatch<SetStateAction<number | null>>;
}

export const CarServicesContext = createContext<CarServicesContextData>({
  data: initialData,
  clearData: () => null,
  setData: () => null,
  otpPhoneNumber: null,
  setOtpPhoneNumber: () => null,
  registerId: null,
  setRegisterId: () => null,
});

export const useCarServices = () => useContext(CarServicesContext);

export const funnelCityList = [
  { id: 1, label: 'تهران' },
  { id: 99999, label: 'سایر شهر‌ها' },
];

export const selectServicesItemsModalContents = [
  {
    title: 'چکاپ کامل',
    subTitle: 'تمام موارد چکاپ پایه به همراه',
    id: 13,
    itemsTitle: 'سرویس چکاپ شامل موارد زیر است:',
    bullets: [
      'بررسی دسته موتورها',
      'تعلیق و فرمان',
      'دیاگ و عیب‌یابی',
      'بررسی گیربکس اتوماتیک و منوآل',
      'بررسی عملکرد سیستم ترمز',
    ],
    warningText: 'در صورت نیاز به تعویض قطعه، هزینه قطعه و اجرت آن اخذ می‌شود.',
  },
  {
    title: 'چکاپ پایه',
    id: basicCheckupId,
    itemsTitle: 'سرویس چکاپ شامل موارد زیر است:',
    bullets: [
      'بررسی سطح مایع خنک کننده موتور: (ضدیخ و ضدجوش)',
      'بررسی سطح روغن هیدرولیک و ترمز',
      'بررسی سلامت باطری و شارژدهی دینام با دستگاه تستر',
      'بررسی عملکرد کلی موتور: (روغن‌سوزی، عیوب واشر سر سیلندر و عیوب تنظیم موتور)',
      'بررسی عملکرد کمپرسور کولر (تست کلاج کمپرسور)',
      'بررسی روغن ریزی‌ها: (واشر درب سوپاپ، گیربکس و فشنگی روغن)',
      'بررسی هرزگرد ها و تسمه سفت‌کن‌ها',
    ],
    warningText: 'در صورت نیاز به تعویض قطعه، هزینه قطعه و اجرت آن اخذ می‌شود.',
  },
];

export const receiptTextStyles = createStyle({
  pageTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '2rem',
  },
  header: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },
  subtitle: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  caption: {
    fontSize: '0.625rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
});

export const confirmTextStyles = createStyle({
  title: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },
  bodyLarge: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.75rem',
  },
  mediumTitle: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  },
});

export const SPECIFIC_DISCOUNT_CODE = 'car_services_specific_discount_code';

const createCarInstance = (
  brand_model: string,
  brand_model_en: string,
): Car => {
  return {
    name: brand_model,
    name_en: brand_model_en,
    last_level: '',
    last_level_id: 1,
  };
};

export type CarInfoType = {
  car?: Car;
  mileage?: string;
  manufactureYear?: string;
};

export const getRetargetingCarInfo = (
  query: ParsedUrlQuery,
): CarInfoType | null => {
  if (!query.brand_model) return null;

  localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
  const data = {} as CarInfoType;

  if (query.brand_model && query.brand_model_en)
    data.car = createCarInstance(
      query.brand_model as string,
      query.brand_model_en as string,
    );
  if (query.mileage) data.mileage = query.mileage as string;
  if (query.manufactured_year)
    data.manufactureYear = query.manufactured_year as string;
  return data;
};

export const getRetargetingCity = (query: ParsedUrlQuery): number | null => {
  if (query.city) {
    const city = query.city as string;
    return city.toLowerCase() === 'tehran' ? 1 : 99999;
  }
  return null;
};

export const getRetargetingDiscount = (query: ParsedUrlQuery) => {
  return query.discount_code ? (query.discount_code as string) : null;
};
