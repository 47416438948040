import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { CitiesV1NeighborhoodRead200DataItem } from 'services/rest/car-inspection/carInspectionAPIs.schemas';
import {
  EvaluationV1PackagesList200DataItem,
  EvaluationV1PackagesListCOI200DataItem,
} from 'services/rest/car-inspection/carInspectionAPIs-new';
import { EvaluationV1ServiceTimeList200DataItem } from 'services/rest/car-inspection/carInspectionAPIs-new';
import { CitiesV1List200DataItem } from 'services/rest/car-inspection/cities-new';
import { getUserPhone } from 'utils/auth';
import { InspectionCenterItem } from 'services/rest/car-inspection/evaluation-new';
import { Car } from 'services/rest/car';
import { TestVariantType } from 'utils/abTest';

export type CarInspectionStep =
  | '00-car-info'
  | '01-location-info'
  | '02-services-info'
  | '03-service-time'
  | '04-user-info'
  | '05-user-otp'
  | '06-invoice';

export type CIPSteps =
  | '00-terms-start'
  | '01-enough-light'
  | '02-dry-clean'
  | '03-empty-trunk'
  | '04-rain-ceil'
  | '05-owner-number'
  | '06-thank-you';

// export interface Car {
//   id: string;
//   name: string;
//   name_en: string;
//   lastLevel: string;
//   lastLevelId: number;
//   type: string;
// }

export type VehicleType = 'light_car' | 'motor_cycle';

export interface CarInspectionPageState {
  step: CarInspectionStep | undefined;
  userOrder?: {
    car?: Car;
    city?: CitiesV1List200DataItem;
    neighborhood?: CitiesV1NeighborhoodRead200DataItem;
    plan?:
      | EvaluationV1PackagesList200DataItem
      | EvaluationV1PackagesListCOI200DataItem;
    serviceTime?: EvaluationV1ServiceTimeList200DataItem;
    center?: InspectionCenterItem;
    fullName?: string;
    phoneNumber?: string;
    address?: string;
    otp?: string;
    discountCode?: string;
    discountPrice?: number;
    totalPrice?: number;
    reserveId?: number;
    serviceID?: string;
    serviceLocation?: string;
    hasTOS?: boolean;
    visitTime?: string;
  };
  divarToken?: string;
  testFlag?: TestVariantType;
  vehicleType: VehicleType;
  isLoading: boolean;
  paymentStep: CIPSteps;
  ownerPhoneNumber?: string;
}

const initialState: CarInspectionPageState = {
  step: undefined,
  userOrder: {
    // serviceID: 'کارشناسی استاندارد',
    // serviceLocation: 'در محل شما',
    phoneNumber: getUserPhone(),
  },
  isLoading: true,
  paymentStep: '06-thank-you',
  vehicleType: 'light_car',
};

const companionInspectorSlice = createSlice({
  name: 'carInspectionPage',
  initialState,
  reducers: {
    setCOIUserOrder: (
      state,
      action: PayloadAction<CarInspectionPageState['userOrder']>,
    ) => {
      state.userOrder = {
        ...state.userOrder,
        ...action.payload,
      };
    },
    setCOIStep: (
      state,
      action: PayloadAction<CarInspectionPageState['step']>,
    ) => {
      state.step = action.payload;
    },
    setIsLoading: (
      state,
      action: PayloadAction<CarInspectionPageState['isLoading']>,
    ) => {
      state.isLoading = action.payload;
    },
    setCOIPaymentStep: (
      state,
      action: PayloadAction<CarInspectionPageState['paymentStep']>,
    ) => {
      state.paymentStep = action.payload;
    },
    setCOIOwnerPhoneNumber: (
      state,
      action: PayloadAction<CarInspectionPageState['ownerPhoneNumber']>,
    ) => {
      state.ownerPhoneNumber = action.payload;
    },
    setCOIDivarToken: (
      state,
      action: PayloadAction<CarInspectionPageState['divarToken']>,
    ) => {
      state.divarToken = action.payload;
    },
    setCOITestFlag: (
      state,
      action: PayloadAction<CarInspectionPageState['testFlag']>,
    ) => {
      state.testFlag = action.payload;
    },
    setCOIVehicleType: (
      state,
      action: PayloadAction<CarInspectionPageState['vehicleType']>,
    ) => {
      state.vehicleType = action.payload;
    },
  },
});

export const {
  setCOIUserOrder,
  setCOIStep,
  setIsLoading,
  setCOIPaymentStep,
  setCOIOwnerPhoneNumber,
  setCOIDivarToken,
  setCOIVehicleType,
  setCOITestFlag,
} = companionInspectorSlice.actions;

export const useDispatchCIUserOrder = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['userOrder']) =>
    dispatch(setCOIUserOrder(state));
};

export const useDispatchCIStep = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['step']) => dispatch(setCOIStep(state));
};

export const useDispatchCIIsLoading = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['isLoading']) =>
    dispatch(setIsLoading(state));
};

export const useDispatchCIPaymentStep = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['paymentStep']) =>
    dispatch(setCOIPaymentStep(state));
};

export const useDispatchCIDivarToken = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['divarToken']) =>
    dispatch(setCOIDivarToken(state));
};

export const useDispatchCIVehicleType = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['vehicleType']) =>
    dispatch(setCOIVehicleType(state));
};

export const useDispatchCITestFlag = () => {
  const dispatch = useAppDispatch();
  return (state: CarInspectionPageState['testFlag']) =>
    dispatch(setCOITestFlag(state));
};

export const useCIUserOrder = () =>
  useAppSelector((state) => state.carInspection.userOrder);

export const useCITestFlag = () =>
  useAppSelector((state) => state.carInspection.testFlag);

export const useCIStep = () =>
  useAppSelector((state) => state.carInspection.step);

export const useCIIsLoading = () =>
  useAppSelector((state) => state.carInspection.isLoading);

export const useCIPaymentStep = () =>
  useAppSelector((state) => state.carInspection.paymentStep);

export const useCIDivarToken = () =>
  useAppSelector((state) => state.carInspection.divarToken);

export const useCIVehicleType = () =>
  useAppSelector((state) => state.carInspection.vehicleType);

export default companionInspectorSlice.reducer;
