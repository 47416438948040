import { styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface PropTypes {
  isactive: string;
}

const Tick = styled(CheckCircleIcon)<PropTypes>`
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  margin-left: 8px;
  transition: color 0.3s;
  color: ${(props) =>
    props.isactive === 'true'
      ? props.theme.palette.primary.main
      : 'rgba(0, 0, 0, 0.32)'};
`;

Tick.defaultProps = {
  isactive: 'false',
};

export default Tick;
