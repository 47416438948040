import createStyle from 'utils/createStyle';

const styles = createStyle({
  imageItemContainer: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transition: 'transform 0.3s, opacity 0.3s',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
