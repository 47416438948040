import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { getUserPhone } from 'utils/auth';

export type DivarInspectionStep = '04-user-info' | '05-user-otp';

export interface DivarInspectionPageState {
  step: DivarInspectionStep | undefined;
  phoneNumber: string | undefined;
  isLoading: boolean;
  divarToken: string | undefined;
  hasTOS: boolean;
  otp?: string;
  inspectionID?: number;
  price?: number;
  isPaymentRequired?: boolean;
  reportLink?: string | null;
}

const initialState: DivarInspectionPageState = {
  step: undefined,
  phoneNumber: getUserPhone(),
  isLoading: true,
  divarToken: undefined,
  hasTOS: false,
};

const divarInspectionSlice = createSlice({
  name: 'divarInspectionPage',
  initialState,
  reducers: {
    setDIStep: (
      state,
      action: PayloadAction<DivarInspectionPageState['step']>,
    ) => {
      state.step = action.payload;
    },
    setIsLoading: (
      state,
      action: PayloadAction<DivarInspectionPageState['isLoading']>,
    ) => {
      state.isLoading = action.payload;
    },
    setDIPhoneNumber: (
      state,
      action: PayloadAction<DivarInspectionPageState['phoneNumber']>,
    ) => {
      state.phoneNumber = action.payload;
    },
    setDIDivarToken: (
      state,
      action: PayloadAction<DivarInspectionPageState['divarToken']>,
    ) => {
      state.divarToken = action.payload;
    },
    setDIHasTOS: (
      state,
      action: PayloadAction<DivarInspectionPageState['hasTOS']>,
    ) => {
      state.hasTOS = action.payload;
    },
    setDIOTP: (
      state,
      action: PayloadAction<DivarInspectionPageState['otp']>,
    ) => {
      state.otp = action.payload;
    },
    setDIInspectionID: (
      state,
      action: PayloadAction<DivarInspectionPageState['inspectionID']>,
    ) => {
      state.inspectionID = action.payload;
    },
    setDIPrice: (
      state,
      action: PayloadAction<DivarInspectionPageState['price']>,
    ) => {
      state.price = action.payload;
    },
    setDIisPaymentRequired: (
      state,
      action: PayloadAction<DivarInspectionPageState['isPaymentRequired']>,
    ) => {
      state.isPaymentRequired = action.payload;
    },
    setDIReportLink: (
      state,
      action: PayloadAction<DivarInspectionPageState['reportLink']>,
    ) => {
      state.reportLink = action.payload;
    },
  },
});

export const {
  setDIStep,
  setIsLoading,
  setDIPhoneNumber,
  setDIDivarToken,
  setDIHasTOS,
  setDIOTP,
  setDIInspectionID,
  setDIPrice,
  setDIisPaymentRequired,
  setDIReportLink,
} = divarInspectionSlice.actions;

export const useDispatchCIStep = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['step']) =>
    dispatch(setDIStep(state));
};

export const useDispatchDIIsLoading = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['isLoading']) =>
    dispatch(setIsLoading(state));
};

export const useDispatchDIPhoneNumber = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['phoneNumber']) =>
    dispatch(setDIPhoneNumber(state));
};

export const useDispatchDIDivarToken = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['divarToken']) =>
    dispatch(setDIDivarToken(state));
};

export const useDispatchDIHasTOS = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['hasTOS']) =>
    dispatch(setDIHasTOS(state));
};

export const useDispatchDIOTP = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['otp']) => dispatch(setDIOTP(state));
};

export const useDispatchsetDIInspectionID = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['inspectionID']) =>
    dispatch(setDIInspectionID(state));
};

export const useDispatchsetDIPrice = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['price']) =>
    dispatch(setDIPrice(state));
};

export const useDispatchsetDIisPaymentRequired = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['isPaymentRequired']) =>
    dispatch(setDIisPaymentRequired(state));
};
export const useDispatchsetDIReportLink = () => {
  const dispatch = useAppDispatch();
  return (state: DivarInspectionPageState['reportLink']) =>
    dispatch(setDIReportLink(state));
};

export const useDIStep = () =>
  useAppSelector((state) => state.divarInspection.step);

export const useDIIsLoading = () =>
  useAppSelector((state) => state.divarInspection.isLoading);

export const useDIPhoneNumber = () =>
  useAppSelector((state) => state.divarInspection.phoneNumber);

export const useDIDivarToken = () =>
  useAppSelector((state) => state.divarInspection.divarToken);

export const useDIHasTOS = () =>
  useAppSelector((state) => state.divarInspection.hasTOS);

export const useDIOTP = () =>
  useAppSelector((state) => state.divarInspection.otp);

export const useDIInspectionID = () =>
  useAppSelector((state) => state.divarInspection.inspectionID);

export const useDIPrice = () =>
  useAppSelector((state) => state.divarInspection.price);

export const useDIisPaymentRequired = () =>
  useAppSelector((state) => state.divarInspection.isPaymentRequired);

export const useDIReportLink = () =>
  useAppSelector((state) => state.divarInspection.reportLink);

export default divarInspectionSlice.reducer;
