import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CAR_EXCHANGE_KEY,
  carExchangeInitialState,
} from 'containers/car-exchange/configs';
import { removeToken } from 'utils/auth';

export interface AuthPageState {
  data: {
    token: string | null;
    isLoggedIn: boolean;
    phoneNumber: string | null;
  };
  state: 'closed' | 'phone-number' | 'otp' | 'just-otp';
  loggingPhoneNumber: string;
}

export const initialAuthPageState: AuthPageState = {
  data: {
    token: null,
    isLoggedIn: false,
    phoneNumber: null,
  },
  state: 'closed',
  loggingPhoneNumber: '',
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState: initialAuthPageState,
  reducers: {
    login: (state, action: PayloadAction<AuthPageState['data']>) => {
      state.data = { ...action.payload };
    },
    logoutUser: (state) => {
      state.data = initialAuthPageState.data;
    },
    setState: (state, action: PayloadAction<AuthPageState['state']>) => {
      state.state = action.payload;
    },
    setLoggingPhoneNumber: (
      state,
      action: PayloadAction<AuthPageState['loggingPhoneNumber']>,
    ) => {
      state.loggingPhoneNumber = action.payload;
    },
  },
});

export const logout = () => (dispatch: Dispatch) => {
  if (localStorage) {
    localStorage.setItem(
      CAR_EXCHANGE_KEY,
      JSON.stringify(carExchangeInitialState),
    );
  }
  removeToken();
  dispatch(actions.logoutUser());
};

export const {
  login,
  logoutUser,
  setState: loginSetState,
  setLoggingPhoneNumber: loginSetLoggingPhoneNumber,
} = actions;

export default reducer;
