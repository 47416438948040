import axios, { AxiosError } from 'axios';
import { REGEX_PHONE_NUMBER } from 'constants/regex';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSendUserAction } from 'services/rest/userLogging';
import {
  login,
  loginSetLoggingPhoneNumber,
  loginSetState,
} from 'states/components-slices/authSlice';
import { getToken, getUserPhone, removeToken, saveToken } from 'utils/auth';
import { apiClient } from '../services/clients';
import { switchEndpoints } from '../services/configs';

export let loginWithModalResolveReject: {
  resolve: (value: { phoneNumber: string; token: string }) => void;
  reject: (reason?: any) => void;
} | null = null;

export const setLoginWithModalResolveReject = (
  value: typeof loginWithModalResolveReject,
) => {
  loginWithModalResolveReject = value;
};

const useLogin = () => {
  const dispatch = useAppDispatch();
  const {
    data: { isLoggedIn },
    state: openingState,
  } = useAppSelector((state) => state.auth);
  const clientIp = useAppSelector((store) => store.global.userIp);
  const { mutate } = useSendUserAction();

  const [isLoading, setIsLoading] = useState(false);

  const { events } = useRouter();

  useEffect(() => {
    if (openingState !== 'closed') {
      events.on('routeChangeComplete', () => {
        dispatch(loginSetState('closed'));
        if (loginWithModalResolveReject) {
          loginWithModalResolveReject.reject();
        }
        loginWithModalResolveReject = null;
      });
    }
  }, [dispatch, events, openingState]);

  const token = getToken();

  function sendOtp(phoneNumber: string) {
    const useActionDefault: SendUserActionParams = {
      service_name: 'general',
      event_name: 'OTP_RESPONSE',
      event_type: 'submit',
      event_metadata: {
        page_url: location.href,
        otp_response_status: 200,
        client_ip: clientIp || '',
      },
    };
    setIsLoading(true);
    return apiClient<ISendOTPResponse>({
      url: switchEndpoints.SEND_OTP,
      method: 'post',
      data: {
        phone_number: phoneNumber,
      },
    })
      .then((res) => {
        mutate(useActionDefault);
        return res;
      })
      .catch((err: AxiosError) => {
        mutate({
          ...useActionDefault,
          event_metadata: {
            ...useActionDefault.event_metadata,
            otp_response_status: err.response?.status || 0,
          },
        });
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function validateVerificationCode(
    phoneNumber: string,
    code: string,
    refresh: boolean = true,
  ) {
    return new Promise<{ token: string }>((resolve, reject) => {
      setIsLoading(true);
      apiClient<IConfirmOTPResponse>({
        url: switchEndpoints.CONFIRM_OTP,
        method: 'post',
        params: {
          refresh: refresh && 'off',
        },
        data: {
          phone_number: phoneNumber,
          code,
        },
      })
        .then((res) => {
          saveToken(res.data.access);
          dispatch(
            login({ token: res.data.access, phoneNumber, isLoggedIn: true }),
          );
          resolve({ token: res.data.access });
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common.authorization = `Bearer ${token}`;

      const phoneNumber = getUserPhone();
      dispatch(login({ token, phoneNumber, isLoggedIn: true }));
    }
  }, [dispatch, token]);

  const loginWithModal = useCallback(
    (initialPhoneNumber?: string) => {
      return new Promise<{ phoneNumber: string; token: string }>(
        (resolve, reject) => {
          loginWithModalResolveReject = {
            resolve,
            reject,
          };
          if (
            typeof initialPhoneNumber === 'string' &&
            REGEX_PHONE_NUMBER.test(initialPhoneNumber)
          ) {
            sendOtp(initialPhoneNumber)
              .then(() => {
                dispatch(loginSetLoggingPhoneNumber(initialPhoneNumber));
                dispatch(loginSetState('just-otp'));
              })
              .catch((err) => {
                if (axios.isAxiosError(err) && err.response?.status === 429) {
                  toast('شماره شما محدود شده است.', {
                    toastId: 'error',
                    position: 'top-center',
                    type: 'error',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
                } else {
                  toast('مشکلی پیش آمده است. مجددا تلاش کنید.', {
                    toastId: 'error',
                    position: 'top-center',
                    type: 'error',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
                }
                reject();
              });
          } else {
            dispatch(loginSetState('phone-number'));
          }
        },
      );
    },
    [dispatch],
  );

  return {
    token,
    isLoggedIn,
    sendOtp,
    validateVerificationCode,
    getToken,
    saveToken,
    removeToken,
    loginWithModal,
    isLoading,
  };
};

export default useLogin;
