import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { removeToken } from '../../../utils/auth';
import {
  CreateOrderParams,
  OrderDTO,
  OrderPaymentItems,
  ServiceTypesParams,
  ServiceTypesResponse,
  SubmitOrderDTO,
  SuggestedBundleResponse,
  SuggestedPartsParams,
  SwitchUserDTO,
  UpdateOrderParams,
  UpdateUserParams,
} from './car-services';
import { AxiosError } from 'axios';
import { apiClient } from '../../clients';
import { aftermarketEndpoints, endPointUrlNormalizer } from '../../configs';

/**
 * getting the services available for passed brand-model-tip car.
 */

export const fetchServiceTypes = (params: ServiceTypesParams | null) => {
  return apiClient<ServiceTypesResponse>({
    method: 'GET',
    url: aftermarketEndpoints.SERVICE_TYPES,
    params,
  }).then((res) => {
    return res.data;
  });
};

export const fetchServiceTypeKey = (params: ServiceTypesParams) => {
  return ['fetchServiceType', params.brand_model, params.check_service_price];
};

export const useFetchServiceTypes = (
  params: ServiceTypesParams | null,
  options?: Omit<
    UseQueryOptions<
      ServiceTypesResponse,
      unknown,
      ServiceTypesResponse,
      ReturnType<typeof fetchServiceTypeKey>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = fetchServiceTypeKey(
    params ?? { brand_model: '', check_service_price: true },
  );
  return useQuery({
    ...options,
    queryFn: () => {
      return fetchServiceTypes(params).then((res) => res);
    },
    queryKey,
  });
};

export const getSuggestedBundles = (params: SuggestedPartsParams) => {
  const p = new URLSearchParams();
  p.append('brand_model', params.brand_model);
  p.append('manufactured_year', params.manufacture_year);
  p.append('tiers', 'standard');
  p.append('tiers', 'economy');
  // p.append('tiers', 'premium');
  params.ids.forEach((id) => {
    p.append('service_types', `${id}`);
  });
  return apiClient<SuggestedBundleResponse>({
    method: 'GET',
    headers: {
      'Karnameh-Authorization': undefined,
    },
    url: aftermarketEndpoints.BUNDLES,
    params: p,
  }).then((res) => {
    return res.data;
  });
};

export const suggestedBundlesKey = (params: SuggestedPartsParams) => {
  return [
    `${params.brand_model}-${params.manufacture_year}-suggestedPartPrices`,
    ...params.ids,
  ];
};

export const useGetSuggestedPartPrices = (
  params: SuggestedPartsParams,
  options?: Omit<
    UseQueryOptions<
      SuggestedBundleResponse,
      unknown,
      SuggestedBundleResponse,
      ReturnType<typeof suggestedBundlesKey>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = suggestedBundlesKey(params);
  return useQuery({
    ...options,
    queryFn: () => {
      return getSuggestedBundles(params).then((res) => res);
    },
    queryKey,
  });
};

export const getCurrentSwitchUser = () => {
  return apiClient<SwitchUserDTO>({
    method: 'GET',
    url: aftermarketEndpoints.CURRENT_USER,
  });
};

export const getCurrentSwitchUserKey = () => {
  return ['currentSwitchUser', getCurrentSwitchUser];
};
export const useGetCurrentSwitchUser = (
  options?: Omit<
    UseQueryOptions<
      SwitchUserDTO,
      unknown,
      SwitchUserDTO,
      ReturnType<typeof getCurrentSwitchUserKey>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = getCurrentSwitchUserKey();
  return useQuery({
    ...options,
    onError: (error) => {
      if ((error as any).response.status === '401') {
        removeToken();
      }
      options?.onError?.(error);
    },
    queryFn: () => {
      return getCurrentSwitchUser().then((res) => res.data);
    },
    queryKey,
  });
};

export const createOrder = (params: CreateOrderParams) => {
  return apiClient<OrderDTO>({
    method: 'POST',
    url: aftermarketEndpoints.ORDERS,
    withCredentials: true,
    data: { ...params, source: 'online_web' },
  });
};

export const createOrderKey = ['createOrderKey'];

export const useCreateOrderMutation = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof createOrder>>,
      unknown,
      CreateOrderParams,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: createOrderKey,
    mutationFn: createOrder,
  });
};

export const updateUserInfo = (params: UpdateUserParams) => {
  return apiClient<SwitchUserDTO>({
    method: 'PUT',
    url: aftermarketEndpoints.CURRENT_USER,
    data: params,
  });
};

export const updateUserInfoKey = ['currentSwitchUser'];

export const useUpdateUserInfoMutation = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof updateUserInfo>>,
      unknown,
      UpdateUserParams,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: updateUserInfoKey,
    mutationFn: updateUserInfo,
  });
};

export const updateOrder = ({ id, ...params }: UpdateOrderParams) => {
  return apiClient<OrderDTO>({
    method: 'PATCH',
    url: endPointUrlNormalizer(aftermarketEndpoints.ORDER_DETAIL, {
      orderID: id,
    }),
    data: params,
  });
};

export const updateOrderKey = ['createOrderKey'];

export const useUpdateOrderMutation = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof updateOrder>>,
      AxiosError<any>,
      UpdateOrderParams,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries([`order-${args[0].data.id}-details`]);
      options?.onSuccess?.(...args);
    },
    mutationKey: updateOrderKey,
    mutationFn: updateOrder,
  });
};

export const getOrderPayments = (orderId: string) => {
  return apiClient<OrderPaymentItems[]>({
    method: 'GET',
    url: endPointUrlNormalizer(aftermarketEndpoints.ORDER_PAYMENT, {
      orderID: orderId,
    }),
  });
};

export const getOrderPaymentsKey = (orderId: string) => [
  `order-${orderId}-payments`,
  getOrderPayments,
];

export const useGetOrderPayments = (
  orderId: string,
  options?: Omit<
    UseQueryOptions<
      OrderPaymentItems[],
      unknown,
      OrderPaymentItems[],
      ReturnType<typeof getOrderPaymentsKey>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = getOrderPaymentsKey(orderId);
  return useQuery({
    ...options,
    queryFn: () => {
      return getOrderPayments(orderId).then((res) => res.data);
    },
    queryKey,
  });
};

export const getOrderDetails = (orderId: string) => {
  return apiClient<OrderDTO>({
    method: 'GET',
    url: endPointUrlNormalizer(aftermarketEndpoints.ORDER_DETAIL, {
      orderID: orderId,
    }),
  });
};

export const getOrderDetailsKey = (orderId: string) => [
  `order-${orderId}-details`,
];
export const useGetOrderDetails = (
  orderId: string,
  options?: Omit<
    UseQueryOptions<
      OrderDTO,
      unknown,
      OrderDTO,
      ReturnType<typeof getOrderDetailsKey>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = getOrderDetailsKey(orderId);
  return useQuery({
    ...options,
    queryFn: () => {
      return getOrderDetails(orderId).then((res) => res.data);
    },
    queryKey,
  });
};

export const submitOrder = (orderId: string) => {
  return apiClient<SubmitOrderDTO>({
    method: 'POST',
    url: endPointUrlNormalizer(aftermarketEndpoints.SUBMIT_ORDER, {
      orderID: orderId,
    }),
  });
};

export const submitOrderKey = ['submitOrder'];

export const useSubmitOrderMutation = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof submitOrder>>,
      unknown,
      string,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: submitOrderKey,
    mutationFn: submitOrder,
  });
};
