import { toast } from 'react-toastify';

interface ToastInterface {
  position?:
    | 'top-right'
    | 'top-center'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-center'
    | 'bottom-left';
  message: any;
  type: 'info' | 'success' | 'warning' | 'error' | 'default';
}
export default function Toast({
  position = 'top-center',
  message,
  type,
}: ToastInterface) {
  return toast(message, {
    position,
    type,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    rtl: true,
  });
}
