import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Slider,
  SliderThumb,
  Stack,
  Typography,
} from '@mui/material';
import {
  CAR_EXCHANGE_KEY,
  CarExchangeState,
  carExchangeInitialState,
} from 'containers/car-exchange/configs';
import Layout from 'containers/car-services-order/components/layout';
import Link from 'next/link';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetPLPListMutate } from 'services/rest/plp';
import { getUserDataFromToken, getUserPhone } from 'utils/auth';
import useFunnelContext from '../../../../hooks/useFunnelContext';

interface Props {
  currentStep: number;
  totalStep: number;
}
type MarkerType = {
  value: number;
  label?: string;
};

function valuetext(value: number) {
  return `${value}°C`;
}
function generateList(number: number, divisor: number): MarkerType[] {
  const result: MarkerType[] = [];
  const max = Math.ceil(number / divisor);

  for (let i = 0; i <= max; i++) {
    const value = i * divisor;
    if (value <= number * 2) {
      const marker: MarkerType = {
        value,
        label: '',
      };
      result.push(marker);
    }
  }

  result.push({
    value: result[result.length - 1].value + divisor,
    label: '',
  });

  return result;
}
interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function ThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <Typography fontSize={9} color={'white'} fontWeight={400}>
        برای تغییر بکشید
      </Typography>
    </SliderThumb>
  );
}
function DeterminingBudget({ currentStep, totalStep }: Props) {
  const layoutRef = useRef<HTMLDivElement | null>(null);
  const carExchangeState: CarExchangeState = localStorage.getItem(
    CAR_EXCHANGE_KEY,
  )
    ? JSON.parse(localStorage.getItem(CAR_EXCHANGE_KEY) ?? '')
    : carExchangeInitialState;

  const [initialized, setIsInitialized] = useState(false);
  const {
    mutate,
    data: plpList,
    isLoading: isPLPLoading,
  } = useGetPLPListMutate();
  const step = 100_000_000;
  const max = 1_000_000_000;
  const min = 0;
  const marks = generateList(max, step);
  const [extraBudget, setExtraBudget] = useState(min);
  const funnelData = useFunnelContext();

  const carProfile = JSON.parse(carExchangeState.car || '') as CarProfileDto;
  const userData = getUserDataFromToken();
  const phoneNumber = getUserPhone();
  const filter = useMemo(
    () => ({
      min_price: extraBudget > 0 ? carExchangeState.price : 0,
      ...(extraBudget < max
        ? { max_price: carExchangeState.price! + extraBudget }
        : {}),
      size: 10000,
    }),
    [carExchangeState, extraBudget],
  );
  function extraBudgetSliderChange(event: Event, value: number | number[]) {
    setExtraBudget(value as number);
  }

  useEffect(() => {
    mutate(filter);
  }, []);

  useEffect(() => {
    if (carExchangeState && carExchangeState.uuid !== '')
      setIsInitialized(true);
    if (carExchangeState && carExchangeState.uuid === '')
      funnelData?.changeStep('select-car');
  }, [carExchangeState]);

  function extraBudgetChange() {
    mutate(filter);
  }

  return (
    <Layout
      ref={layoutRef}
      header={{
        title: 'تعیین بودجه',
        currentPage: currentStep,
        totalPage: totalStep,
      }}
      extraScroll={false}
      isReactiveToResize={true}
    >
      <Stack width={'100%'} height={'100%'} justifyContent={'space-between'}>
        <Stack gap={2} width={'100%'}>
          <Typography
            sx={(t) => ({
              [t.breakpoints.down('md')]: {
                fontSize: 15,
                fontWeight: 400,
                lineHeight: '32px',
              },
              [t.breakpoints.up('md')]: {
                fontSize: 16,
                fontWeight: 600,
                lineHeight: '28px',
              },
            })}
          >
            در صورت نیاز می‌توانید، بودجه خود را افزایش دهید:
          </Typography>
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              px: 4,
            }}
          >
            <Box
              top={0}
              left={0}
              right={0}
              bottom={0}
              position={'absolute'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{
                bottom: 9,
              }}
            >
              <Box
                border={'3px solid'}
                borderColor={(t) => t.palette.secondary.main}
                width={33}
                sx={{
                  borderTopLeftRadius: 3,
                  borderBottomLeftRadius: 3,
                }}
              />
              <Box
                border={'2px solid'}
                borderColor={(t) => t.palette.secondary.main}
                sx={{
                  borderTopRightRadius: 3,
                  borderBottomRightRadius: 3,
                  opacity: 0.38,
                }}
                width={33}
              />
            </Box>
            <Slider
              components={{
                Thumb: ThumbComponent,
              }}
              color="secondary"
              aria-label="Custom marks"
              value={extraBudget}
              getAriaValueText={valuetext}
              onChange={extraBudgetSliderChange}
              onChangeCommitted={extraBudgetChange}
              step={step}
              marks={marks}
              max={max + step}
              min={min}
              sx={{
                '& .MuiSlider-thumb': {
                  width: '84px',
                  borderRadius: '10px',
                },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: -0.4,
                position: 'absolute',
                left: 0,
                right: 0,
              }}
            >
              <Typography
                variant="caption"
                onClick={() => {
                  setExtraBudget(0);
                }}
                sx={{
                  cursor: 'pointer',
                }}
              >
                صفر
              </Typography>
              <Typography
                variant="caption"
                onClick={() => {
                  setExtraBudget(max + step);
                }}
                sx={{
                  cursor: 'pointer',
                }}
              >
                بیش از یک میلیارد
              </Typography>
            </Box>
          </Box>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            marginTop={19}
          >
            <Typography color={'#6E6E6E'} variant="body2">
              تعداد آگهی:
            </Typography>
            <Typography color={'#202021'} variant="body2" fontWeight={600}>
              {isPLPLoading || !initialized ? (
                <CircularProgress size="0.8rem" />
              ) : (
                plpList?.total
              )}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography color={'#6E6E6E'} variant="body2">
              بودجه اضافی‌تر:
            </Typography>
            <Typography color={'#202021'} variant="body2" fontWeight={600}>
              {extraBudget > max
                ? `تا بیش از یک میلیارد تومان`
                : `تا ${extraBudget.toLocaleString()} تومان`}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography color={'#6E6E6E'} variant="body2">
              حدود قیمت خودروی شما:
            </Typography>
            {initialized ? (
              <Typography color={'#202021'} variant="body2" fontWeight={600}>
                {carExchangeState.price!.toLocaleString()} تومان
              </Typography>
            ) : (
              <CircularProgress size="0.8rem" />
            )}
          </Stack>
          <Divider />
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography color={'#6E6E6E'} variant="body2">
              مبلغ کل:
            </Typography>
            <Typography color={'#202021'} variant="body2" fontWeight={600}>
              {extraBudget < max
                ? `${(
                    carExchangeState.price! + extraBudget
                  ).toLocaleString()} تومان`
                : 'بدون محدودیت'}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          borderTop={'1px solid'}
          borderColor={'#EBEBEB'}
          pt={6}
        >
          <Link href={`/buy-used-cars?max-price=${carExchangeState.price}`}>
            <Button
              LinkComponent={'a'}
              variant="text"
              color="primary"
              onClick={() => {
                localStorage.removeItem(CAR_EXCHANGE_KEY);
              }}
            >
              بودجه اضافی ندارم
            </Button>
          </Link>
          <Link
            href={`/buy-used-cars?min-price=${
              extraBudget ? carExchangeState.price : 0
            }${
              filter.max_price ? `&max-price=${filter.max_price}` : ''
            }&sort=price-descending`}
          >
            <Button
              id={'traceable'}
              _traceservicename={'car-exchange'}
              _traceeventname={'car-exchange-determining-budget-submit'}
              _traceeventtype={'submit'}
              _traceeventmetadata={JSON.stringify({
                extraBudget: extraBudget ?? 0,
                brand_model: carProfile?.brand_model_fa,
                manufactured_year: carProfile?.manufactured_year,
                price: carExchangeState?.price ?? 0,
                ...userData,
                phoneNumber,
              })}
              onClick={() => {
                localStorage.removeItem(CAR_EXCHANGE_KEY);
              }}
              LinkComponent={'a'}
              color="primary"
              sx={(t) => ({
                [t.breakpoints.up('md')]: {
                  minWidth: 200,
                },
                [t.breakpoints.down('md')]: {
                  minWidth: 144,
                },
              })}
            >
              تایید و ادامه
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Layout>
  );
}

export default DeterminingBudget;
