export const isIndexedDBSupported = () => {
  return 'indexedDB' in window;
};

export const openIDB = (dbName: string) => {
  if (!isIndexedDBSupported()) {
    return new Promise<string>((_, reject) => {
      reject('indexed db not supported!');
    });
  }
  const dbRequest = window.indexedDB.open(dbName);

  return new Promise<IDBDatabase>((resolve, reject) => {
    dbRequest.onsuccess = () => {
      resolve(dbRequest.result);
    };
    dbRequest.onerror = (e) => {
      reject(e);
    };
  });
};

export const getIDBTableItem = async (
  dbName: string,
  tableName: string,
  itemKey: string,
  transactionMode: IDBTransactionMode = 'readonly',
) => {
  let db = null;
  try {
    db = await openIDB(dbName);
  } catch (_) {
    return new Promise<string>((__, reject) =>
      reject('opening db table failed!'),
    );
  }

  if (!(db instanceof IDBDatabase)) {
    return new Promise<string>((_, reject) => reject('DB type has issue!'));
  }

  const request = db
    .transaction(tableName, transactionMode)
    .objectStore(tableName)
    .get(itemKey);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = (e) => {
      reject(e);
    };
  });
};
