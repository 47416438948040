const BuyIcon = ({ size }: NavigationIconProps) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.34998 21.7389H3.51998L4.87998 20.3789H5.42998V19.0389L6.08998 18.3789H7.07998V17.7889L7.73998 17.1189H8.42998L9.03998 16.5089H9.54998L10.18 17.1389H10.61L12.54 15.2089L10.7 13.3789L2.34998 21.7389Z"
        fill="#00A754"
      />
      <path
        d="M10.41 13.0896L9.42 12.0996L1.62 19.8996V21.7396H1.77L10.41 13.0896Z"
        fill="#00A754"
      />
      <path
        d="M12.59 3.09945L8.98996 6.69945C7.85996 7.82945 7.85996 9.65945 8.98996 10.7895L13.84 15.6395C14.97 16.7695 16.8 16.7695 17.93 15.6395L21.53 12.0395C22.66 10.9095 22.66 9.07945 21.53 7.94945L16.68 3.09945C15.55 1.96945 13.72 1.96945 12.59 3.09945ZM18.94 7.56945C19.37 7.99945 19.37 8.70945 18.94 9.13945C18.51 9.56945 17.8 9.56945 17.37 9.13945L15.66 7.42945C15.23 6.99945 15.23 6.28945 15.66 5.85945C16.09 5.42945 16.8 5.42945 17.23 5.85945L18.94 7.56945Z"
        fill="#00A754"
      />
    </svg>
  );
};
export default BuyIcon;
