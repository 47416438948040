import { InputAdornment, Stack, Typography } from '@mui/material';
import Spacer from 'components/Spacer';
import Footer from 'containers/car-services-order/components/Footer';
import Tick from 'containers/car-services-order/components/Tick';
import Layout from 'containers/car-services-order/components/layout';
import {
  CAR_SERVICES_LOCALSTORAGE_KEY,
  useCarServices,
} from 'containers/car-services-order/configs';
import {
  UpdateQuotationParams,
  useCreateReminder,
  useGetQuotation,
} from 'containers/car-services-order/requests';
import { useFormik } from 'formik';
import useFunnelContext from 'hooks/useFunnelContext';
import useLogin from 'hooks/useLogin';
import { FC, useRef, useState } from 'react';
import { TierType } from 'services/rest/car-services/car-services';
import { getUserPhone, isUserTokenExpired, removeToken } from 'utils/auth';
import {
  useCreateOrderMutation,
  useGetCurrentSwitchUser,
} from '../../../services/rest/car-services';
import isValidPhoneNumber from '../../../utils/isValidPhoneNumber';
import TextField from '@mui/material/TextField';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import Toast from '../../../components/toast';
import { useRouter } from 'next/router';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const UserPhone: FC<PropTypes> = (props) => {
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const funnelData = useFunnelContext();
  const { replace } = useRouter();
  const { currentStep, totalStep } = props;
  const userInfoRef = useRef({ name: '', family: '' });
  const [canFetchUser, setCanFetchUser] = useState<boolean>(false);
  const {
    data: carServicesData,
    setData: setCarServicesData,
    clearData,
    setOtpPhoneNumber,
  } = useCarServices();
  const { sendOtp } = useLogin();
  const initialUserPhone = getUserPhone() || '';
  const { mutate: createOrder } = useCreateOrderMutation();
  const { mutateAsync: createReminder, isLoading: isReminderLoading } =
    useCreateReminder();
  const { data: quotationData } = useGetQuotation(
    {
      uuid: carServicesData.quotationUUID ?? '',
    },
    { enabled: Boolean(carServicesData.quotationUUID) },
  );
  const isTest = funnelData?.testFlag === 'T';

  const navigateToReminderSet = (name: string) => {
    const message = isTest
      ? 'اطلاعات آخرین سرویس دوره‌ای شما با موفقیت ثبت شد.'
      : 'تاریخ بعدی سرویس دوره‌ای شما با موفقیت ثبت شد.';
    replace(
      `/services/car-services/lead-submission/?status=set-reminder&message=${message}&name=${name}`,
    );
    clearData(undefined);
    localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
  };

  const createNewOrder = () => {
    if (!quotationData?.brand_model_fa) return;
    if (carServicesData.orderId) {
      funnelData?.changeStep('user-address');
      return;
    }
    if (!quotationData) return;
    const body: Omit<UpdateQuotationParams, 'uuid'> = {
      tier: quotationData.tier as TierType,
    };
    // if (quotationData.tier === 'customization') {
    body.parts = quotationData.parts;
    // }
    createOrder(
      {
        mileage: quotationData.mileage,
        car_profile: {
          mileage: quotationData.mileage,
          brand_model_en: quotationData.brand_model_en,
          brand_model_fa: quotationData.brand_model_fa,
          manufactured_year: quotationData.manufactured_year,
        },
        service_types: quotationData.service_types,
        ...body,
        quotation_uuid: quotationData.uuid,
      },
      {
        onSuccess: (response) => {
          setCarServicesData((prev) => ({
            ...prev,
            orderId: parseInt(response.data.id),
          }));
          funnelData?.changeStep('user-address');
        },
      },
    );
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: initialUserPhone,
    },
    onSubmit: (newValues) => {
      if (!isValidPhoneNumber(newValues.phoneNumber)) {
        setIsPhoneNumberValid(false);
        return;
      }
      setIsPhoneNumberValid(true);
      const userPhone = getUserPhone();
      if (isUserTokenExpired() || newValues.phoneNumber !== userPhone) {
        setIsLoading(true);
        sendOtp(newValues.phoneNumber)
          .then(() => {
            setOtpPhoneNumber(newValues.phoneNumber);
            funnelData?.changeStep('otp');
          })
          .catch(() => {
            return Toast({
              message:
                'مشکلی در ارتباط با سرور پیش آمده لطفا دوباره تلاش کنید!',
              type: 'error',
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setCanFetchUser(true);
      }
    },
  });

  const { isLoading: isUserLoading, isRefetching } = useGetCurrentSwitchUser({
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: canFetchUser,
    onSuccess: (response) => {
      if (response) {
        setCanFetchUser(false);
        setCarServicesData((prevState) => {
          const newState = { ...prevState };
          userInfoRef.current.name = response.first_name ?? '';
          userInfoRef.current.family = response.last_name ?? '';
          return newState;
        });
      }
      if (carServicesData.reminderDate) {
        const defaultBody = {
          car_profile: {
            brand_model_en: quotationData?.brand_model_en,
            brand_model_fa: quotationData?.brand_model_fa,
            manufactured_year: quotationData?.manufactured_year,
            mileage: quotationData?.mileage,
          },
        };
        const body = carServicesData.reminderMileage
          ? {
              ...defaultBody,
              previous_autoservice_mileage: parseInt(
                carServicesData.reminderMileage,
              ),
              previous_autoservice_date: carServicesData.reminderDate,
            }
          : {
              ...defaultBody,
              upcoming_autoservice: carServicesData.reminderDate,
            };
        createReminder(body).then(() => {
          navigateToReminderSet(`${response.first_name} ${response.last_name}`);
        });
        return;
      }
      if (response.first_name && response.last_name) {
        createNewOrder();
      } else {
        funnelData?.changeStep('user-info');
      }
    },
    onError: (error) => {
      setCanFetchUser(false);
      userInfoRef.current.name = '';
      userInfoRef.current.family = '';

      if ((error as any).response.status === 401) {
        removeToken();
        sendOtp(formik.values.phoneNumber).then(() => {
          setOtpPhoneNumber(formik.values.phoneNumber);
          funnelData?.changeStep('otp');
        });
        return;
      }
      funnelData?.changeStep('user-info');
    },
  });

  return (
    <Layout
      header={{
        title: 'اطلاعات حساب کاربری',
        totalPage: totalStep,
        currentPage: currentStep,
      }}
      extraScroll={false}
    >
      <Typography
        fontSize="15px"
        fontWeight={600}
        color={(th) => th.palette.common.black}
      >
        برای دریافت کد تخفیف اختصاصی، شماره موبایل خود را وارد کنید.
      </Typography>

      <Stack
        component="div"
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '24px',
        }}
      >
        <TextField
          label="شماره موبایل"
          type="tel"
          autoComplete="off"
          size="small"
          placeholder="۰۹xxxxxxxxx"
          name="phoneNumber"
          inputProps={{
            maxLength: 11,
            autoComplete: 'nope',
          }}
          value={formik.values.phoneNumber}
          onChange={(e) => {
            let newValue = e.target.value;
            newValue = newValue
              .replace(/[^0-9۰-۹]/g, '')
              .replace(/[۰-۹]/g, (token) => {
                return String.fromCharCode(
                  token.charCodeAt(0) - ('۰'.charCodeAt(0) - '0'.charCodeAt(0)),
                );
              });
            formik.setFieldValue('phoneNumber', newValue);
            setIsPhoneNumberValid(true);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalPhoneRoundedIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          error={!isPhoneNumberValid}
          helperText={!isPhoneNumberValid && 'شماره وارد شده اشتباه است!'}
        />
        <Tick isactive={String(/^09\d{9}$/.test(formik.values.phoneNumber))} />
      </Stack>

      <Typography
        color={(th) => th.palette.common.black}
        fontSize="0.75rem"
        mt={2}
        fontWeight={400}
      >
        با ورود به کارنامه،{' '}
        <Typography
          color={(th) => th.palette.secondary.main}
          fontSize="0.75rem"
          fontWeight={400}
          component="a"
          href="/car-inspection/terms"
        >
          قوانین کارنامه و سیاست‌نامه
        </Typography>{' '}
        حریم‌خصوصی را می‌پذیرم.
      </Typography>

      <Spacer />
      <Footer
        isLoading={
          (canFetchUser && (isUserLoading || isRefetching)) ||
          isReminderLoading ||
          isLoading
        }
        isDisabled={
          !formik.values.phoneNumber ||
          (canFetchUser && isUserLoading) ||
          isRefetching ||
          isReminderLoading ||
          isLoading
        }
        id="car-services-phone-btn"
        onClick={() => formik.handleSubmit()}
      />
    </Layout>
  );
};

export default UserPhone;
