import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import Spacer from 'components/Spacer';
import Footer from 'containers/car-services-order/components/Footer';
import Tick from 'containers/car-services-order/components/Tick';
import Layout from 'containers/car-services-order/components/layout';
import {
  CAR_SERVICES_LOCALSTORAGE_KEY,
  useCarServices,
} from 'containers/car-services-order/configs';
import { useFormik } from 'formik';
import useFunnelContext from 'hooks/useFunnelContext';
import { FC, useEffect, useMemo, useRef } from 'react';
import {
  useCreateOrderMutation,
  useGetCurrentSwitchUser,
  useUpdateUserInfoMutation,
} from '../../../services/rest/car-services';
import {
  UpdateQuotationParams,
  useCreateReminder,
  useGetQuotation,
} from 'containers/car-services-order/requests';
import { useRouter } from 'next/router';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const UserInfo: FC<PropTypes> = (props) => {
  const { currentStep, totalStep } = props;
  const { mutate: createOrder, isLoading: isCreateOrderLoading } =
    useCreateOrderMutation();
  const updateUserInfo = useUpdateUserInfoMutation();
  const funnelData = useFunnelContext();
  const { replace } = useRouter();
  const userInfoRef = useRef<{ name: string; family: string }>({
    name: '',
    family: '',
  });
  const {
    data: carServicesData,
    setData: setCarServicesData,
    clearData,
  } = useCarServices();
  const { data: quotationData } = useGetQuotation(
    {
      uuid: carServicesData.quotationUUID ?? '',
    },
    { enabled: Boolean(carServicesData.quotationUUID) },
  );
  const { mutateAsync: createReminder } = useCreateReminder();
  const { data: userData } = useGetCurrentSwitchUser();
  const createNewOrder = () => {
    if (quotationData?.brand_model_fa && quotationData.manufactured_year) {
      if (carServicesData.orderId) {
        funnelData?.nextStep();
        return;
      }
      const body = {
        tier: quotationData.tier,
      } as Omit<UpdateQuotationParams, 'uuid'>;
      // if (quotationData.tier === 'customization') {
      body.parts = quotationData.parts;
      // }
      createOrder(
        {
          mileage: quotationData.mileage,
          car_profile: {
            mileage: quotationData.mileage,
            brand_model_en: quotationData.brand_model_en,
            brand_model_fa: quotationData.brand_model_fa,
            manufactured_year: quotationData.manufactured_year,
          },
          service_types: quotationData.service_types,
          ...body,
          quotation_uuid: quotationData.uuid,
        },
        {
          onSuccess: (response) => {
            setCarServicesData((prevState) => ({
              ...prevState,
              orderId: parseInt(response.data.id),
            }));
            funnelData?.nextStep();
          },
        },
      );
    }
  };

  const isTest = funnelData?.testFlag === 'T';

  const navigateToReminderSet = (name: string) => {
    const message = isTest
      ? 'اطلاعات آخرین سرویس دوره‌ای شما با موفقیت ثبت شد.'
      : 'تاریخ بعدی سرویس دوره‌ای شما با موفقیت ثبت شد.';
    replace(
      `/services/car-services/lead-submission/?status=5&message=${message}&name=${name}&desc=در موعد مقرر، همراه با تخفیف‌های ویژه با ارائه سرویس دوره‌ای همراه شما خواهیم بود.`,
    );
    clearData(undefined);
    localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
  };

  const formik = useFormik({
    initialValues: {
      firstName: userData?.first_name ?? '',
      lastName: userData?.last_name ?? '',
    },
    onSubmit: (newValues) => {
      userInfoRef.current.name = newValues.firstName;
      userInfoRef.current.family = newValues.lastName;

      updateUserInfo.mutate(
        {
          first_name: newValues.firstName,
          last_name: newValues.lastName,
        },
        {
          onSuccess: () => {
            if (carServicesData.orderId) {
              funnelData?.nextStep();
              return;
            }
            if (carServicesData.reminderDate) {
              const defaultBody = {
                car_profile: {
                  brand_model_en: quotationData?.brand_model_en,
                  brand_model_fa: quotationData?.brand_model_fa,
                  manufactured_year: quotationData?.manufactured_year,
                  mileage: quotationData?.mileage,
                },
              };
              const body = carServicesData.reminderMileage
                ? {
                    ...defaultBody,
                    previous_autoservice_mileage: parseInt(
                      carServicesData.reminderMileage,
                    ),
                    previous_autoservice_date: carServicesData.reminderDate,
                  }
                : {
                    ...defaultBody,
                    upcoming_autoservice: carServicesData.reminderDate,
                  };
              createReminder(body).then(() => {
                navigateToReminderSet(
                  `${newValues.firstName} ${newValues.lastName}`,
                );
              });
              return;
            }
            createNewOrder();
          },
        },
      );
    },
  });

  const isValid = useMemo(() => {
    const { values } = formik;

    return /^.{1,}$/.test(values.firstName) && /^.{1,}$/.test(values.lastName);
  }, [formik]);

  useEffect(() => {
    formik.setValues({
      firstName: userData?.first_name ?? '',
      lastName: userData?.last_name ?? '',
    });
  }, [userData?.first_name, userData?.last_name]);

  return (
    <Layout
      header={{
        title: ' اطلاعات فردی',
        totalPage: totalStep,
        currentPage: currentStep,
      }}
    >
      <Typography
        fontSize="15px"
        fontWeight={600}
        color={(th) => th.palette.common.black}
      >
        اطلاعات فردی خود را وارد کنید.
      </Typography>

      <Stack
        component="div"
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '24px',
        }}
      >
        <FormControl
          sx={{
            flexGrow: 1,
          }}
        >
          <InputLabel>نام</InputLabel>
          <OutlinedInput
            label="نام"
            type="text"
            size="small"
            startAdornment={
              <PersonRoundedIcon
                sx={{
                  color: '#6E6E6E',
                  marginRight: 1,
                }}
              />
            }
            placeholder="بنویسید ..."
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            sx={{ width: '100%' }}
            autoComplete="off"
          />
        </FormControl>
        <Tick isactive={String(/^.{3,}$/.test(formik.values.firstName))} />
      </Stack>

      <Stack
        component="div"
        sx={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '24px',
        }}
      >
        <FormControl
          sx={{
            flexGrow: 1,
          }}
        >
          <InputLabel>نام خانوادگی</InputLabel>
          <OutlinedInput
            label="نام خانوادگی"
            type="text"
            size="small"
            startAdornment={
              <PersonRoundedIcon
                sx={{
                  color: '#6E6E6E',
                  marginRight: 1,
                }}
              />
            }
            placeholder="بنویسید ..."
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            sx={{ width: '100%' }}
            autoComplete="off"
          />
        </FormControl>
        <Tick isactive={String(/^.{3,}$/.test(formik.values.lastName))} />
      </Stack>

      <Spacer />

      <Footer
        isLoading={updateUserInfo.isLoading || isCreateOrderLoading}
        isDisabled={
          !isValid || updateUserInfo.isLoading || isCreateOrderLoading
        }
        onClick={formik.submitForm}
        id="car-services-user-info-btn"
      />
    </Layout>
  );
};

export default UserInfo;
