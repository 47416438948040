let start: number, previousTimeStamp: number;
let done = false;

function step(timeStamp: number, callback: () => void) {
  if (start === undefined) {
    start = timeStamp;
  }
  const elapsed = timeStamp - start;

  if (previousTimeStamp !== timeStamp) {
    // Math.min() is used here to make sure the element stops at exactly 200px
    const count = Math.min(0.1 * elapsed, 200);
    callback();
    if (count === 200) done = true;
  }

  if (elapsed < 2000) {
    // Stop the animation after 2 seconds
    previousTimeStamp = timeStamp;
    if (!done) {
      window.requestAnimationFrame((time) => step(time, callback));
    }
  }
}

export const requestForAnimation = (callback: () => void) => {
  requestAnimationFrame((time: number) => {
    step(time, callback);
  });
};
