import createStyle from 'utils/createStyle';

const styles = createStyle({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  header: {
    width: '100%',
    height: '64px',
    minHeight: '64px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12)',
  },
  headerTitle: {
    color: (theme) => theme.palette.common.black,
    fontSize: '20px',
    marginLeft: '8px',
    width: 'max-content',
    '@media (max-width: 767px)': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  headerBackButton: {
    marginLeft: '8px',
  },
  percentBar: {
    height: '4px',
    width: '30%',
    backgroundColor: (theme) => theme.palette.primary.main,
    transition: 'width 0.3s',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
  },
  contentContainer: {
    width: '100%',
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'auto',
    position: 'relative',
  },
  content: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '24px',
    '@media (min-width: 768px)': {
      minHeight: 'unset',
      flexGrow: 1,
    },
  },
});

export default styles;
