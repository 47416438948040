const SellIcon = ({ size }: NavigationIconProps) => {
  return (
    <svg
      width={size ?? '24'}
      height={size ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.119985 13.3502C0.179985 12.7702 0.589985 12.3602 0.989985 11.9602C3.78999 9.18015 6.57999 6.40015 9.34999 3.61016C9.96999 2.98016 10.69 2.68016 11.57 2.70016C13.22 2.74016 14.87 2.75016 16.51 2.77016C17.36 2.78016 18.03 3.12016 18.41 3.90016C18.5 4.09016 18.56 4.31016 18.59 4.52016C18.62 4.70016 18.68 4.80016 18.86 4.87016C20.11 5.33016 21.28 5.94016 22.3 6.80015C22.86 7.27015 23.35 7.81015 23.66 8.48015C24.19 9.62015 23.72 10.7602 22.54 11.1802C21.57 11.5202 20.59 11.4502 19.61 11.2902C19.31 11.2402 19.01 11.1602 18.71 11.0902C18.59 11.0602 18.53 11.0802 18.45 11.2002C18.28 11.4802 18.1 11.7702 17.87 12.0002C14.99 14.9002 12.1 17.7802 9.20999 20.6702C8.36999 21.5102 7.27999 21.5102 6.43999 20.6702C4.53999 18.7802 2.64999 16.8802 0.759985 15.0002C0.439985 14.6802 0.179985 14.3102 0.129985 13.8702C0.0999854 13.6902 0.109985 13.5202 0.119985 13.3502ZM15.53 7.96015C16.02 8.24015 16.48 8.54015 16.97 8.78015C18.2 9.40015 19.48 9.82015 20.87 9.86015C21.3 9.88015 21.72 9.85015 22.12 9.67015C22.32 9.58015 22.4 9.43015 22.31 9.24015C22.18 8.97015 22.04 8.70015 21.84 8.48015C20.98 7.53015 19.88 6.94015 18.72 6.44015C18.71 6.44015 18.69 6.45015 18.68 6.45015C18.68 7.13015 18.68 7.82015 18.68 8.50015C18.66 8.50015 18.64 8.51015 18.63 8.51015C17.7 8.19015 16.83 7.78015 16.02 7.22015C15.96 7.18015 15.92 7.07015 15.92 6.99015C15.92 6.01015 15.16 5.33015 14.19 5.45016C13.48 5.54015 12.89 6.23015 12.9 6.95015C12.92 7.72015 13.52 8.37015 14.26 8.43015C14.78 8.47015 15.19 8.29015 15.53 7.96015Z"
        fill="#00A754"
      />
    </svg>
  );
};
export default SellIcon;
