import { Box } from '@mui/material';
import useQueryFlags from 'hooks/useQueryFlags';
import BottomNav from 'layout/BottomNav';
import Footer from 'layout/Footer';
import Header from 'layout/Header';
import { emptyPages } from 'layout/data';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo, type FC, type PropsWithChildren } from 'react';
import useTwaApp from '../hooks/useTwaApp';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useRouter();
  const {
    queryFlags: { hideFooter, hideHeader, hideBottomNav },
  } = useQueryFlags([
    'hideFooter',
    'hideHeader',
    'hideBottomNav',
    'hideBottomNav',
  ]);

  const hasHeaderAndFooter = useMemo(
    () => !emptyPages.some((emptyPage) => new RegExp(emptyPage).test(pathname)),
    [pathname],
  );
  const { isTwa } = useTwaApp();

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <Box
        component={'main'}
        sx={(t) =>
          pathname === '/'
            ? {
                [t.breakpoints.down('md')]: {
                  pt: '5.5rem',
                },
                [t.breakpoints.up('md')]: {
                  pt: '65px',
                },
              }
            : {}
        }
        id="main"
      >
        {children}
      </Box>
      {hasHeaderAndFooter && !hideFooter && !isTwa ? <Footer /> : null}
      {hasHeaderAndFooter && !hideHeader ? <Header /> : null}
      {hasHeaderAndFooter && !hideBottomNav ? <BottomNav /> : null}
    </>
  );
};

export default Layout;
