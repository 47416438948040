import DeterminingBudget from 'containers/car-exchange/steps/DeterminingBudget';
import { FunnelListItem } from '../../components/funnel';
import CarPrice from './steps/CarPrice/CarPrice';
import SelectCar from './steps/SelectCar/SelectCar';
import UserName from './steps/UserName/UserName';
import UserPhone from './steps/UserPhone/UserPhone';

const totalStep = 5;

export const exchangeFunnelList: FunnelListItem[] = [
  {
    key: 'user-phone',
    component: UserPhone,
    props: { currentStep: 1, totalStep } as Parameters<typeof UserPhone>[0],
    nextStepKey: 'otp',
  },
  {
    key: 'user-name',
    component: UserName,
    props: { currentStep: 2, totalStep } as Parameters<typeof UserName>[0],
    nextStepKey: 'select-car',
    prevStepKey: 'user-phone',
  },
  {
    key: 'select-car',
    component: SelectCar,
    props: { currentStep: 3, totalStep } as Parameters<typeof SelectCar>[0],
    nextStepKey: 'car-price',
    prevStepKey: 'user-name',
  },
  {
    key: 'car-price',
    component: CarPrice,
    props: { currentStep: 4, totalStep } as Parameters<typeof CarPrice>[0],
    nextStepKey: 'x',
    prevStepKey: 'select-car',
  },
  {
    key: 'determining-budget',
    component: DeterminingBudget,
    props: { currentStep: 5, totalStep } as Parameters<typeof CarPrice>[0],
    nextStepKey: 'x',
    prevStepKey: 'select-car',
  },
];

export const carStatus = [
  { id: 'intact', label: 'سالم و بی‌خط و خش' },
  { id: 'some-scratches', label: 'خط و خش جزیی' },
  { id: 'paintless-dent-removal', label: 'صافکاری بی‌رنگ' },
  { id: 'one-spot-paint', label: 'رنگ‌شدگی، در ۱ ناحیه' },
  { id: 'two-spot-paint', label: 'رنگ‌شدگی، در ۲ ناحیه' },
  { id: 'some-paint', label: 'رنگ‌شدگی، در چند ناحیه' },
  { id: 'half-paint', label: 'دوررنگ' },
  { id: 'full-paint', label: 'تمام‌رنگ' },
  { id: 'accidental', label: 'تصادفی' },
  { id: 'junk', label: 'اوراقی' },
];

export interface CarExchangeState {
  uuid?: string;
  car?: string;
  price?: number;
}

export const carExchangeInitialState: CarExchangeState = {
  uuid: '',
  car: '',
  price: 0,
};

export const CAR_EXCHANGE_KEY = 'car-exchange-state';
