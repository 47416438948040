import InstagramIcon from 'components/icons/footer/InstagramIcon';
import LinkedInIcon from 'components/icons/footer/LinkedInIcon';
import TelegramIcon from 'components/icons/footer/TelegramIcon';
import TwitterIcon from 'components/icons/footer/TwitterIcon';
import ENV from 'constants/environments';
import { FooterInterface, ISocialData } from 'interfaces/footer.interface';
import etehadiehLogo from '../../../public/assets/logos/etehadieh.svg';
import samandehiLogo from '../../../public/assets/logos/samandehi.svg';

interface StaticsInterface {
  signs: any;
  footerData: FooterInterface;
  socialData: ISocialData[];
}

const footerData: FooterInterface = {
  services: {
    heading: 'خدمات کارنامه',
    id: 3000,
    items: [
      {
        title: 'کارشناسی خودرو',
        id: 3001,
        link: '/car-inspection',
      },
      // {
      //   title: 'سرویس دوره‌ای',
      //   id: 3002,
      //   link: '/car-services',
      // },
      {
        title: 'دستیار فروش',
        id: 3003,
        link: '/car-sell',
      },
      {
        title: 'دستیار خرید',
        id: 3004,
        link: '/services/concierge-buy',
      },
      {
        title: 'قیمت خودروی صفر',
        id: 3005,
        link: '/car-price',
      },
      {
        title: 'قیمت خودروی کارکرده',
        id: 3006,
        link: `${ENV.LANDING_URL}/car-price/used-car`,
      },
      {
        title: 'آگهی‌های خودرو',
        id: 3007,
        link: '/buy-used-cars',
      },
    ],
  },
  contactUs: {
    heading: 'ارتباط با ما',
    id: 4000,
    items: [
      {
        title: 'راه‌های ارتباطی',
        id: 5001,
        link: `${ENV.LANDING_URL}/contact-us`,
      },
      {
        title: 'انتقادات و پیشنهادات',
        id: 5002,
        link: `${ENV.LANDING_URL}/contact-us#message-form`,
      },
      {
        title: 'ثبت شکایت',
        id: 5003,
        link: `${ENV.LANDING_URL}/guaranty#message-form`,
      },
    ],
  },
  guides: {
    heading: 'راهنما',
    id: 5000,
    items: [
      { title: 'بلاگ', id: 4001, link: `https://karnameh.com/blog/` },
      {
        title: 'فرصت‌های شغلی',
        id: 4002,
        link: 'https://careers.karnameh.com/',
      },
      {
        title: 'قوانین و مقررات',
        id: 4003,
        link: `${ENV.LANDING_URL}/car-inspection/terms`,
      },
      {
        title: 'درباره کارنامه',
        id: 4004,
        link: `${ENV.LANDING_URL}/about-us`,
      },
      { title: 'حریم خصوصی', id: 4005, link: `${ENV.LANDING_URL}/privacy` },
    ],
  },
};

const socialData: ISocialData[] = [
  {
    title: 'instagram',
    link: 'https://www.instagram.com/karnameh.official/?hl=en',
    icon: InstagramIcon,
  },
  {
    title: 'twitter',
    link: 'https://twitter.com/karnameh_com',
    icon: TwitterIcon,
  },

  {
    title: 'linkedIn',
    link: 'https://www.linkedin.com/company/karnameh/',
    icon: LinkedInIcon,
  },
  {
    title: 'telegram',
    link: 'https://t.me/karnameh_com',
    icon: TelegramIcon,
  },
];

export const statics: StaticsInterface = {
  signs: {
    enamad: {
      linkProps: {
        href: 'https://trustseal.enamad.ir/?id=158329&Code=57aebifkmsC3Fnj6isCd',
        target: '_blank',
        referrerPolicy: 'origin',
      },
      imageProps: {
        // Enamad forces us to add all these props,
        // don't remove/edit anyone of them
        src: 'https://Trustseal.eNamad.ir/logo.aspx?id=158329&Code=57aebifkmsC3Fnj6isCd',
        width: '55.68px',
        height: '48px',
        alt: '',
        id: '57aebifkmsC3Fnj6isCd',
        referrerPolicy: 'origin',
        style: {
          cursor: 'pointer',
          objectFit: 'contain',
        },
      },
    },
    samandehi: {
      link: 'https://logo.samandehi.ir/Verify.aspx?id=209170&p=uiwkobpdpfvlrfthjyoeobpd',
      image: {
        image: samandehiLogo,
        alt: 'karnameh logo samandehi',
      },
    },
    etehadieh: {
      link: 'https://ecunion.ir/verify/karnameh.com?token=66312125f79a7e2e7d4e',
      image: {
        image: etehadiehLogo,
        alt: 'karnameh logo ecunion',
      },
    },
  },
  footerData,
  socialData,
};
