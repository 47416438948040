import {
  Hydrate,
  QueryClientProvider,
  type DehydratedState,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { type FC, type PropsWithChildren } from 'react';
import createQueryClient from 'utils/createQueryClient';

interface Props {
  state?: DehydratedState;
}

export const queryClient = createQueryClient();

const AppQueryClientProvider: FC<PropsWithChildren<Props>> = ({
  children,
  state,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={state}>{children}</Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default AppQueryClientProvider;
