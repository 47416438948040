export const enNumToFa = (value: string) => {
  return value.replace(/[0-9]/g, (token) => {
    return String.fromCharCode(
      token.charCodeAt(0) + ('۰'.charCodeAt(0) - '0'.charCodeAt(0)),
    );
  });
};

export const faNumToEn = (value: string) => {
  return value.replace(/[۰-۹]/g, (token) => {
    return String.fromCharCode(
      token.charCodeAt(0) - ('۰'.charCodeAt(0) - '0'.charCodeAt(0)),
    );
  });
};
