import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FunnelData } from 'components/CompanionInspectorFunnel/COIFHooks';

export const initialIFState: FunnelData = {
  stepList: [],
  stepFooterList: [],
  currentStep: null,
  changeStepFn: () => {},
};

const { reducer, actions } = createSlice({
  name: 'COIFunnel',
  initialState: initialIFState,
  reducers: {
    setFunnelData: (state, action: PayloadAction<FunnelData>) => {
      state.stepList = action.payload.stepList;
      state.stepFooterList = action.payload.stepFooterList;
      state.currentStep = action.payload.currentStep;
      state.changeStepFn = action.payload.changeStepFn;
    },
  },
});

export const { setFunnelData } = actions;

export default reducer;
