import { Collapse, Stack, Typography, useTheme } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CircleIcon from '@mui/icons-material/Circle';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import {
  TierType,
  TierTypeDTO,
} from '../../../../services/rest/car-services/car-services';

import { BundleHeader } from 'containers/car-services-order/components/BundleItem/BundleHeader';
import { BundlePriceItem } from 'containers/car-services-order/components/BundleItem/BundlePriceItem';
import { basicCheckupId } from 'containers/car-services-order/configs';

export type Props = {
  tier: TierTypeDTO;
  discount?: number;
  tierType: TierType;
  onTierChanged(tier: TierType): void;
  selectedTier: TierType | undefined;
};

type PartType = {
  name: string;
  slug: string;
  part_type?: string;
  market_price: number;
};
const BundleItem = ({ tier, tierType, onTierChanged, selectedTier }: Props) => {
  const [partsExpanded, setPartsExpanded] = useState<boolean>(false);
  const theme = useTheme();
  const [parts, setParts] = useState<PartType[]>([]);
  useEffect(() => {
    setParts([]);
    const p: PartType[] = [];
    tier.services.forEach((service) => {
      service.parts.forEach((part) => {
        p.push({
          name: part.name,
          slug: part.part_type,
          part_type: part.part_type,
          market_price: part.market_price,
        });
      });
    });
    setParts(p);
  }, [tier]);

  const sortParts = (a: PartType, b: PartType): number => {
    if (a.part_type === 'روغن موتور') {
      return -1;
    } else if (b.part_type === 'روغن موتور') {
      return 1;
    }
    return a.name.localeCompare(b.name); // Fallback to default string comparison
  };
  const totalPrice =
    parts.reduce((acc, item) => acc + item.market_price, 0) +
    tier.services_total_price;
  const bundleDiscount = Math.abs(
    ((tier.total_price - totalPrice) / totalPrice) * 100,
  );
  return (
    <Stack
      sx={{
        border: `1px solid ${
          selectedTier === tierType
            ? theme.palette.secondary.main
            : theme.palette.surface.paper
        }`,
        width: '100%',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        backgroundColor: 'white',
        padding: '0.75rem',
      }}
    >
      <BundleHeader
        onTierChanged={onTierChanged}
        totalPrice={totalPrice}
        price={tier.total_price}
        discount={bundleDiscount}
        tierItem={tierType}
        selectedTier={selectedTier}
      />
      <Divider sx={{ margin: '0.5rem 0 1rem' }} />
      <Collapse in={partsExpanded} collapsedSize="80px">
        {parts.sort(sortParts).map((part, index) => {
          return (
            <BundlePriceItem
              key={`${part.slug}-${index}`}
              brandName={part.name}
              price={part.market_price}
            />
          );
        })}
        <Stack flexDirection="column">
          <Stack flexDirection="row">
            <CircleIcon
              sx={{
                width: '0.375rem',
                height: '0.375rem',
                marginTop: '8px',
                color: theme.palette.info.main,
              }}
            />
            <Typography
              sx={{
                color: theme.palette.info.main,
                marginX: '0.75rem',
                fontSize: '0.875rem',
                fontWeight: '400',
                lineHeight: '1.5rem',
              }}
            >
              {tier.services.reduce(
                (acc, item, index) =>
                  acc +
                  `${index !== 0 ? ' + ' : ''} ${
                    item.id !== basicCheckupId ? 'اجرت' : ''
                  } ${
                    item.id === basicCheckupId
                      ? `${item.name} رایگان`
                      : item.name
                  }`,
                '',
              )}
            </Typography>
          </Stack>
          <Stack
            gap={0.5}
            flexDirection="row"
            justifyContent="end"
            alignItems="center"
          >
            <Typography
              fontSize="0.875rem"
              fontWeight="600"
              lineHeight="1.75rem"
            >
              {tier.services_total_price.toLocaleString()}
            </Typography>
            <Typography
              fontSize="0.675rem"
              fontWeight="400"
              lineHeight="1.25rem"
            >
              تومان
            </Typography>
          </Stack>
          <Divider sx={{ marginY: '1rem' }} />
        </Stack>
      </Collapse>
      <Stack
        justifyContent="center"
        width="100%"
        onClick={() => {
          setPartsExpanded(!partsExpanded);
        }}
        sx={{
          cursor: 'pointer',
          color: theme.palette.secondary.main,
        }}
        flexDirection="row"
        alignItems="center"
      >
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          {partsExpanded ? 'بستن همه موارد' : 'مشاهده همه موارد'}
        </Typography>
        {partsExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Stack>
    </Stack>
  );
};
export default BundleItem;
