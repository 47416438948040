import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import styles from 'components/CarSearchTextFeild/styles';
import useLogic from 'components/CarSearchTextFeild/useLogic';
import { FC } from 'react';
import { Car, FindCarsServiceNames } from 'services/rest/car';
import { VehicleType } from 'states/pages-slices/carInspectionSlice';

interface PropTypes {
  initialValue?: Car | null;
  onChange: (value: Car | null) => void;
  serviceName?: FindCarsServiceNames;
  size?: 'medium' | 'small';
  isDisabled?: boolean;
  error?: string | undefined;
  inputTextValue?: string | null;
  vehicleType?: VehicleType;
  value?: Car | null;
  secondary?: boolean;
}

const CarSearchTextFeild: FC<PropTypes> = (props) => {
  const {
    initialValue = null,
    onChange,
    serviceName,
    size = 'medium',
    isDisabled = false,
    error = undefined,
    inputTextValue,
    vehicleType,
    value = null,
    secondary,
  } = props;

  const {
    car,
    setCar,
    inputValue,
    setInputValue,
    inputFocus,
    setInputFocus,
    autoCompleteRef,
    fetchCarData,
    isFetchCarLoading,
    isFindCarLoading,
    autoCompleteOptions,
  } = useLogic(
    initialValue,
    serviceName,
    inputTextValue,
    vehicleType,
    undefined,
    value,
    true,
  );

  return (
    <Autocomplete
      size={size}
      ref={autoCompleteRef}
      open={inputFocus}
      disabled={!fetchCarData || isDisabled}
      disablePortal
      onOpen={() => setInputFocus(true)}
      loading={isFindCarLoading || isFetchCarLoading}
      loadingText={
        <Stack sx={styles.loading}>
          <CircularProgress size={15} sx={styles.circularProgress} />
          <Typography sx={styles.searchText}>در حال جستجو</Typography>
        </Stack>
      }
      id="combo-search-car"
      onFocus={() => setInputFocus(true)}
      noOptionsText={
        <Stack sx={styles.notFound}>
          {inputValue.length < 2 ? 'حداقل دو حرف وارد کنید' : 'موردی یافت نشد'}
        </Stack>
      }
      onBlur={() => {
        setInputFocus(false);
        if (car === null) {
          setInputValue('');
        }
      }}
      sx={styles.input}
      options={autoCompleteOptions}
      getOptionLabel={(option) => option.name}
      filterOptions={(options) => {
        return options;
      }}
      isOptionEqualToValue={(option, itemValue) =>
        option.name_en === itemValue.name_en &&
        option.last_level === itemValue.last_level &&
        option.last_level_id === itemValue.last_level_id
      }
      value={car}
      onChange={(_, newValue) => {
        if (newValue === null && car !== null) {
          setCar(null);
          onChange(null);
        } else if (
          newValue?.last_level === 'type' ||
          newValue?.last_level === 'model'
        ) {
          setCar(() => {
            return (
              autoCompleteOptions.find((option) => {
                return (
                  option.name_en === newValue.name_en &&
                  option.last_level === newValue.last_level &&
                  option.last_level_id === newValue.last_level_id
                );
              }) || null
            );
          });
          onChange(newValue);
          setInputFocus(false);
          if (autoCompleteRef.current) {
            autoCompleteRef.current.blur();
          }
        }
        setInputValue(newValue?.name ?? '');
      }}
      onSelect={(e) => {
        setInputValue((e.target as HTMLInputElement).value);
      }}
      renderInput={({
        inputProps: { value: otherValue, ...otherInputProps },
        ...params
      }) => (
        <TextField
          error={!!error}
          helperText={error}
          color={secondary ? 'secondary' : 'primary'}
          {...params}
          label="برند - مدل - تیپ"
          value={inputValue}
          inputProps={{ ...otherInputProps }}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
      )}
    />
  );
};

export default CarSearchTextFeild;
