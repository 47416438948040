import { Stack } from '@mui/material';
import useAppSelector from 'hooks/useAppSelector';
import Image from 'next/image';
import { FC } from 'react';
import styles from './styles';

interface PropTypes {
  otpEnteredLength: number;
}

const Motions: FC<PropTypes> = (props) => {
  const { otpEnteredLength } = props;
  const modalState = useAppSelector((store) => store.auth.state);

  return (
    <Stack
      sx={{
        ...styles.imageItemContainer,
        transform: `scale(${
          modalState === 'otp' || modalState === 'just-otp'
            ? (otpEnteredLength + 1) * 0.5 + 1
            : 1
        })`,
        opacity: 1,
      }}
    >
      <Image
        src="/assets/images/login/road.svg"
        layout="fill"
        alt="otp"
        objectFit="cover"
      />
    </Stack>
  );
};

export default Motions;
