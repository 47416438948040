import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProviderInterface,
  LoanDataInterface,
} from 'interfaces/loan.interface';
import { getUserPhone } from 'utils/auth';

export interface LoanState {
  provider?: ProviderInterface;
  loanData: LoanDataInterface;
  currentStepId: string;
}

export const initialAuthPageState: LoanState = {
  loanData: {
    phoneNumber: getUserPhone(),
    nationalCode: '',
    fullName: '',
    city: '',
    amount: 120,
    installmentCount: '6',
    installmentPeriod: '',
  },
  currentStepId: '0',
};

const { reducer, actions } = createSlice({
  name: 'loanRequest',
  initialState: initialAuthPageState,
  reducers: {
    setProvider: (state, action: PayloadAction<LoanState['provider']>) => {
      state.provider = action.payload;
    },
    setLoanData: (state, action: PayloadAction<LoanState['loanData']>) => {
      state.loanData = { ...state.loanData, ...action.payload };
    },
    setCurrentStepId: (
      state,
      action: PayloadAction<LoanState['currentStepId']>,
    ) => {
      state.currentStepId = action.payload;
    },
  },
});

export const { setProvider, setLoanData, setCurrentStepId } = actions;

export default reducer;
