import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ViolationItemStateType = {
  itemToCheckout: number[];
  allItem: number[];
  filter: 'all' | 'paid' | 'unpaid';
  checkAll: boolean;
};

const initialItemToCheckoutState: ViolationItemStateType = {
  itemToCheckout: [],
  allItem: [],
  filter: 'all',
  checkAll: false,
};

const { actions, reducer } = createSlice({
  name: 'violationItemToChekout',
  initialState: initialItemToCheckoutState,
  reducers: {
    itemToCheckoutHandler: (state, action: PayloadAction<number>) => {
      if (state.itemToCheckout.find((item) => item === action.payload)) {
        state.itemToCheckout = state.itemToCheckout.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.itemToCheckout.push(action.payload);
      }

      state.checkAll = state.allItem.every((item) =>
        state.itemToCheckout.includes(item),
      );
    },
    itemToCheckoutBulkHandler: (state, action: PayloadAction<number[]>) => {
      state.itemToCheckout = [...action.payload];
    },
    checkedAllHandler: (state, action: PayloadAction<boolean>) => {
      state.checkAll = action.payload;
      state.itemToCheckout = action.payload ? [...state.allItem] : [];
    },
    changeViolationItemFilter: (
      state,
      action: PayloadAction<ViolationItemStateType['filter']>,
    ) => {
      if (state.filter !== action.payload) {
        state.filter = action.payload;
        state.itemToCheckout = [];
        state.checkAll = false;
      }
    },
    itemToCheckoutInit: (state, action: PayloadAction<number[]>) => {
      state.itemToCheckout = [...action.payload];
      state.allItem = [...action.payload];
      state.checkAll = true;
    },
  },
});

export const {
  itemToCheckoutHandler,
  itemToCheckoutBulkHandler,
  itemToCheckoutInit,
  changeViolationItemFilter,
  checkedAllHandler,
} = actions;
export { reducer as violationItemReducer };
