import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'states/components-slices/authSlice';
import COIFReducer from 'states/components-slices/companioninspectorFunnelSlice';
import IFReducer from 'states/components-slices/inspectionFunnelSlice';
import loanReducer from 'states/components-slices/loanSlice';
import stickyNotifReducer from 'states/components-slices/stickySlice';
import globalReducer from 'states/globalSlice';
import affidavitReducer from 'states/pages-slices/affidavitSlice';
import carInspectionReducer from 'states/pages-slices/carInspectionSlice';
import quickSellReducer from 'states/pages-slices/carQuickSellSlice';
import companionInspectorReducer from 'states/pages-slices/companionInspectorSlice';
import concierageBuyReducer from 'states/pages-slices/concierageBuySlice';
import divarInspectionReducer from 'states/pages-slices/divarInspectionSlice';
import leasingCalculatorReducer from 'states/pages-slices/leasingCalculatorSlice';
import leasingDocumentsReducer from 'states/pages-slices/leasingDocumentsSlice';
import leasingValidationReducer from 'states/pages-slices/leasingValidationSlice';
import { plpReducer } from 'states/pages-slices/plpSlice';
import { violationItemReducer } from 'states/pages-slices/violationItemSlice';

const store = configureStore({
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
  reducer: {
    global: globalReducer,
    loan: loanReducer,
    auth: authReducer,
    inspectionFunnel: IFReducer,
    concierageBuy: concierageBuyReducer,
    carInspection: carInspectionReducer,
    divarInspection: divarInspectionReducer,
    leasingValidation: leasingValidationReducer,
    leasingDocuments: leasingDocumentsReducer,
    plp: plpReducer,
    affidavit: affidavitReducer,
    leasingCalculator: leasingCalculatorReducer,
    quickSell: quickSellReducer,
    COIFunnel: COIFReducer,
    companionInspector: companionInspectorReducer,
    violationItem: violationItemReducer,
    stickyNotif: stickyNotifReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
