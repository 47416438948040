import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { FormControl, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import { FC, useEffect, useMemo } from 'react';

import Layout from 'containers/car-services-order/components/layout';
import useFunnelContext from 'hooks/useFunnelContext';
import { useUpdateName } from 'services/rest/car-exchange';
import { getUserDataFromToken, getUserPhone } from 'utils/auth';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const UserName: FC<PropTypes> = (props) => {
  const { currentStep, totalStep } = props;
  const funnelData = useFunnelContext();
  const updateUserInfo = useUpdateName();
  const userData = getUserDataFromToken();
  const phoneNumber = getUserPhone();

  const formik = useFormik({
    initialValues: {
      name: `${userData?.first_name ?? ''} ${userData?.last_name ?? ''}`,
    },
    onSubmit: (newValues) => {
      updateUserInfo.mutate(
        {
          first_name: newValues.name.split(' ')[0],
          last_name: newValues.name.split(' ').slice(1).join(' '),
        },
        {
          onSuccess: () => {
            funnelData?.changeStep('select-car');
          },
        },
      );
    },
  });

  const isValid = useMemo(() => {
    const { values } = formik;

    return /^.{1,}$/.test(values.name);
  }, [formik]);

  useEffect(() => {
    let name = `${userData?.first_name ?? ''} ${userData?.last_name ?? ''}`;
    if (name === ' ') name = '';

    formik.setFieldValue('name', name);
  }, []);

  return (
    <Layout
      header={{
        title: 'مشخصات',
        totalPage: totalStep,
        currentPage: currentStep,
      }}
      extraScroll={false}
      isReactiveToResize={true}
    >
      <Stack sx={{ height: '100%', width: '100%', position: 'relative' }}>
        <Typography
          fontSize="15px"
          fontWeight={600}
          color={(th) => th.palette.common.black}
        >
          نام و نام خانوادگی خود را وارد کنید.
        </Typography>

        <Stack
          component="div"
          sx={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '24px',
          }}
        >
          <FormControl
            sx={{
              flexGrow: 1,
            }}
          >
            <TextField
              label="نام و نام‌خانوادگی"
              type="text"
              size="small"
              color="secondary"
              InputProps={{
                startAdornment: (
                  <PersonRoundedIcon
                    sx={{
                      color: '#6E6E6E',
                      marginRight: 1,
                    }}
                  />
                ),
              }}
              placeholder="بنویسید ..."
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              sx={{ width: '100%' }}
              autoComplete="off"
            />
          </FormControl>
        </Stack>
        <Button
          id={'traceable'}
          _traceservicename={'car-exchange'}
          _traceeventname={'car-exchange-user-name-submit'}
          _traceeventtype={'submit'}
          _traceeventmetadata={JSON.stringify({
            userName: formik.values.name,
            phoneNumber,
          })}
          disabled={!isValid}
          onClick={formik.submitForm}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            p: 0,
            width: { xs: '100%', md: 'auto' },
            minWidth: { md: '12.5rem' },
            height: '2.5rem',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          تایید و ادامه
        </Button>
      </Stack>
    </Layout>
  );
};

export default UserName;
