import { faNumToEn } from 'utils/en-fa';

type SearchInArray = <T>(
  items: T[],
  searchValue: string,
  getSearchableItems: (item: T) => string[],
) => T[];

export const searchInArray: SearchInArray = (
  items,
  searchValue,
  getSearchableItems,
) => {
  const mainSearchValue = faNumToEn(searchValue.replace(/(‌| )/g, ''));

  const regExpression = `^.*${Array.from(mainSearchValue).join('.*')}.*$`;

  const result = items.filter((item) => {
    const searchableItems = getSearchableItems(item).map((i) => {
      return faNumToEn(i.replace(' ', '').replaceAll('‌', ''));
    });
    let isMatch = false;
    searchableItems.forEach((searchableItem) => {
      try {
        if (RegExp(regExpression, 'i').test(searchableItem)) {
          isMatch = true;
        }
      } catch (_) {
        console.log('invalid searchable char entered!');
      }
    });
    return isMatch;
  });

  const sortedResult = result.sort((a, b) => {
    const searchableItemsOfA = getSearchableItems(a).map((i) => {
      return faNumToEn(i.replace(' ', '').replaceAll('‌', ''));
    });

    const searchableItemsOfB = getSearchableItems(b).map((i) => {
      return faNumToEn(i.replace(' ', '').replaceAll('‌', ''));
    });

    if (
      searchableItemsOfA.some((i) => i.includes(mainSearchValue)) &&
      !searchableItemsOfB.some((i) => i.includes(mainSearchValue))
    ) {
      return -1;
    } else if (
      !searchableItemsOfA.some((i) => i.includes(mainSearchValue)) &&
      searchableItemsOfB.some((i) => i.includes(mainSearchValue))
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  return sortedResult;
};
