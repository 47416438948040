import createStyle from 'utils/createStyle';

const styles = createStyle({
  container: {
    width: '100%',
    height: '4.25rem',
    display: { xs: 'flex', md: 'none' },
    zIndex: '5',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'sticky',
    bottom: '0',
    backgroundColor: (theme) => theme.palette.surface.default,
    boxShadow: '0 -1px 2px 0 rgba(0,0,0,.12)',
    '@media print': {
      display: 'none',
    },
  },
  linkItem: {
    flexGrow: 1,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexBasis: '100%',
    cursor: 'pointer',
    minHeight: '56px',
    textDecoration: 'none',
    height: '100%',
    padding: '4px 2px',
  },
  icon: {
    width: '25px',
    height: '25px',
    marginBottom: '4px',
    transition: 'color 0.3s',
  },
});

export default styles;
