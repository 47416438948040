import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LeasingDocumentsPageState {
  loginPhoneNumber: string;
}

const initialState: LeasingDocumentsPageState = {
  loginPhoneNumber: '',
};

const { actions, reducer } = createSlice({
  name: 'leasing-validation',
  initialState: initialState,
  reducers: {
    setLoginPhoneNumber: (
      state,
      action: PayloadAction<LeasingDocumentsPageState['loginPhoneNumber']>,
    ) => {
      state.loginPhoneNumber = action.payload;
    },
  },
});

export const { setLoginPhoneNumber: setLeasingDocumentsLoginPhoneNumber } =
  actions;

export default reducer;
