/**
 * @returns step by step names of a car in English and Persian.
 */
export const getCarCompleteName = (configs: {
  brandEn?: string | null;
  brandFa?: string | null;
  modelEn?: string | null;
  modelFa?: string | null;
  typeEn?: string | null;
  typeFa?: string | null;
}) => {
  const brandEn = configs.brandEn ? configs.brandEn : '';
  const brandModelEn = configs.modelEn
    ? `${brandEn} ${configs.modelEn}`
    : brandEn;
  const brandModelTypeEn = configs.typeEn
    ? `${brandModelEn} ${configs.typeEn}`
    : brandModelEn;

  const brandFa = configs.brandFa ? configs.brandFa : '';
  const brandModelFa = configs.modelFa
    ? `${brandFa} ${configs.modelFa}`
    : brandFa;
  const brandModelTypeFa = configs.typeFa
    ? `${brandModelFa} ${configs.typeFa}`
    : brandModelFa;

  return {
    brandEn,
    brandFa,
    brandModelEn,
    brandModelFa,
    brandModelTypeEn,
    brandModelTypeFa,
    nameEn: brandModelTypeEn,
    nameFa: brandModelTypeFa,
  };
};

/**
 * detects the level of a car based on "brand", "model" and "type".
 */
const getCarLevel = (config: {
  brandEn?: string | null;
  modelEn?: string | null;
  typeEn?: string | null;
}): GroupedCarsLevel => {
  if (config.brandEn) {
    if (config.modelEn) {
      if (config.typeEn) {
        return 'type';
      }
      return 'model';
    }
    return 'brand';
  }
  return 'type';
};

// converts a car Object ot a GroupedCar object.
export const convertCarToGroupedCar = (car: Car): GroupedCarsItem => {
  const {
    brand_en: brandEn,
    brand_fa: brandFa,
    model_en: modelEn,
    model_fa: modelFa,
    type_en: typeEn,
    type_fa: typeFa,
  } = car;

  const level = getCarLevel({ brandEn, modelEn, typeEn });

  const names = getCarCompleteName({
    brandEn,
    brandFa,
    modelEn,
    modelFa,
    typeEn,
    typeFa,
  });

  return {
    brandEn,
    brandFa,
    modelEn,
    modelFa,
    typeEn,
    typeFa,
    level,
    list: null,
    names,
    isCar: true,
    cars: null,
  };
};

export const isGroupedCarsItem = (
  item: Car | GroupedCarsItem,
): item is GroupedCarsItem => {
  return 'names' in item;
};

/**
 * makes a hierarchy grouped car recursively. from "brand" level to "type"
 */
export const groupCars = (cars: Cars | GroupedCars): GroupedCars => {
  const groupingLevels: GroupedCarsLevel[] = ['brand', 'model', 'type'];

  // converting all of the cars to a list of GroupedCarsItem Object
  const initialGroupedCars: GroupedCars = cars.map((car) => {
    if (isGroupedCarsItem(car)) {
      return car;
    }
    return convertCarToGroupedCar(car);
  });

  /**
   * this function gets a list of GroupedCarsItem Objects,
   *  then groups them by one level based on the passed level,
   *  after all repeats this process recurcivly until "type" level.
   */
  return (function startGrouping(
    groupList: GroupedCars,
    startLevel: GroupedCarsLevel,
  ) {
    const nextLevel = groupingLevels[groupingLevels.indexOf(startLevel) + 1];

    const groupedData = groupList.reduce<GroupedCars>((result, groupedCar) => {
      let compareKey: keyof CarNames = 'brandModelTypeEn';

      switch (startLevel) {
        case 'brand':
          compareKey = 'brandEn';
          break;
        case 'model':
          compareKey = 'brandModelEn';
          break;
        case 'type':
          compareKey = 'brandModelTypeEn';
          break;
      }

      const findedResultItemIndex = result.findIndex((resultItem) => {
        if (resultItem.names[compareKey] === groupedCar.names[compareKey]) {
          return true;
        } else {
          return false;
        }
      });

      function makeNewGroupedObj(): GroupedCarsItem {
        const indexLevel = groupingLevels.indexOf(startLevel);
        const newGroupedObjBrandModelTypeValues = {
          brandEn: groupedCar.brandEn,
          brandFa: groupedCar.brandFa,
          modelEn:
            indexLevel >= groupingLevels.indexOf('model') && groupedCar.modelEn
              ? groupedCar.modelEn
              : null,
          modelFa:
            indexLevel >= groupingLevels.indexOf('model') && groupedCar.modelFa
              ? groupedCar.modelFa
              : null,
          typeEn:
            indexLevel >= groupingLevels.indexOf('type') && groupedCar.typeEn
              ? groupedCar.typeEn
              : null,
          typeFa:
            indexLevel >= groupingLevels.indexOf('type') && groupedCar.typeFa
              ? groupedCar.typeFa
              : null,
        };

        return {
          level: startLevel,
          list: groupedCar.level === startLevel ? null : [groupedCar],
          names: getCarCompleteName({ ...newGroupedObjBrandModelTypeValues }),
          ...newGroupedObjBrandModelTypeValues,
          cars: groupedCar.level === startLevel ? null : [groupedCar],
          isCar: groupedCar.level === startLevel,
        };
      }

      if (findedResultItemIndex !== -1) {
        const findedResultItem = result[findedResultItemIndex];
        if (Array.isArray(findedResultItem.list)) {
          findedResultItem.list.push(groupedCar);
        } else {
          findedResultItem.list = [groupedCar];
        }
        if (Array.isArray(findedResultItem.cars)) {
          findedResultItem.cars.push(groupedCar);
        }
      } else {
        result.push(makeNewGroupedObj());
      }

      return result;
    }, []);

    // grouping sub lists of groupedData if possible.
    if (typeof nextLevel !== 'undefined') {
      groupedData.forEach((resultItem, resultItemIndex) => {
        if (Array.isArray(resultItem.list) && resultItem.list.length) {
          const newList = startGrouping(resultItem.list, nextLevel);
          groupedData[resultItemIndex].list = newList;
        }
      });
    }

    groupedData.forEach((groupedDataItem, groupedDataItemIndex) => {
      if (
        Array.isArray(groupedDataItem.cars) &&
        groupedDataItem.cars.length === 1
      ) {
        groupedData[groupedDataItemIndex] = groupedDataItem.cars[0];
      }
    });

    return groupedData;
  })(initialGroupedCars, 'brand');
};

export const searchGroupedCarsInGroupedCarsWithNameEn = (
  groupedCars: GroupedCars,
  nameEn: string,
): GroupedCarsItem | null => {
  for (let groupedCarItem of groupedCars) {
    if (
      groupedCarItem.names.nameEn.toLowerCase().includes(nameEn.toLowerCase())
    ) {
      return groupedCarItem;
    } else if (
      Array.isArray(groupedCarItem.list) &&
      groupedCarItem.list.length
    ) {
      const subResult = searchGroupedCarsInGroupedCarsWithNameEn(
        groupedCarItem.list,
        nameEn,
      );
      if (subResult) {
        return subResult;
      }
    }
  }
  return null;
};
