import styled from '@emotion/styled';
import { Link } from '@mui/material';
import theme from 'theme';
import createStyle from 'utils/createStyle';

const styles = createStyle({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '64px',
    position: 'fixed',
    top: 0,
    paddingX: '1.5rem',
    paddingY: '1.31rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 1px 2px 0 rgb(0 0 0 / 12%)',
    maxWidth: 'unset !important',
    zIndex: 5,
    [theme.breakpoints.up('lg')]: {
      paddingX: 'calc(((100vw - 89rem)/2) + 24px)',
    },
    [theme.breakpoints.up('md')]: {
      paddingX: '24px',
    },
    '@media print': {
      display: 'none',
    },
  },
  headerLogoWrapper: {
    display: 'inline-flex',
    marginRight: '24px',
    paddingRight: '24px',
    height: '36px',
    minHeight: '36px',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      minWidth: '99px',
      height: '24px',
      minHeight: '24px',
    },
  },
  menuWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  actionButtonWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'rgba(0,0,0,.56)',
    '& button': {
      cursor: 'pointer',
      ':hover': {
        backgroundColor: 'rgba(0,0,0,.04) !important',
      },
    },
  },
  actionButton: {
    color: 'rgba(0,0,0,.56)',
    padding: '0 8px',
    '& .MuiButton-startIcon': {
      marginX: '0',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '8px',
      padding: '0px',
      width: '40px',
      minWidth: 'unset',
      height: '24px',
    },
  },
  actionButtonText: {
    fontSize: '.75rem',
    fontWeight: '600',
    lineHeight: '2',
    color: 'rgba(0,0,0,.56)',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  buttonIcons: {
    width: '24px',
    height: '24px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 1.5,
    },
  },
});

export const MenuLink = styled(Link)`
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.56);
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 36px;
  min-width: unset !important;

  span {
    margin-left: 8px;
  }
  &:hover {
    color: black;
  }
`;
export default styles;
