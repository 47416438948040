import createStyle from 'utils/createStyle';

const styles = createStyle({
  container: {
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  phoneInput: {
    width: '100%',
    mt: '1rem',
  },
  specer: {
    flexGrow: 1,
    display: { xs: 'none', md: 'flex' },
  },
  submitContainer: {
    width: '100%',
    px: { xs: '1rem', md: '2rem' },
    pb: '1.5rem',
    borderTop: {
      xs: (th) => `1px solid ${th.palette.surface.paper}`,
      md: 'none',
    },
    mt: { xs: '2rem', md: 'none' },
  },
  submitButton: {
    width: '100%',
    alignSelf: 'center',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    px: '1rem',
    py: { xs: '1rem', md: '1.5rem' },
    position: 'relative',
    borderBottom: (th) => ({
      xs: `1px solid ${th.palette.surface.paper}`,
      md: 'unset',
    }),
  },
  backButton: {
    position: 'absolute',
    left: { xs: '1rem', md: '2rem' },
  },
  contents: {
    width: '100%',
    px: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  inputAndormanet: {
    width: '18px',
    height: '18px',
    mr: '0.75rem',
    color: (th) => th.palette.iconDefault.main,
  },
});

export default styles;
