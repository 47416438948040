import { Divider, Drawer, Stack, Typography } from '@mui/material';
import theme from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import { useGetOrderPayments } from 'services/rest/car-services';
import { RowItem } from 'containers/car-services-order/steps/FinalConfirmation';

type Props = {
  isOpen: boolean;
  onClose(): void;
  orderId: string;
};

const PartPriceItemsBS = ({ isOpen, onClose, orderId }: Props) => {
  const { data: payments } = useGetOrderPayments(orderId);
  if (!payments) return <></>;
  return (
    <Drawer
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: '28px',
          borderTopRightRadius: '28px',
          backgroundColor: 'white',
          height: 'fit-content',
          maxHeight: '80%',
          minHeight: '20%',
          overflow: 'hidden',
        },
      }}
      keepMounted={false}
      anchor={'bottom'}
      open={isOpen}
      onClose={onClose}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack
          sx={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingY: '26px',
            paddingX: '16px',
            borderBottom: `1px solid ${theme.palette.surface.paper}`,
          }}
        >
          <CloseIcon onClick={onClose} />
          <Typography
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            fontWeight="600"
            fontSize="1rem"
            height="26px"
          >
            جزییات قیمت سرویس
          </Typography>
        </Stack>
        <Stack
          sx={{
            marginTop: '1.13rem',
            px: '1.5rem',
            mb: '0.5rem',
            maxHeight: '80vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: theme.palette.primary.main,
              borderRadius: '4px',
            },
          }}
        >
          <Typography
            fontSize="1rem"
            fontWeight="600"
            lineHeight="2rem"
            color="#202021"
            sx={{
              marginBottom: '1rem',
            }}
          >
            خدمات
          </Typography>
          {payments &&
            payments[0] &&
            payments[0].payment_items
              .filter(
                (item) =>
                  item.item_type === 'service' &&
                  item.service_price.slug !== 'ایاب-و-ذهاب',
              )
              .map((item) => {
                return (
                  <RowItem
                    key={item.id}
                    title={item.title}
                    value={`${item.price.toLocaleString()} تومان`}
                    isBlue={false}
                  />
                );
              })}
          <Divider sx={{ marginBottom: '1rem ', marginTop: '0.5rem' }} />
          <Typography
            fontSize="1rem"
            fontWeight="600"
            lineHeight="2rem"
            color="#202021"
            sx={{
              marginBottom: '1rem',
            }}
          >
            قطعات
          </Typography>
          {payments &&
            payments[0] &&
            payments[0].payment_items
              .filter((item) => item.item_type === 'part')
              .map((item) => {
                return (
                  <RowItem
                    key={item.id}
                    title={item.title}
                    value={`${item.price.toLocaleString()} تومان`}
                    isBlue={false}
                  />
                );
              })}
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default PartPriceItemsBS;
