import { apiClient } from 'services/clients';
import { api2Endpoints, endPointUrlNormalizer } from 'services/configs';

export interface BannerContents {
  id: number;
  name: string;
  desktop_url: string;
  mobile_url: string;
  redirect_url: string;
}

export interface HeaderContents {
  id: number;
  name: string;
  title: string;
  description: string;
  button_title: string;
  button_url: string;
}

export const getBanner = (bannerKey: string) => {
  return apiClient<BannerContents | null>({
    method: 'GET',
    url: endPointUrlNormalizer(api2Endpoints.GET_BANNER, {
      bannerKey: bannerKey,
    }),
  })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return Promise.resolve(null);
    });
};

export const getHeader = (headerKey: string) => {
  return apiClient<HeaderContents | null>({
    method: 'GET',
    url: endPointUrlNormalizer(api2Endpoints.GET_HEADER, {
      headerKey: headerKey,
    }),
  })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return Promise.resolve(null);
    });
};
