import { apiClient } from '../../clients';
import {
  CarProfileCreationDTO,
  CarProfileCreationParam,
  CarProfileDto,
  CreateLeadDTO,
  CreateLeadParam,
  PriceEstimationParams,
  PriceEstimatorDTO,
  UpdateBodyStatusParam,
  UpdateLeadParam,
  UpdateMileageParam,
  UpdateNameParam,
} from './carExchange';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  endPointUrlNormalizer,
  myCarEndpoints,
  switchEndpoints,
} from 'services/configs';

export const getCarProfiles = () => {
  return apiClient<CarProfileDto[]>({
    method: 'GET',
    url: myCarEndpoints.CAR_PROFILES,
  });
};

export const getCarProfilesKey = () => {
  return ['my-cars'];
};

export const useGetCarProfiles = (
  options?: Omit<
    UseQueryOptions<
      CarProfileDto[],
      unknown,
      CarProfileDto[],
      ReturnType<typeof getCarProfilesKey>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = getCarProfilesKey();
  return useQuery({
    ...options,
    queryFn: () => {
      return getCarProfiles().then((res) => res.data);
    },
    queryKey,
  });
};

export const getPriceEstimation = (params: PriceEstimationParams) => {
  return apiClient<PriceEstimatorDTO>({
    method: 'POST',
    params: {
      approximate: true,
    },
    url: endPointUrlNormalizer(myCarEndpoints.PRICE_ESTIMATION, {
      profileID: params.id,
    }),
  });
};

export const getPriceEstimationKey = (params: PriceEstimationParams) => {
  return [`my-cars-${params.id}-estimate`];
};

export const usePriceEstimation = (
  params: PriceEstimationParams,
  options?: Omit<
    UseQueryOptions<
      PriceEstimatorDTO,
      AxiosError<unknown>,
      PriceEstimatorDTO,
      ReturnType<typeof getPriceEstimationKey>
    >,
    'queryFn' | 'queryKey'
  >,
) => {
  const queryKey = getPriceEstimationKey(params);
  return useQuery({
    ...options,
    queryKey,
    queryFn: () => {
      return getPriceEstimation(params).then((res) => res.data);
    },
  });
};

export const createCarProfile = (params: CarProfileCreationParam) => {
  return apiClient<CarProfileCreationDTO>({
    method: 'POST',
    url: myCarEndpoints.CAR_PROFILES,
    data: params,
  });
};

export const createCarProfileKey = ['createCarProfile'];

export const useCreateCarProfile = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof createCarProfile>>,
      unknown,
      CarProfileCreationParam,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: createCarProfileKey,
    mutationFn: createCarProfile,
  });
};

export const createLead = (params: CreateLeadParam) => {
  return apiClient<CreateLeadDTO>({
    method: 'POST',
    url: myCarEndpoints.CREATE_LEAD,
    data: params,
  });
};

export const createLeadKey = ['createLead'];

export const useCreateLead = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof createLead>>,
      unknown,
      CreateLeadParam,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: createLeadKey,
    mutationFn: createLead,
  });
};

export const updateLead = (params: UpdateLeadParam) => {
  return apiClient<CreateLeadDTO>({
    method: 'PATCH',
    url: endPointUrlNormalizer(myCarEndpoints.UPDATE_LEAD, {
      leadID: params.uuid,
    }),
    data: params,
  });
};

export const updateLeadKey = ['updateLead'];

export const useUpdateLead = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof updateLead>>,
      unknown,
      UpdateLeadParam,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: updateLeadKey,
    mutationFn: updateLead,
  });
};

export const updateMileage = (params: UpdateMileageParam) => {
  return apiClient({
    method: 'POST',
    url: endPointUrlNormalizer(myCarEndpoints.UPDATE_MILEAGE, {
      carProfileID: params.id,
    }),
    data: params,
  });
};

export const updateMileageKey = ['updateMileage'];

export const useUpdateMileage = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof updateMileage>>,
      unknown,
      UpdateMileageParam,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: updateMileageKey,
    mutationFn: updateMileage,
  });
};

export const updateBodyStatus = (params: UpdateBodyStatusParam) => {
  return apiClient({
    method: 'POST',
    url: endPointUrlNormalizer(myCarEndpoints.BODY_STATUS, {
      carProfileID: params.id,
    }),
    data: params,
  });
};

export const updateBodyStatusKey = ['updateBodyStatus'];

export const useUpdateBodyStatus = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof updateBodyStatus>>,
      unknown,
      UpdateBodyStatusParam,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: updateBodyStatusKey,
    mutationFn: updateBodyStatus,
  });
};

export const updateName = (params: UpdateNameParam) => {
  return apiClient({
    method: 'PUT',
    url: switchEndpoints.CURRENT_USER,
    data: params,
  });
};

export const updateNameKey = ['updateName'];

export const useUpdateName = (
  options?: Omit<
    UseMutationOptions<
      Awaited<ReturnType<typeof updateName>>,
      unknown,
      UpdateNameParam,
      unknown
    >,
    'mutationFn' | 'mutationKey'
  >,
) => {
  return useMutation({
    ...options,
    mutationKey: updateNameKey,
    mutationFn: updateName,
  });
};
