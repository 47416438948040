import PasswordIcon from '@mui/icons-material/Password';
import { InputAdornment, Stack, TextField, Typography } from '@mui/material';
import Spacer from 'components/Spacer';
import Toast from 'components/toast';
import Footer from 'containers/car-services-order/components/Footer';
import Tick from 'containers/car-services-order/components/Tick';
import Layout from 'containers/car-services-order/components/layout';
import {
  CAR_SERVICES_LOCALSTORAGE_KEY,
  useCarServices,
} from 'containers/car-services-order/configs';
import {
  useCreateReminder,
  useGetQuotation,
} from 'containers/car-services-order/requests';
import { useFormik } from 'formik';
import useFunnelContext from 'hooks/useFunnelContext';
import useLogin from 'hooks/useLogin';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { TierType } from 'services/rest/car-services/car-services';
import {
  useCreateOrderMutation,
  useGetCurrentSwitchUser,
} from '../../../services/rest/car-services';

interface PropTypes {
  currentStep: number;
  totalStep: number;
}

const UserOTP: FC<PropTypes> = ({ currentStep, totalStep }) => {
  const [isOtpCorrect, setIsOtpCorrect] = useState(true);
  const funnelData = useFunnelContext();
  const { replace } = useRouter();
  const layoutRef = useRef<HTMLDivElement | null>(null);
  const { otpPhoneNumber, setRegisterId } = useCarServices();
  const { validateVerificationCode, saveToken } = useLogin();
  const [, setVh] = useState(0);
  const [canFetchUser, setCanFetchUser] = useState<boolean>(false);
  const {
    setData: setCarServicesData,
    data: carServicesData,
    clearData,
  } = useCarServices();
  const { mutateAsync: createReminder } = useCreateReminder();
  const { mutate: createOrder } = useCreateOrderMutation();
  const userInfoRef = useRef({ name: '', family: '' });
  const { data: quotationData } = useGetQuotation(
    {
      uuid: carServicesData.quotationUUID ?? '',
    },
    { enabled: Boolean(carServicesData.quotationUUID) },
  );
  const scrollToItem = (elm: HTMLElement) => {
    const layoutElm = layoutRef.current;
    if (layoutElm) {
      layoutElm.scrollTo({
        left: 0,
        top: (elm.offsetTop || 0) - 24,
        behavior: 'smooth',
      });
    }
  };

  const createNewOrder = () => {
    if (!quotationData?.brand_model_en || !quotationData?.manufactured_year)
      return;
    const mileage = quotationData.mileage;
    if (carServicesData.orderId) {
      funnelData?.changeStep('user-address');
      return;
    }
    if (!carServicesData?.quotationUUID) return;
    createOrder(
      {
        mileage: mileage,
        car_profile: {
          mileage,
          brand_model_en: quotationData?.brand_model_en,
          brand_model_fa: quotationData?.brand_model_fa,
          manufactured_year: quotationData.manufactured_year,
        },
        quotation_uuid: carServicesData.quotationUUID,
        service_types: quotationData.service_types,
        tier: quotationData.tier as TierType,
        parts: quotationData.parts,
      },
      {
        onSuccess: (response) => {
          setCarServicesData((prevState) => ({
            ...prevState,
            orderId: parseInt(response.data.id),
          }));
          funnelData?.changeStep('user-address');
        },
        onError: () => {
          Toast({
            type: 'error',
            message: 'مشکلی در ساخت سفارش پیش آمده است.',
          });
        },
      },
    );
  };

  useEffect(() => {
    const windowResizeHandler = () => {
      if (!window.visualViewport) {
        return;
      }

      setVh(window.visualViewport.height);
    };

    windowResizeHandler();

    window.addEventListener('resize', windowResizeHandler);
    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    onSubmit: (values) => {
      if (!otpPhoneNumber) return;
      validateVerificationCode(otpPhoneNumber, values.otp)
        .then((data) => {
          setIsOtpCorrect(true);
          saveToken((data as any).token);
          setRegisterId(null);
          setCanFetchUser(true);
        })
        .catch(() => {
          setIsOtpCorrect(false);
        });
    },
  });

  const isTest = funnelData?.testFlag === 'T';

  const navigateToReminderSet = (name: string) => {
    const message = isTest
      ? 'اطلاعات آخرین سرویس دوره‌ای شما با موفقیت ثبت شد.'
      : 'تاریخ بعدی سرویس دوره‌ای شما با موفقیت ثبت شد.';
    replace(
      `/services/car-services/lead-submission/?status=set-reminder&message=${message}&name=${name}&desc=در موعد مقرر، همراه با تخفیف‌های ویژه با ارائه سرویس دوره‌ای همراه شما خواهیم بود.`,
    );
    clearData(undefined);
    localStorage.removeItem(CAR_SERVICES_LOCALSTORAGE_KEY);
  };

  const {} = useGetCurrentSwitchUser({
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: canFetchUser,
    onSuccess: (response) => {
      if (response) {
        setCanFetchUser(false);
        userInfoRef.current.name = response.first_name ?? '';
        userInfoRef.current.family = response.last_name ?? '';
      }
      if (carServicesData.reminderDate) {
        const defaultBody = {
          car_profile: {
            brand_model_en: quotationData?.brand_model_en,
            brand_model_fa: quotationData?.brand_model_fa,
            manufactured_year: quotationData?.manufactured_year,
            mileage: quotationData?.mileage,
          },
        };
        const body = carServicesData.reminderMileage
          ? {
              ...defaultBody,
              previous_autoservice_mileage: parseInt(
                carServicesData.reminderMileage,
              ),
              previous_autoservice_date: carServicesData.reminderDate,
            }
          : {
              ...defaultBody,
              upcoming_autoservice: carServicesData.reminderDate,
            };
        createReminder(body).then(() => {
          navigateToReminderSet(`${response.first_name} ${response.last_name}`);
        });
        return;
      }
      if (response.first_name && response.last_name) {
        createNewOrder();
      } else {
        funnelData?.nextStep();
      }
    },
    onError: () => {
      setCanFetchUser(false);
      userInfoRef.current.name = '';
      userInfoRef.current.family = '';
      funnelData?.nextStep();
    },
  });

  const isValid = useMemo(() => {
    return /^\d{6}$/.test(formik.values.otp);
  }, [formik.values.otp]);

  return (
    <Layout
      ref={layoutRef}
      header={{
        title: 'کد تائید',
        currentPage: currentStep,
        totalPage: totalStep,
      }}
    >
      <Stack sx={{ width: '100%' }} gap={4}>
        <Stack gap={2}>
          <Typography fontWeight={500}>
            لطفا کد تایید ارسالی را وارد کنید.
          </Typography>

          <Typography color="graytext" fontSize={14}>
            کد تایید به شماره {otpPhoneNumber} پیامک شده است.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Stack direction="row" gap={1} alignItems="center">
            <TextField
              autoFocus={true}
              placeholder="xxxxxx"
              size="small"
              label="کد تأیید"
              title="کد تأیید خود را وارد کنید."
              name="otp"
              required={true}
              fullWidth={true}
              onFocus={(e) =>
                scrollToItem(
                  e.target.parentElement?.parentElement
                    ?.parentElement as HTMLElement,
                )
              }
              value={formik.values.otp}
              error={!isOtpCorrect}
              helperText={!isOtpCorrect && 'کد وارد شده نادرست است!'}
              onChange={(event) => {
                if (event.target.value.length > 6) return;

                const otp = event.target.value.replace(/[۰-۹]/g, (num) =>
                  String.fromCharCode(
                    num.charCodeAt(0) - ('۰'.charCodeAt(0) - '0'.charCodeAt(0)),
                  ),
                );

                formik.setFieldValue('otp', otp);
                setIsOtpCorrect(true);
              }}
              inputProps={{ inputMode: 'numeric' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Tick
              isactive={String(formik.values.otp.length === 6 && isOtpCorrect)}
            />
          </Stack>

          <Typography
            sx={(theme) => ({
              userSelect: 'none',
              cursor: 'pointer',
              height: 'max-content',
              color: theme.palette.primary.main,
            })}
            onClick={() => {
              funnelData?.prevStep();
            }}
            fontSize={14}
          >
            تغییر شماره موبایل
          </Typography>
        </Stack>
      </Stack>

      <Spacer />

      <Footer
        isDisabled={!isValid}
        id="car-services-otp-btn"
        onClick={formik.submitForm}
      />
    </Layout>
  );
};

export default UserOTP;
