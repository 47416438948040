const LinkedInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clipPath="url(#clip0_1968_27490)">
        <path
          d="M12.1483 9.26662C12.4518 8.92287 12.7041 8.57547 13.0186 8.28291C13.9804 7.376 15.1214 6.91888 16.4488 6.92985C17.1802 6.93717 17.9006 6.98836 18.6064 7.19315C20.2191 7.65758 21.1553 8.76928 21.6014 10.3418C21.9378 11.5229 21.9964 12.7334 22 13.9511C22.0037 16.5146 21.9927 19.0781 22 21.6453C22 21.8866 21.9342 21.9488 21.6965 21.9451C20.3763 21.9342 19.0525 21.9342 17.7324 21.9451C17.4983 21.9451 17.4508 21.8757 17.4508 21.6563C17.4581 19.2171 17.4618 16.7779 17.4508 14.3351C17.4508 13.7244 17.4106 13.1137 17.2387 12.5176C16.9242 11.4242 16.138 10.8647 14.9897 10.9269C13.4245 11.011 12.6091 11.7862 12.4079 13.377C12.3604 13.7573 12.3384 14.1376 12.3384 14.5216C12.3384 16.8949 12.3384 19.2683 12.3458 21.6416C12.3458 21.8793 12.2872 21.9488 12.0459 21.9451C10.7148 21.9342 9.38365 21.9342 8.05254 21.9451C7.84043 21.9451 7.77827 21.8903 7.77827 21.6745C7.78558 16.9754 7.78558 12.2763 7.77827 7.58078C7.77827 7.34674 7.85506 7.29555 8.07448 7.29555C9.33977 7.30652 10.6014 7.30652 11.8667 7.29555C12.1007 7.29555 12.1593 7.36868 12.1556 7.5881C12.141 8.14761 12.1519 8.71077 12.1519 9.27394L12.1483 9.26662ZM4.9149 7.60638C4.9149 7.38697 4.88199 7.28457 4.62235 7.28823C3.28392 7.30286 1.94183 7.2992 0.603399 7.28823C0.394955 7.28823 0.351072 7.35771 0.351072 7.55153C0.354729 12.258 0.354729 16.9644 0.351072 21.6709C0.351072 21.8903 0.41324 21.9415 0.625341 21.9415C1.94549 21.9305 3.26563 21.9305 4.58578 21.9415C4.85273 21.9415 4.91856 21.8647 4.9149 21.6087C4.90393 19.2829 4.90759 16.9608 4.90759 14.635C4.90759 12.3092 4.90759 9.94681 4.91124 7.60638H4.9149ZM2.64396 0C1.18851 0 -0.00364852 1.19581 8.39076e-06 2.64761C0.00366531 4.08477 1.19948 5.27327 2.63664 5.27327C4.0921 5.27327 5.27694 4.08477 5.27694 2.62566C5.27328 1.18484 4.08478 0 2.64396 0Z"
          fill="#00A754"
        />
      </g>
      <defs>
        <clipPath id="clip0_1968_27490">
          <rect width="22" height="21.9415" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LinkedInIcon;
