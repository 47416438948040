import { QueryClient, QueryClientConfig } from '@tanstack/react-query';

const createQueryClient = (config?: QueryClientConfig) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10_000,
      },
    },
    ...config,
  });

export default createQueryClient;
